<template>
    <div>
        <v-card class="mb-12" color="">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Povratak
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="text-right">
                            <v-btn
                                color="primary"
                                class=""
                                @click="azuriraj(form.analiza_id)"
                            >
                                Ažuriraj podatke
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-card class="mt-4">
                    <v-card-title>Osnovni podatci</v-card-title>
                    <v-card-text class="mt-4">
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.analiza_naziv"
                                    :counter="40"
                                    :rules="nazivRules"
                                    label="Naziv analize"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="izbornikDatum"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="formatedDate"
                                            label="Datum"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="datum"
                                        @input="izbornikDatum = false"
                                        locale="hr-HR"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title>Detaljnije</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="font-weight-light">
                                    <b>Analiza broj:</b>
                                </h3>
                                {{ this.form.analiza_broj }}
                            </v-col>
                            <v-col>
                                <b> Klijent: </b>
                                {{ this.form.gospodarstvo_naziv }}
                                <v-spacer></v-spacer>
                                <b> Parcela: </b>
                                {{ this.form.parcela_naziv }}
                                <v-spacer></v-spacer>
                                <b> ARKOD čestice: </b>
                                {{ this.form.parcela_arkod }}
                            </v-col>
                            <v-col>
                                <b> Naziv uzorka:</b>
                                {{ this.form.uzorak_naziv }}
                                <v-spacer></v-spacer>
                                <b> Broj uzorka: </b>
                                {{ this.form.uzorak_broj }}
                                <v-spacer></v-spacer>
                                <b> Dubina uzorkovanja: </b>
                                {{ this.form.uzorak_dubina }} cm
                                <v-spacer></v-spacer>
                                <b> Datum uzorkovanja: </b>
                                {{ this.formatDate(this.form.uzorak_datum) }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title> Rezultati analize </v-card-title>
                    <v-card-text class="mt-4">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.analiza_phkci"
                                    label="pH-KCI"
                                    :rules="decimalRules"
                                    required
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.analiza_phh2o"
                                    label=""
                                    required
                                    outlined
                                    :rules="decimalRules"
                                    clearable
                                    dense
                                >
                                    <template #label>
                                        <label>pH-H<sub>2</sub>O</label>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model="form.analiza_alp2o5"
                                    label=""
                                    required
                                    :rules="decimalRules"
                                    outlined
                                    clearable
                                    dense
                                    suffix="mg/100g tla"
                                >
                                    <template #label>
                                        <label
                                            >AL-P<sub>2</sub>O<sub
                                                >5</sub
                                            ></label
                                        >
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model="form.analiza_alpk2o"
                                    label=""
                                    required
                                    outlined
                                    :rules="decimalRules"
                                    clearable
                                    dense
                                    suffix="mg/100g tla"
                                >
                                    <template #label>
                                        <label>AL-K<sub>2</sub>O</label>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.analiza_humus"
                                    label="Humus %"
                                    required
                                    outlined
                                    :rules="decimalRules"
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.analiza_caco3"
                                    label=""
                                    required
                                    outlined
                                    :rules="decimalRules"
                                    clearable
                                    dense
                                >
                                    <template #label>
                                        <label>CaCO<sub>3</sub>O %</label>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model="form.analiza_hkcmol"
                                    label=""
                                    required
                                    outlined
                                    :rules="decimalRules"
                                    clearable
                                    dense
                                >
                                    <template #label>
                                        <label>
                                            Hk cmol<sup> (+)</sup>kg<sup
                                                >-1</sup
                                            >
                                        </label>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="form.analiza_napomena"
                                    outlined
                                    name="input-7-4"
                                    label="Napomena"
                                    value="Ovdje ide napomena"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="text-left">
                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="odustani()"
                                    >
                                        Odustani
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="text-right">
                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="azuriraj(form.analiza_id)"
                                    >
                                        Ažuriraj podatke
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-card>
    </div>
</template>
<script>
export default {
    data: () => ({
        valid: true,
        loading: false,
        izbornikDatum: false,
        //ID  uzorka
        id: null,
        datum: null,
        formatedDate: "",
        form: {},
        nazivRules: [(v) => !!v || "Naziv je potreban"],
        decimalRules: [
            (v) => !!v || "Unesite vrijednost",
            (v) =>
                /^\d{1,2}(\.\d{1,3})?$/.test(v) ||
                "Broj mora biti u formatu ##.###",
        ],
    }),

    created() {
        this.ucitaj();
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.form.analiza_datum = this.formatISODate(val);
        },
    },
    methods: {
        ucitaj(id) {
            this.isLoading = true;

            this.$Api
                .getByID("analize", id)
                .then((response) => {
                    this.form = response.data;
                    this.formatedDate = this.formatDate(
                        this.form.analiza_datum
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("analize", id, this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        odustani() {
            this.$router.push("/analize");
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.ucitaj(this.id);
    },
};
</script>
