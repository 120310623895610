<template>
    <div>
        <v-card>
            <v-tabs v-model="tab" grow>
                <v-tab
                    v-for="item in items"
                    :key="item.tab"
                    class="tab"
                    active-class="tabActive"
                >
                    {{ item.tab }}
                </v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="item in items"
                        :key="item.tab"
                        active-class="tabItemActive"
                    >
                        <v-card flat>
                            <v-card-text class="pa-0"
                                ><component v-bind:is="item.content"></component
                            ></v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
import NpkPreporuka from "./NpkKalkulacijeLista.vue";

export default {
    components: {
        "npk-preporuka": NpkPreporuka,
    },
    data: () => ({
        tab: null,
        items: [{ tab: "NPK gnojidba", content: "npk-preporuka" }],
    }),
};
</script>

<style></style>
