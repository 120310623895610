<template>
    <div>
        <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.name"
                            :counter="40"
                            :rules="imeRules"
                            label="Naziv tvrtke"
                            required
                            outlined
                            dense
                        ></v-text-field>

                        <v-autocomplete
                            v-if="form.mjesto_id"
                            v-model="form.mjesto_id"
                            :items="items"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Mjesto"
                            outlined
                            dense
                        >
                        </v-autocomplete>

                        <v-text-field
                            v-model="form.ulica"
                            :rules="ulicaRules"
                            label="Ulica"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.kucni_broj"
                            label="Kućni broj"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.telefon"
                            :rules="telefonRules"
                            label="Telefon"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.mobitel"
                            :rules="telefonRules"
                            label="Mobitel"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.web"
                            label="Web stranica"
                            required
                            outlined
                            :rules="webRules"
                            clearable
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left mb-5">
                            <v-btn color="primary" class="" @click="submitForm">
                                Ažuriraj podatke
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>

            <v-card class="pa-5">
                <v-row>
                    <v-col cols="6">
                        <v-form
                            ref="uploadForm"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <h4 class="mb-5">Izmjeni Logotip</h4>
                            <div class="d-flex">
                                <v-file-input
                                    accept="image/*"
                                    label="Logotip"
                                    outlined
                                    dense
                                    @change="selectImage"
                                    prepend-icon="mdi-camera"
                                    required
                                    :rules="logoRules"
                                    ref="uploadLogoField"
                                    v-model="odabranaSlika"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip small label color="primary">
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                                <v-btn
                                    @click="uploadLogo(odabranaSlika)"
                                    slot="append-outer"
                                    class="uploadButton"
                                >
                                    <v-icon dark left>
                                        mdi-cloud-upload
                                    </v-icon>
                                    Prenesi
                                </v-btn>
                            </div>

                            <p class="text-right h6">
                                Makismalna veličina 2MB, dimenzije maksimalno
                                150 x 150px
                            </p>
                        </v-form>
                    </v-col>

                    <v-col cols="6">
                        <h4 class="mb-5">Logotip</h4>

                        <v-img
                            :src="prikaziSliku"
                            max-width="150"
                            class="mx-auto"
                        ></v-img>
                    </v-col>
                </v-row>
            </v-card>

            <v-row>
                <v-col cols="12"> </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                Vremenska prognoza</v-list-item-title
                            >
                            <v-list-item-subtitle>
                                Postavljanje lokacije za vremensku prognozu
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-form
                        ref="vremenskaForm"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                    >
                        <v-text-field
                            v-model="formVremenska.lokacija"
                            label="Lokacija"
                            required
                            outlined
                            dense
                            readonly
                            hide-details=""
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    color="primary"
                                    class="lokacija-btn"
                                    @click="spremiLokaciju"
                                >
                                    Spremi lokaciju
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-form>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-if="koordinate">
                        <AddMap
                            :centerProp="koordinate"
                            :parceleSloj="false"
                            @click="mapClick"
                        >
                        </AddMap>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import uploadSlike from "../../api/upload";
import AddMap from "../../components/leafletmaps/AddMap";

export default {
    components: {
        AddMap,
        // VGeosearch,
    },
    data() {
        return {
            odabrano: null,
            test: [
                { id: 1, name: "test1" },
                { id: 2, name: "test2" },
            ],
            items: [],
            selectedItem: null,
            naziv: "",
            isLoading: false,
            search: null,
            responseData: "",
            valid: true,
            //ID glavnog profila
            id: null,
            ime: "",
            imeRules: [(v) => !!v || "Ime i prezime je potrebno"],
            telefon: "",
            telefonRules: [
                (v) => !!v || "Unesite telefonski broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "E-mail je potrebna",
                (v) => /.+@.+\..+/.test(v) || "E-mail adresa nije ispravna",
            ],
            webRules: [
                (v) =>
                    !this.isURL(v) || "Web adresa ne treba sadržavati http://",
            ],
            ulica: "",
            ulicaRules: [(v) => !!v || "Unos je potreban"],
            kucni_broj: "",
            mjesto: "",
            web: "",
            mobitel: "",
            select: null,
            checkbox: false,
            //Podaci iz forme
            form: {},
            //Poruka
            snackbar: false,
            poruka: "",
            timeout: 2000,
            uploadLogoField: "test",
            logoRules: [
                (value) => !!value || "Unos je potreban",
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Logotip bi trebao biti ispod 2 MB!",
            ],
            logoWidth: "",
            logoHeight: "",
            logoUrl: "",
            trenutnaSlika: "",
            odabranaSlika: null,
            formVremenska: { lokacija: null },
            koordinate: "45.813315737410846, 15.96569651322166", //Default Zagreb
        };
    },

    methods: {
        uploadLogo(file) {
            if (this.validateUpload() == true) {
                uploadSlike
                    .uploadImage(file)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                        this.form.logotip = response.data.url;
                    })
                    .catch((err) => {
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            err.response.data.errors.logotip[0]
                        );
                    });
            }
        },
        mapClick(ev) {
            this.formVremenska.lokacija =
                ev.latlng.lat.toPrecision(6) +
                "," +
                ev.latlng.lng.toPrecision(6);
        },
        spremiLokaciju() {
            if (this.formVremenska.lokacija === null) {
                this.pokaziPoruku(
                    "poruke",
                    "warning",
                    "Odaberite lokaciju prije spremanja!"
                );
            } else {
                this.$Api
                    .updateData(
                        "glavni-lokacija",
                        this.form.id,
                        this.formVremenska
                    )
                    .then((response) => {
                        this.updateCenter(this.formVremenska.lokacija);
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        selectImage(image) {
            let file = image;
            if (!file || file.type.indexOf("image/") !== 0) return;

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    this.logoWidth = img.width;
                    this.logoHeight = img.height;
                };
                img.src = evt.target.result;
            };

            reader.onerror = (evt) => {
                console.error(evt);
            };
            // Logotip bi trebao biti maksimalno 300px u širini
            this.odabranaSlika = image;
            if (this.logoWidth >= 300) {
            }
        },

        updateCenter(gps) {
            //Parse koordinata iz stringa
            if (gps) {
                this.koordinate = gps.split(",").map((value) => {
                    return parseFloat(value);
                });
            } else {
                return null;
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        validateUpload() {
            if (this.$refs.uploadLogoField.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submitForm() {
            if (this.validate() == true) {
                axios
                    .put("/api/glavni/" + this.form.id, this.form)
                    .then((res) => {
                        //Perform Success Action

                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno ažuriranje"
                        );
                    })
                    .catch((error) => {
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            "Greška prilikom ažuriranja!"
                        );
                    })
                    .finally(() => {});
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        ucitajMjesta() {
            try {
                this.items = JSON.parse(localStorage.getItem("mjesta"));
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        ucitajProfil() {
            this.isLoading = true;
            axios
                .get(this.$store.getters.getApiUrl + "/glavni")
                .then((response) => {
                    this.form = response.data[0];
                    this.updateCenter(this.form.glavni_gps),
                        (this.formVremenska.lokacija =
                            response.data[0].glavni_gps);
                })
                .catch((error) => {
                    this.pokaziPoruku("poruke", "error", error);
                });
        },
        isURL(str) {
            var pattern = new RegExp("^https?://"); // fragment locator
            return pattern.test(str);
        },
    },
    mounted() {
        this.logoUrl = this.$store.getters.getLogoUrl;
        this.isLoading = false;
    },
    watch: {},
    computed: {
        prikaziSliku() {
            this.trenutnaSlika = this.logoUrl + this.form.logotip;
            return this.trenutnaSlika;
        },
    },

    created() {
        this.isLoading = true;
        this.ucitajMjesta();
        this.ucitajProfil();
        this.updateCenter(this.koordinate);
    },
};
</script>
<style scoped>
.uploadButton {
    background-color: #137791 !important;
    color: #ffffff !important;
    margin-left: 3px;
}
.lokacija-btn {
    margin-top: -6px;
}
</style>
