const masaTla = 4.5;
const ca = 20; //U miligramima
const zaCaO = 50 / 40;
const zaCaco3 = 100 / 40;
const saturacijskiMulj = 100 / 48;
const karbokalk = 100 / 34.4;
export default {
    izracunKalcilizacije(hkcmol, phkci) {
        let potrebaRez = {};
        let mmol = hkcmol * 10; //Konverzija cmol u mmol
        let camg = mmol * ca; //Rezultat u miligramima
        let cag = (mmol * ca) / 1000; //Rezultat u gramima
        let potrebaCa = cag * masaTla;
        let potrebaCaO = potrebaCa * zaCaO;
        let potrebaCaco3 = potrebaCa * zaCaco3;
        let potrebaKarbokalk = potrebaCa * karbokalk;
        let potrebaSaturacijskiMulj = potrebaCa * saturacijskiMulj;

        if (parseFloat(phkci) <= 5.5) {
            console.log("PHKCL", phkci);
            potrebaRez = Object.assign(
                { potrebaCa: parseFloat(potrebaCa).toFixed(2) },
                { potrebaCaO: parseFloat(potrebaCaO).toFixed(2) },
                { potrebaCaco3: parseFloat(potrebaCaco3).toFixed(2) },
                { potrebaKarbokalk: parseFloat(potrebaKarbokalk).toFixed(2) },
                {
                    potrebaSaturacijskiMulj: parseFloat(
                        potrebaSaturacijskiMulj
                    ).toFixed(2),
                }
            );
        } else {
            console.log("PHKCL", phkci);
            potrebaRez = Object.assign(
                { potrebaCa: 0 },
                { potrebaCaO: 0 },
                { potrebaCaco3: 0 },
                { potrebaKarbokalk: 0 },
                { potrebaSaturacijskiMulj: 0 }
            );
        }

        return potrebaRez;
    },
};
