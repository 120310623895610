<template>
    <div>
        <v-row>
            <v-col>
                <h4 class="naslov">
                    OBRAZAC O UZIMANJU UZORAKA ZA ANALIZU TLA
                </h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <h4 style="margin-bottom: 1px">
                    Osnovni podaci o Gospodarstvu
                </h4>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">Gospodarstvo</td>
                                <td class="stupacDesno">
                                    {{ stavka.gospodarstvo_naziv }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Mjesto</td>
                                <td class="stupacDesno">
                                    {{ stavka.gospodarstvo_mjesto_naziv }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Adresa</td>
                                <td class="stupacDesno">
                                    {{ stavka.gospodarstvo.ulica }}
                                    {{ stavka.gospodarstvo.kucni_broj }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Kontakt</td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">MIBPG</td>
                                <td class="stupacDesno">
                                    {{ stavka.gospodarstvo.maticni_broj }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">OIB</td>
                                <td class="stupacDesno">
                                    {{ stavka.gospodarstvo.oib }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Naziv banke</td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">IBAN</td>
                                <td class="stupacDesno"></td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>

        <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <h4 style="margin-bottom: 5px">
                    Osnovni podaci o kulturi i parceli
                </h4>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">Kultura</td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Planirana kultura</td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    Površina (m<sup>2</sup>)
                                </td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    Oznaka katastarske čestice
                                </td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">ARKOD ID</td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">GPS koordinate</td>
                                <td class="stupacDesno"></td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>

        <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <h4 style="margin-bottom: 5px">Osnovni podaci o tlu</h4>
                <table light class="osnovnoTablica osnovnoTablicaTlo" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">Tip tla</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Glinovito (teško) tlo
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Ilovasto tlo
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Pjeskovito (lako) tlo
                                        </div>
                                    </div>
                                </td>
                                <td class="stupacLijevo">Tip nasada</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Novi nasad
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Postoječi nasad
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Dubina tla</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Vrlo duboko tlo
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Srednje duboko tlo
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Plitko tlo
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Kamenito/Stjenovito tlo
                                        </div>
                                    </div>
                                </td>
                                <td class="stupacLijevo">Starost nasada</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Manje od 1 godine
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            2-3 godine
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            4-6 godina
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Više od 7 godina
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">Nagib terena</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Ravno tlo
                                        </div>
                                    </div>

                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Umjereni nagib
                                        </div>
                                    </div>

                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Strma tla
                                        </div>
                                    </div>
                                </td>
                                <td class="stupacLijevo">Rodnost tla</td>
                                <td class="stupacDesno">
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Slaba rodnost
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Umjerena rodnost
                                        </div>
                                    </div>
                                    <div style="padding: 4px; text-align: left">
                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            <div
                                                style="
                                                    float: left;
                                                    border: 1px solid;
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            style="
                                                display: inline-block;
                                                padding: 0;
                                                vertical-align: middle;
                                            "
                                        >
                                            Vrlo dobra rodnost
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
        <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <h4 style="margin-bottom: 5px">Oznaka uzorka tla</h4>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    Broj / Oznaka uzorka tla
                                </td>
                                <td class="stupacDesno"></td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    Dubina uzimanja uzorka
                                </td>
                                <td class="stupacDesno"></td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ["stavka"],
    data: () => ({}),
    mounted() {},

    methods: {},
    created() {},
};
</script>

<style scoped>
/*Stil za pdf download*/
.checkmark {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid !important;
}

.naslov {
    text-align: center;
    margin-top: 10px;
    font-family: "Roboto";
}
.osnovnoTablica {
    border: 1px solid;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}
.osnovnoTablica td {
    border: 1px solid;
    text-align: center;
    padding: 4px;
}
.osnovnoTablica td.stupacLijevo {
    text-align: right;
    width: 30%;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}

.osnovnoTablicaTlo td.stupacLijevo {
    text-align: left;
    width: 18%;
    padding: 0;
}
.osnovnoTablicaTlo td.stupacDesno {
    text-align: left;
}
.float-container {
    padding: 5px;
    border: 1px solid;
}

.float-child {
    width: 50%;
    float: left;
    padding: 0px;
}
.checkBox {
    float: left;
    border: 1px solid;
    width: 12px;
    height: 12px;
}
</style>
