<template>
    <v-card>
        <v-tabs v-model="tab">
            <v-tab
                v-for="item in items"
                :key="item.tab"
                class="tab"
                active-class="tabActive"
            >
                {{ item.tab }}
            </v-tab>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="item in items"
                    :key="item.tab"
                    active-class="tabItemActive"
                >
                    <v-card flat>
                        <v-card-text class="pa-0"
                            ><component v-bind:is="item.content"></component
                        ></v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-card>
</template>
<script>
import P2O5 from "./P2O5.vue";
import K2O from "./K2O.vue";
import OrganskaGnojiva from "./OrganskaGnojiva.vue";
import NPKformulacije from "./NPKformulacije.vue";
export default {
    components: {
        "p2-o5": P2O5,
        "k2-o": K2O,
        "organska-gnojiva": OrganskaGnojiva,
        "npk-formulacije": NPKformulacije,
    },
    data() {
        return {
            tab: null,
            items: [
                { tab: "P2O5", content: "p2-o5" },
                { tab: "K2O", content: "k2-o" },
                {
                    tab: "Organska gnojiva",
                    content: "organska-gnojiva",
                },
                {
                    tab: "NPK formulacije",
                    content: "npk-formulacije",
                },
            ],
        };
    },
};
</script>

<style>
/* Helper classes */
.tab {
    background-color: #fffbe6 !important;
    justify-content: left !important;
}
.tabActive {
    background-color: #90918f !important;
    color: #fffbe6 !important;
}
/* .tabItemActive {
   
} */
</style>
