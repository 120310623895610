import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { Api } from "./api/servisi";
// import VueSessionStorage from "vue-sessionstorage";
import Notifications from "vue-notification";
import VueBreadcrumbs from "vue-breadcrumbs";
import { setupComponents } from "./config/setupComponents";
import VueHtmlToPaper from "vue-html-to-paper";
import VueTheMask from "vue-the-mask";
import VuetifyMoney from "vuetify-money";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

locale.use(lang);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { DataTables, DataTablesServer } from "vue-data-tables";
Vue.use(DataTables);
Vue.use(DataTablesServer);

Vue.use(ElementUI);

Vue.use(VuetifyMoney);
Vue.use(VueTheMask);
Vue.use(VueHtmlToPaper, options);
Vue.use(Notifications);
Vue.use(VueBreadcrumbs);

//Globalne varijable
Vue.prototype.$baseUrl = window.location.origin;
Vue.prototype.$Api = Api;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

const options = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    timeout: 1000, // default timeout before the print window appears
    autoClose: false, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};
//Postavlja komponente
setupComponents(Vue);
export const bus = new Vue();

new Vue({
    store,
    router,
    vuetify,
    // i18n,
    render: (h) => h(App),
}).$mount("#app");
