<template>
    <div v-if="cookieLoaded">
        <v-row>
            <v-col cols="4">
                <prikaz-kartice></prikaz-kartice>
            </v-col>
            <v-col cols="8">
                <vremenska-prognoza></vremenska-prognoza>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" md="4">
                <analitika-uzorci></analitika-uzorci>
            </v-col>
            <v-col cols="4" md="4">
                <analitika-analiza></analitika-analiza>
            </v-col>
            <v-col cols="4" md="4">
                <analitika-klijenti></analitika-klijenti>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <uzorci-dash></uzorci-dash>
            </v-col>
            <v-col cols="12">
                <analize-dash></analize-dash>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    components: {
        "uzorci-dash": () =>
            import(/* webpackPrefetch: true */ "../pages/uzorci/Uzorci.vue"),
        "analize-dash": () =>
            import(/* webpackPrefetch: true */ "../pages/analiza/Analiza.vue"),
        "analitika-uzorci": () =>
            import(
                /* webpackPrefetch: true */ "../components/dashboard/AnalitikaUzorci.vue"
            ),
        "analitika-analiza": () =>
            import(
                /* webpackPrefetch: true */ "../components/dashboard/AnalitikaAnaliza.vue"
            ),
        "analitika-klijenti": () =>
            import(
                /* webpackPrefetch: true */ "../components/dashboard/AnalitikaKlijenti.vue"
            ),
        "prikaz-kartice": () =>
            import(
                /* webpackPrefetch: true */ "../components/dashboard/Kartice.vue"
            ),
        "vremenska-prognoza": () =>
            import(
                /* webpackPrefetch: true */ "../pages/prognoza/VremenskaPrognoza.vue"
            ),
    },
    data: () => ({
        title: "Kontrolna ploča",
        uzorci: null,
        isLoading: false,
        cookieLoaded: $cookies.get("auth"),
    }),
    methods: {},
    created() {
        if (this.$store.getters.isLoggedIn) {
            if (localStorage.getItem("mjesta") === null) {
                this.$store.dispatch("initMjesta_AC");
                this.$store.dispatch("initBanka_AC");
            }
        }
    },
};
</script>

<style></style>
