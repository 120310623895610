<template>
    <div>
        <v-overlay :value="overlay" z-index="9999">
            <h2>Preuzimanje u tijeku!</h2>
            <!-- <v-progress-circular
                :size="20"
                color="amber"
                indeterminate
            ></v-progress-circular> -->
        </v-overlay>
        <v-data-table
            ref="datatableParcele"
            :headers="headers"
            :items="dataItems"
            key="id"
            sort-by="name"
            :search="search"
            no-results-text="Nema pronađenih"
            no-data-text="Nema podataka"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            loading-text="Učitavanje... Molimo pričekajte"
            @page-count="pageCount = $event"
            group-by="gospodarstvo_naziv"
        >
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="12" class="teal lighten-4">
                    <v-row>
                        <v-col cols="2">
                            <v-icon @click="toggle" class="mr-0">
                                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                            </v-icon>
                            <span class="">{{
                                items[0].gospodarstvo_naziv
                            }}</span>
                        </v-col>
                        <v-col>
                            <h4>OIB: {{ items[0].gospodarstvo_oib }}</h4>

                            <h4>
                                MIBPG:
                                {{ items[0].gospodarstvo_maticni_broj }}
                            </h4>
                        </v-col>
                    </v-row>
                </th>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                    ></v-text-field>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisFiltriranih"
                                    class="mr-2 ml-2"
                                    color="red lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispis tablice parcela</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFiltriranih"
                                    class="mr-2"
                                    color="blue lighten-1"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi tablicu parcela</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download :filename="FilenameXlsx">
                                        <v-icon
                                            @click="izvozExcell"
                                            class="mr-2"
                                            color="green lighten-1"
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </span>
                        </template>
                        <span>Izvoz tablice parcela</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    :to="{ name: 'nova-parcela' }"
                                >
                                    Dodaj +
                                </v-btn>
                            </span>
                        </template>
                        <span>Dodaj novu parcelu</span>
                    </v-tooltip>
                </v-toolbar>
            </template>
            <template v-slot:[`item.naziv`]="{ item }">
                <v-icon class="mr-3">mdi-layers</v-icon>
                <router-link
                    :to="{ name: 'parcela', params: { id: item.id } }"
                    class="routerLink"
                >
                    {{ item.naziv }}
                </router-link>
            </template>
            <template v-slot:[`item.arkod`]="{ item }">
                <p>
                    {{ item.arkod }}
                </p>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="ispisObrasca(item)"
                                class="mr-2"
                                color="green lighten-2"
                            >
                                mdi-printer
                            </v-icon>
                        </span>
                    </template>
                    <span>Ispiši podatke o parceli</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="downloadObrasca(item)"
                                class="mr-2"
                                color="blue lighten-2"
                            >
                                mdi-download
                            </v-icon>
                        </span>
                    </template>
                    <span>Preuzmi analizu</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="obrisiDialog(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši</span>
                </v-tooltip>
            </template>

            <div class="text-center">
                <v-pagination v-model="page" :length="4" circle></v-pagination>
            </div>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
        <!-- Dialog dodaj-->
        <v-dialog v-model="dialogNovo" persistent max-width="500px">
            <v-form ref="form" lazy-validation v-model="valid">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Dodaj</span>
                    </v-card-title>

                    <v-container>
                        <v-text-field
                            v-model="form.naziv"
                            label="Naziv"
                            :rules="unosRules"
                            required
                            dense
                            outlined
                        ></v-text-field>
                        <v-text-field
                            v-model="form.vrijednost"
                            label="Vrijednost"
                            outlined
                            dense
                        ></v-text-field>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialogNovo = false">
                            Odustani
                        </v-btn>
                        <v-btn text @click="dodaj"> Spremi </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dialog uredi-->
        <v-dialog v-model="dialogUredi" persistent max-width="500px">
            <v-form ref="form">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Uredi</span>
                    </v-card-title>

                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.naziv"
                                    label="Naziv"
                                    required
                                    outlined
                                    dense
                                    :rules="unosRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.vrijednost"
                                    label="Vrijednost"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogUredi = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="azuriraj(form.id)"
                        >
                            Ažuriraj
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dialog obrisi-->
        <v-dialog v-model="dialogObrisi" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Potvrdite brisanje!</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogObrisi = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="obrisi(obrisiId)">
                        Obriši
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- -DOWNLOAD  -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="true"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <parcela-obrazac
                        :parcela="ParcelaIspis"
                        v-if="ParcelaIspis"
                    ></parcela-obrazac>
                </v-container>
            </section>
        </vue-html2pdf>
        <!-- ISPIS  -->
        <div id="printMe" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>
                <parcela-obrazac
                    :parcela="ParcelaIspis"
                    v-if="ParcelaIspis"
                ></parcela-obrazac>
                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- ISPIS FILTRIRANIH ANALIZA -->
        <div id="printFiltrirano" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <parcela-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </parcela-filtrirano>

                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- -DOWNLOAD  FILTRIRANIH :paginate-elements-by-height="1400"   -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="true"
            :paginate-elements-by-height="1400"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfFiltrirano"
            :html-to-pdf-options="downloadFilterOptions"
            @hasDownloaded="hasDownloaded($event)"
            @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <parcela-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </parcela-filtrirano>

                <!-- <div class="verticalSpacer"></div> -->

                <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import parcelaObrazac from "../obrasci/ParcelaObrazac.vue";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import VueHtml2pdf from "vue-html2pdf";
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
export default {
    components: {
        VueHtml2pdf,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "parcela-obrazac": parcelaObrazac,
        "parcela-filtrirano": () => import("../obrasci/ParcelaFilterIspis.vue"),
        "obrazac-zaglavlje": ObrazacZaglavlje,
    },
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        expanded: [],
        search: "",
        valid: true,
        loading: false,
        ParcelaIspis: null,
        dataItemsFlitrirane: [], // Stavke nakon filtera ili pretrage
        dataItemsKoristeniFilteri: {
            datumFilter: null,
            godinaFilter: null,
            operatorFilter: null,
            mjestoFilter: null,
        },
        FilenameXlsx: null,
        sheetName: "uzorci",
        sheets: [
            {
                name: "Uzorci",
                data: [{}],
            },
        ],
        collection: [{ a: 1, b: 2 }],
        downloadFilterOptions: {
            filename: "Tablica_parcela",
            pagebreak: {
                mode: ["avoid-all", "css", "legacy"],
            },
            jsPDF: {
                format: "a4",
                orientation: "landscape",
            },
            // html2canvas: {
            //     useCORS: true,
            //     allowTaint: true,
            //     scrollX: 0,
            //     scrollY: 0,
            // },
        },
        imeDatoteke: "",
        headers: [
            {
                text: "Naziv",
                align: "start",
                sortable: false,
                value: "naziv",
                width: "25%",
            },
            {
                text: "Gospodarstvo",
                value: "gospodarstvo_naziv",
                sortable: false,
            },
            {
                text: "Korisnik OIB",
                value: "gospodarstvo_oib",
                sortable: false,
                align: " d-none", //Skriveno , tu za pretragu
            },
            {
                text: "Površina (ha)",
                value: "povrsina",
                sortable: false,

                align: "center",
            },
            {
                text: "ARKOD",
                value: "arkod",
                sortable: false,
                align: "center",
                width: "15%",
            },
            {
                text: "Katastarska općina",
                value: "mjesto_parcele_naziv",
                sortable: false,
                align: "center",
            },
            {
                text: "Katastarska čestica",
                value: "kat_cestica",
                sortable: false,
                align: "center",
            },
            {
                text: "Koordinate",
                value: "gps",
                sortable: false,
            },
            {
                text: "Katastar veza",
                value: "katastar_link",
                sortable: false,
                width: "15%",
            },
            { text: "Uredi", value: "actions", sortable: false, width: "10%" },
        ],
        unosRules: [(v) => !!v || "Unos je potreban"],
        dataItems: [], //Ucitava elemente
        obrisiId: "",
        form: {}, //Objekt za uredjivanje dodavanje spremanje
        dialogNovo: false,
        dialogUredi: false,
        dialogObrisi: false,
        overlay: false,
    }),

    created() {
        this.ucitaj();
    },
    mounted() {
        // this.skupiSve();
    },
    methods: {
        dodajDialog() {
            this.form.id = "";
            this.form.naziv = "";
            this.form.vrijednost = "";
            this.dialogNovo = true;
        },
        urediDialog(item) {
            this.dialogUredi = true;
            this.form.id = item.id;
            this.form.naziv = item.naziv;
            this.form.vrijednost = item.vrijednost;
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        ucitaj() {
            this.loading = true;
            this.$Api
                .getData("parcela")
                .then((response) => {
                    this.dataItems = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        dodaj() {
            if (this.$refs.form.validate()) {
                this.$Api
                    .postData("parcela", this.form)
                    .then((response) => {
                        this.dialogNovo = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
            this.resetforme();
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("parcela", id, this.form)
                    .then((response) => {
                        this.dialogUredi = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        obrisi(id) {
            this.$Api
                .delete("parcela", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ispisFiltriranih() {
            // this.forceRerender();
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.dataItemsFlitrirane =
                this.$refs.datatableParcele.$children[0].filteredItems;
            // this.analizaIspis = this.dataItemsFlitrirane;
            // this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printFiltrirano", localOptions);
            }, "500");
        },
        //Preuzimanje filtriranih analiza
        downloadFiltriranih() {
            this.overlay = true;
            this.imeDatoteke = "Tablica Uzoraka";
            this.dataItemsFlitrirane =
                this.$refs.datatableParcele.$children[0].filteredItems;
            setTimeout(() => {
                this.generateReportFiltriranih();
            }, "500");
        },
        async generateReportFiltriranih() {
            this.$refs.html2PdfFiltrirano.generatePdf();
        },
        formirajExcell() {
            this.FilenameXlsx = "Tablica_parcela.xlsx";
            this.sheets[0].data = [{}]; //Nuliranje array

            this.$refs.datatableParcele.$children[0].filteredItems.forEach(
                (element) => {
                    this.sheets[0].data.push({
                        Naziv: element.naziv,
                        Površina: element.povrsina,
                        ARKOD: element.arkod,
                        "Katastarska općina": element.mjesto_parcele_naziv,
                        "Katastarska čestica": element.kat_cestica,
                        Koordinate: element.gps,
                        Korisnik: element.gospodarstvo_naziv,
                        "Korisnik OIB": element.gospodarstvo_oib,
                        "Korisnik MB": element.gospodarstvo_maticni_broj,
                    });
                }
            );
        },
        //Nista ne radi samo pokazuje cursor, download ide iz komponente xlsx
        izvozExcell() {
            this.formirajExcell();
            return false;
        },
        downloadObrasca(item) {
            this.imeDatoteke = "Parcela-" + item.parcela_broj + ".pdf";
            this.ParcelaIspis = item;

            setTimeout(() => {
                this.generateReport();
            }, "500");
        },

        async ispisObrasca(item) {
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.ParcelaIspis = item;

            // this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printMe", localOptions);
            }, "500");
        },
        hasDownloaded(e) {
            this.overlay = false;
        },
        onProgress(e) {
            this.printPdfProgress = e;
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        skupiSve() {
            let table = this.$refs.datatableParcele;
            let keys = Object.keys(table.$vnode.componentInstance.openCache);
            keys.forEach((x) => {
                table.$vnode.componentInstance.openCache[x] = false;
            });
        },
    },
};
</script>
<style>
.width-400 {
    width: 400px;
}
</style>
