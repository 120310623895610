<template>
    <div>
        <loader-stranica :state="isLoading"></loader-stranica>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-row>
                <v-col>
                    <div class="text-right">
                        <v-btn
                            @click="ispisObrasca()"
                            class="mr-2"
                            color="primary"
                        >
                            Ispiši podatke
                        </v-btn>
                        <v-btn
                            @click="downloadObrasca()"
                            class="mr-2"
                            color="primary"
                        >
                            Preuzmi podatke
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="azuriraj(form.id, tloID)"
                        >
                            Ažuriraj podatke
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <h2 class="font-weight-light">Opće informacije</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.naziv"
                            :rules="unosRules"
                            label="Naziv"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.povrsina"
                            label="Površina"
                            :rules="povrsinaRules"
                            required
                            suffix="ha"
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.gps"
                            label="GPS koordinate"
                            required
                            outlined
                            clearable
                            dense
                            read-only
                        ></v-text-field>
                        <v-autocomplete
                            v-model="form.mjesto_parcele_id"
                            :items="mjesta"
                            item-text="naziv"
                            item-value="id"
                            label="Mjesto / Općina"
                            outlined
                            clearable
                            :rules="mjestoRules"
                            required
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.kat_cestica"
                            label="Katastarska čestica"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.arkod"
                            label="ARKOD"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-autocomplete
                            v-model="form.vlasnistvo_id"
                            :items="vlasnistvo"
                            item-text="vlasnistvo_naziv"
                            item-value="id"
                            label="Tip vlasništva"
                            outlined
                            clearable
                            :rules="mjestoRules"
                            required
                            dense
                        ></v-autocomplete>
                        <v-text-field
                            v-model="form.katastar_link"
                            label="Katastar link"
                            outlined
                            dense
                            hide-details
                        >
                            <template v-slot:append-outer>
                                <v-icon
                                    left
                                    @click="katastarOtvori(form.katastar_link)"
                                >
                                    mdi-web-box</v-icon
                                >
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-expansion-panels v-model="otvorenPanel">
                    <v-expansion-panel key="i">
                        <v-expansion-panel-header color="teal lighten-4">
                            <h3 class="font-weight-light">Parcela na karti</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12">
                                    <div v-if="koordinate">
                                        <AddMap
                                            :centerProp="koordinate"
                                            @click="mapClick"
                                        />
                                    </div>
                                    <div v-else>
                                        <h3>Nema podataka za kartu</h3>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
            <h2 class="font-weight-light">Kulture predkulture</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.plan_kultura"
                            :items="kulture"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Planirana kultura"
                            outlined
                            :rules="selectRule"
                            @change="izborKulturePrinos"
                            dense
                        >
                        </v-select>

                        <v-text-field
                            v-model="form.plan_prinos"
                            :rules="prinosRules"
                            label="Planirani prinos"
                            required
                            suffix="t/ha"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.pred_kultura"
                            :items="kulture"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Predkultura"
                            :rules="selectRule"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.pred_kultura_prinos"
                            :items="predKulturaPrinos"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            :rules="selectRule"
                            label="Prinos predkulture"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Proizvodnja</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.proizvodnja"
                            :items="proizvodnja"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="proizvodnja_naziv"
                            item-value="id"
                            :rules="selectRule"
                            label="Proizvodnja"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6"> </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Žetveni ostaci</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.zetveni_ostaci"
                            :items="zetveniOstaci"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            :rules="selectRule"
                            label="Žetveni ostaci"
                            outlined
                            dense
                            suffix="t/ha"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.zet_ost_h"
                            :items="zetveniOstaciH"
                            item-text="name"
                            item-value="value"
                            label="Količina"
                            :rules="selectRule"
                            dense
                            outlined
                            suffix="t/ha"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>

            <h2 class="font-weight-light">Organska Gnojidba</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6" ref="organskoVrsta">
                        <v-select
                            v-model="form.organsko_vrsta"
                            :items="organskoVrsta"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Vrsta organskog gnojiva"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.organsko_primjena"
                            :items="organskoPrimjena"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Broj godina od primjene"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.organsko_t_h"
                            label="Količina"
                            required
                            suffix="t/ha"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Osnovna Gnojidba</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.osnovna_gnojidba"
                            :items="osnovnoGnojidba"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Osnovna gnojidba"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6"> </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Zemljište</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.exp_nagib"
                            :items="expozicijaNagib"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Ekspozicija i nagib"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.navodnjavanje"
                            :items="navodnjavanje"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Navodnjavanje"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.ur_zemljista"
                            :items="uredjenostZemljista"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Uređenost zemljišta"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.ur_zemljista_kategorija"
                            :items="uredjenostKategorija"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Voda na zemljištu"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Informacije o tlu</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.tip_tla"
                            :items="tloTip"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Tekstura tla (procjena)"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.dubina_tla"
                            :items="tloDubina"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Dubina tla"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.biogenost_tla"
                            :items="tloBiogenost"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Biogenost tla"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.rodnost_tla"
                            :items="tloRodnost"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Rodnost tla"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.tip_nasada"
                            :items="nasadTip"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Tip nasada"
                            outlined
                            dense
                        >
                        </v-select>
                        <v-select
                            v-model="form.starost_nasada"
                            :items="nasadStarost"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Starost nasada"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Nasad kategorija</h2>
            <v-container>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.nasad_kategorija_id"
                            :items="nasadKategorija"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="nasad_kategorija_naziv"
                            item-value="id"
                            label="Vrsta nasada"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="form.broj_stabala_cokota_ha"
                            label="Unesite količinu"
                            outlined
                            clearable
                            :rules="brojRules"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <h2 class="font-weight-light">Agrotehnika i zaštita</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.agrotehnika"
                            :items="agrotehnika"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Agrotehnika"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="form.zastita"
                            :items="zastita"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Zaštita"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>

            <h2 class="font-weight-light">Napomena</h2>
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="12">
                        <v-textarea
                            v-model="form.napomena"
                            label="Unesite napomenu o parceli"
                            auto-grow
                            outlined
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col> </v-col>
                    <IzbornikUredi
                        @spremi="azuriraj(form.id, tloID)"
                        @odustani="odustani()"
                    />
                </v-row>
            </v-container>
        </v-form>
        <!-- ---------------DOWNLOAD ------------- -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="true"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <parcela-obrazac
                        :parcela="parcelaIspis"
                        v-if="parcelaIspis"
                    ></parcela-obrazac>
                    <div class="verticalSpacer"></div>
                </v-container>
            </section>
        </vue-html2pdf>
        <!-- ISPIS  -->
        <div id="printMe" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>
                <parcela-obrazac
                    :parcela="parcelaIspis"
                    v-if="parcelaIspis"
                ></parcela-obrazac>
                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import parcelaObrazac from "../obrasci/ParcelaObrazac.vue";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import ObrazacPodnozje from "../obrasci/ObrazacPodnozje.vue";
import VueHtml2pdf from "vue-html2pdf";
import AddMap from "../../components/leafletmaps/AddMap";
import IzbornikUredi from "../../components/IzbornikUredjivanje.vue";
export default {
    components: {
        AddMap,
        VueHtml2pdf,
        "parcela-obrazac": parcelaObrazac,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "obrazac-podnozje": ObrazacPodnozje,
        IzbornikUredi,
    },

    data() {
        return {
            mjesta: [],
            kulture: [],
            predKulturaPrinos: [],
            zetveniOstaci: [],
            organskoVrsta: [],
            organskoPrimjena: [],
            organskoLista: [10, 20, 30, 40, 50, 60, 70, 80, 80, 100],
            osnovnoGnojidba: [],
            expozicijaNagib: [],
            navodnjavanje: [],
            uredjenostZemljista: [],
            uredjenostKategorija: [],
            agrotehnika: [],
            zastita: [],
            tloID: "",
            tloTip: [],
            tloDubina: [],
            tloBiogenost: [],
            tloRodnost: [],
            nasadTip: [],
            nasadStarost: [],
            nasadKategorija: [],
            koordinate: [],
            proizvodnja: [],
            zetveniOstaciH: [
                { name: "1", value: "1" },
                { name: "2", value: "2" },
                { name: "3", value: "3" },
                { name: "4", value: "4" },
                { name: "5", value: "5" },
                { name: "6", value: "6" },
            ],
            vlasnistvo: [],
            prinosInfo: false,
            otvorenPanel: 0,
            selectedItem: null,
            isLoading: false,
            progressValue: 0,
            responseData: "",
            valid: true,
            //ID  profila
            id: null,
            planiraniPrinos: {}, //Objekt iz odabrane planirane kulture v-select
            povrsinaRules: [
                (v) => !!v || "Unesite vrijednost",
                (v) =>
                    /^\d{1,2}(\.\d{1,3})?$/.test(v) ||
                    "Broj mora biti u formatu ##.###",
            ],
            prinosRules: [
                (v) => !!v || "Unesite vrijednost",

                (v) =>
                    /^\d{1,2}(\.\d{1,1})?$/.test(v) ||
                    "Broj mora biti u formatu ##.#",
                (v) =>
                    (v <= Number(this.planiraniPrinos.max) &&
                        v >= Number(this.planiraniPrinos.min)) ||
                    `Realni prinos za odabranu kulturu ${this.planiraniPrinos.min} t/h - ${this.planiraniPrinos.max} t/h`,
            ],
            selectRule: [(v) => !!v || "Odaberite vrijednost"],
            unosRules: [(v) => !!v || "Unos potreban"],
            brojRules: [
                (v) => !!v || "Unesite broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            mjestoRules: [(v) => !!v || "Odaberite ili unesite mjesto"],
            select: null,
            //Podaci iz forme
            form: {},
            parcelaIspis: null,
            imeDatoteke: null,
        };
    },
    methods: {
        updateCenter(gps) {
            //Parse koordinata iz stringa
            if (gps) {
                this.koordinate = gps.split(",").map((value) => {
                    return parseFloat(value);
                });
            } else {
                return null;
            }
        },
        provjeriKoordinate() {
            if (this.koordinate === null) {
                return null;
            }
        },
        mapClick(ev) {
            this.form.gps =
                ev.latlng.lat.toPrecision(6) +
                "," +
                ev.latlng.lng.toPrecision(6);
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        azuriraj(parcelaId, tloId) {
            this.izborKulturePrinos(this.form.plan_kultura);
            if (this.$refs.form.validate()) {
                axios
                    .all([
                        this.$Api.updateData("parcela", parcelaId, this.form),
                        this.updateCenter(this.form.gps),
                    ])
                    .then(
                        axios.spread((...responses) => {
                            this.pokaziPoruku(
                                "poruke",
                                "success",
                                responses[0].data.message
                            );
                        })
                    )
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        izborKulturePrinos(item) {
            console.log("PRINOS");
            const prinos = this.kulture.find((element) => element.id == item);
            this.planiraniPrinos = JSON.parse(prinos.prinos);
        },
        downloadObrasca(item) {
            this.prikupiPodatke();
            this.imeDatoteke =
                "Parcela-" + this.parcelaIspis.parcela_naziv + ".pdf";
            setTimeout(() => {
                this.generateReport();
            }, "500");
        },
        async ispisObrasca(item) {
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.prikupiPodatke();

            setTimeout(() => {
                this.$htmlToPaper("printMe", localOptions);
            }, "500");
        },

        ucitaj(id) {
            this.isLoading = true;
            this.$Api
                .getByID("parcela", id)
                .then((response) => {
                    this.form = response.data;
                    this.updateCenter(response.data.gps);
                    this.ucitajOstalo();
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        async ucitajOstalo() {
            axios
                .all([
                    await this.$Api.getData("kultura"),
                    this.$Api.getData("prinos"),
                    this.$Api.getData("zetveni-ostaci"),
                    await this.$Api.getData("organsko-vrste"),
                    this.$Api.getData("organsko-primjena"),
                    this.$Api.getData("osnovna-gnojidba"),
                    await this.$Api.getData("ekspozicija-nagib"),
                    this.$Api.getData("navodnjavanje"),
                    this.$Api.getData("uredjenost-zemljista"),
                    await this.$Api.getData("uredjenost-kategorija"),
                    this.$Api.getData("agrotehnika"),
                    this.$Api.getData("zastita"),
                    await this.$Api.getData("tip-tla"),
                    this.$Api.getData("dubina-tla"),
                    this.$Api.getData("biogenost-tla"),
                    await this.$Api.getData("rodnost-tla"),
                    this.$Api.getData("nasad-tip"),
                    this.$Api.getData("nasad-starost"),
                    this.$Api.getData("proizvodnja"),
                    await this.$Api.getData("nasad-kategorija"),
                ])
                .then(
                    axios.spread((...responses) => {
                        this.kulture = responses[0].data;
                        this.predKulturaPrinos = responses[1].data;
                        this.zetveniOstaci = responses[2].data;
                        this.organskoVrsta = responses[3].data;
                        this.organskoPrimjena = responses[4].data;
                        this.osnovnoGnojidba = responses[5].data;
                        this.expozicijaNagib = responses[6].data;
                        this.navodnjavanje = responses[7].data;
                        this.uredjenostZemljista = responses[8].data;
                        this.uredjenostKategorija = responses[9].data;
                        this.agrotehnika = responses[10].data;
                        this.zastita = responses[11].data;
                        this.tloTip = responses[12].data;
                        this.tloDubina = responses[13].data;
                        this.tloBiogenost = responses[14].data;
                        this.tloRodnost = responses[15].data;
                        this.nasadTip = responses[16].data;
                        this.nasadStarost = responses[17].data;
                        this.proizvodnja = responses[18].data;
                        this.nasadKategorija = responses[19].data;
                    })
                )
                .catch((errors) => {
                    this.pokaziPoruku(
                        "poruke",
                        "error",
                        "Greška prilikom učitavanja!"
                    );
                })
                .finally(() => {
                    this.isLoading = false;
                    this.resetValidation();
                    this.izborKulturePrinos(this.form.plan_kultura);
                });
        },
        prikupiPodatke() {
            // this.imeDatoteke = "Gospodarstvo_" + item.naziv + ".pdf";

            this.parcelaIspis = {};
            this.parcelaIspis.gospodarstvo_naziv = this.form.gospodarstvo_naziv;
            this.parcelaIspis.parcela_naziv = this.form.naziv;
            this.parcelaIspis.parcela_povrsina = this.form.povrsina;
            this.parcelaIspis.kat_cestica = this.form.kat_cestica;
            this.parcelaIspis.arkod = this.form.arkod;
            this.parcelaIspis.gps = this.form.gps;
            this.parcelaIspis.plan_kutura_naziv = this.form.plan_kutura_naziv;

            this.parcelaIspis.plan_prinos = this.form.plan_prinos;

            this.parcelaIspis.pred_kultura_naziv = this.form.pred_kultura_naziv;

            this.parcelaIspis.pred_kultura_prinos =
                this.form.pred_kultura_prinos;

            this.parcelaIspis.zetveni_ostaci_naziv =
                this.form.zetveni_ostaci_naziv;

            this.parcelaIspis.zet_ost_h = this.form.zet_ost_h;

            if (
                this.organskoVrsta.find(
                    (vrsta) => vrsta.id === this.form.organsko_vrsta
                )
            ) {
                this.parcelaIspis.organsko_vrsta = this.organskoVrsta.find(
                    (vrsta) => vrsta.id === this.form.organsko_vrsta
                ).naziv;
            } else {
                this.parcelaIspis.organsko_vrsta = "Nije unešeno";
            }

            this.parcelaIspis.organsko_t_h = this.form.organsko_t_h;

            if (
                this.organskoPrimjena.find(
                    (broj) => broj.id === this.form.organsko_primjena
                )
            ) {
                this.parcelaIspis.organsko_primjena =
                    this.organskoPrimjena.find(
                        (broj) => broj.id === this.form.organsko_primjena
                    ).naziv;
            } else {
                this.parcelaIspis.organsko_primjena = "Nije unešeno";
            }

            if (
                this.osnovnoGnojidba.find(
                    (broj) => broj.id === this.form.osnovna_gnojidba
                )
            ) {
                this.parcelaIspis.osnovna_gnojidba = this.osnovnoGnojidba.find(
                    (broj) => broj.id === this.form.osnovna_gnojidba
                ).naziv;
            } else {
                this.parcelaIspis.osnovna_gnojidba = "Nije unešeno";
            }

            if (
                this.expozicijaNagib.find((el) => el.id === this.form.exp_nagib)
            ) {
                this.parcelaIspis.exp_nagib = this.expozicijaNagib.find(
                    (el) => el.id === this.form.exp_nagib
                ).naziv;
            } else {
                this.parcelaIspis.exp_nagib = "Nije unešeno";
            }

            if (
                this.uredjenostZemljista.find(
                    (el) => el.id === this.form.ur_zemljista
                )
            ) {
                this.parcelaIspis.ur_zemljista = this.uredjenostZemljista.find(
                    (el) => el.id === this.form.ur_zemljista
                ).naziv;
            } else {
                this.parcelaIspis.ur_zemljista = "Nije unešeno";
            }

            if (
                this.navodnjavanje.find(
                    (el) => el.id === this.form.navodnjavanje
                )
            ) {
                this.parcelaIspis.navodnjavanje = this.navodnjavanje.find(
                    (el) => el.id === this.form.navodnjavanje
                ).naziv;
            } else {
                this.parcelaIspis.navodnjavanje = "Nije unešeno";
            }

            if (
                this.uredjenostKategorija.find(
                    (el) => el.id === this.form.ur_zemljista_kategorija
                )
            ) {
                this.parcelaIspis.ur_zemljista_kategorija =
                    this.uredjenostKategorija.find(
                        (el) => el.id === this.form.ur_zemljista_kategorija
                    ).naziv;
            } else {
                this.parcelaIspis.ur_zemljista_kategorija = "Nije unešeno";
            }

            if (this.tloTip.find((el) => el.id === this.form.tip_tla)) {
                this.parcelaIspis.tip_tla = this.tloTip.find(
                    (el) => el.id === this.form.tip_tla
                ).naziv;
            } else {
                this.parcelaIspis.tip_tla = "Nije unešeno";
            }

            if (this.tloDubina.find((el) => el.id === this.form.dubina_tla)) {
                this.parcelaIspis.dubina_tla = this.tloDubina.find(
                    (el) => el.id === this.form.dubina_tla
                ).naziv;
            } else {
                this.parcelaIspis.dubina_tla = "Nije unešeno";
            }

            if (this.tloRodnost.find((el) => el.id === this.form.rodnost_tla)) {
                this.parcelaIspis.rodnost_tla = this.tloRodnost.find(
                    (el) => el.id === this.form.rodnost_tla
                ).naziv;
            } else {
                this.parcelaIspis.rodnost_tla = "Nije unešeno";
            }

            if (this.nasadTip.find((el) => el.id === this.form.tip_nasada)) {
                this.parcelaIspis.tip_nasada = this.nasadTip.find(
                    (el) => el.id === this.form.tip_nasada
                ).naziv;
            } else {
                this.parcelaIspis.tip_nasada = "Nije unešeno";
            }

            if (
                this.tloBiogenost.find(
                    (el) => el.id === this.form.biogenost_tla
                )
            ) {
                this.parcelaIspis.biogenost_tla = this.tloBiogenost.find(
                    (el) => el.id === this.form.biogenost_tla
                ).naziv;
            } else {
                this.parcelaIspis.biogenost_tla = "Nije unešeno";
            }

            if (
                this.nasadStarost.find(
                    (el) => el.id === this.form.starost_nasada
                )
            ) {
                this.parcelaIspis.starost_nasada = this.nasadStarost.find(
                    (el) => el.id === this.form.starost_nasada
                ).naziv;
            } else {
                this.parcelaIspis.starost_nasada = "Nije unešeno";
            }
            if (
                this.agrotehnika.find((el) => el.id === this.form.agrotehnika)
            ) {
                this.parcelaIspis.agrotehnika = this.agrotehnika.find(
                    (el) => el.id === this.form.agrotehnika
                ).naziv;
            } else {
                this.parcelaIspis.agrotehnika = "Nije unešeno";
            }

            if (this.zastita.find((el) => el.id === this.form.zastita)) {
                this.parcelaIspis.zastita = this.zastita.find(
                    (el) => el.id === this.form.zastita
                ).naziv;
            } else {
                this.parcelaIspis.zastita = "Nije unešeno";
            }
        },
        ucitajVlasnistvo() {
            this.$Api.getData("vlasnistvo").then((response) => {
                this.vlasnistvo = response.data;
            });
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        odustani() {
            this.$router.push("/parcele");
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        katastarOtvori(link) {
            window.open(link, "_blank");
        },
    },

    mounted() {
        this.id = this.$route.params.id;
        this.ucitaj(this.id);
        this.ucitajMjesta();
        this.ucitajVlasnistvo();
    },
};
</script>

<style></style>
