<template>
    <div>
        <v-speed-dial
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            fixed
        >
            <template v-slot:activator>
                <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab"> mdi-close </v-icon>
                    <v-icon v-else> mdi-pencil-box </v-icon>
                </v-btn>
            </template>

            <v-btn fab dark small color="indigo" @click="$emit('odustani')">
                <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>

            <v-btn fab dark small color="green" @click="$emit('spremi')">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-speed-dial>
    </div>
</template>

<script>
export default {
    data() {
        return {
            direction: "left",
            fab: false,
            fling: false,
            hover: true,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            transition: "slide-y-reverse-transition",
        };
    },
};
</script>

<style></style>
