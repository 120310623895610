<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container class="pa-0">
                <v-card>
                    <v-card-title class="cyan darken-1 mb-5">
                        <span class="subtitle white--text"
                            >Detalji o gospodarstvu</span
                        >
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.naziv"
                                    :counter="40"
                                    :rules="imeRules"
                                    label="Naziv gospodarstva"
                                    prepend-icon="mdi-barn"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.maticni_broj"
                                    :rules="ulicaRules"
                                    label="MIBPG"
                                    prepend-icon="mdi-alpha-m-box"
                                    required
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-switch
                                    v-model="form.status"
                                    label="Klijent aktivan ili nekativan"
                                    color="success"
                                    input-value="true"
                                >
                                </v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.oib"
                                    :rules="oibRules"
                                    label="OIB"
                                    prepend-icon="mdi-alpha-o-box"
                                    maxlength="11"
                                    required
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>

                                <v-select
                                    v-model="form.banka_id"
                                    :items="banke"
                                    :loading="isLoading"
                                    hide-no-data
                                    hide-selected
                                    item-text="naziv"
                                    item-value="id"
                                    label="Banka"
                                    prepend-icon="mdi-bank"
                                    outlined
                                    dense
                                >
                                </v-select>
                                <v-text-field
                                    v-model="form.iban"
                                    :rules="ibanRules"
                                    label="IBAN"
                                    prepend-icon="mdi-alpha-o-box"
                                    outlined
                                    maxlength="21"
                                    clearable
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title class="cyan darken-1 mb-5">
                        <span class="subtitle white--text"
                            >Detalji o vlasniku</span
                        >
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.ime"
                                    :counter="40"
                                    :rules="imeRules"
                                    label="Ime"
                                    prepend-icon="mdi-account-arrow-right"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>

                                <v-select
                                    v-model="form.mjesto_id"
                                    :items="mjesta"
                                    :loading="isLoading"
                                    hide-no-data
                                    hide-selected
                                    item-text="naziv"
                                    item-value="id"
                                    label="Mjesto"
                                    prepend-icon="mdi-home-city-outline"
                                    outlined
                                    dense
                                >
                                </v-select>

                                <v-text-field
                                    v-model="form.ulica"
                                    :rules="ulicaRules"
                                    label="Ulica"
                                    prepend-icon="mdi-view-sequential"
                                    required
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.kucni_broj"
                                    :rules="ulicaRules"
                                    label="Kučni broj"
                                    prepend-icon="mdi-numeric-1-box-multiple-outline"
                                    required
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.telefon"
                                    :rules="telefonRules"
                                    label="Telefon"
                                    prepend-icon="mdi-phone"
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.mobitel"
                                    :rules="telefonRules"
                                    label="Mobitel"
                                    prepend-icon="mdi-cellphone"
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.email"
                                    :rules="emailRules"
                                    label="Email"
                                    prepend-icon="mdi-email"
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.web"
                                    label="Web"
                                    prepend-icon="mdi-web"
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Povratak
                            </v-btn>
                        </div></v-col
                    >
                    <v-col>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="azuriraj">
                                Ažuriraj
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            banke: [],
            mjesta: [],
            isLoading: false,
            search: null,
            responseData: "",
            valid: true,
            //ID glavnog profila
            id: null,
            imeRules: [(v) => !!v || "Ime i prezime je potrebno"],
            oibRules: [
                (v) => !!v || "Unesite OIB",
                (v) => (v && v.length > 10) || "OIB nije ispravan",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            telefonRules: [
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            emailRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return /.+@.+\..+/.test(v) || "E-mail nije ispravan";
                    }
                },
            ],
            ulicaRules: [(v) => !!v || "Unos je potreban"],
            ibanRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.length > 10 || "IBAN nije ispravan";
                    }
                },
            ],
            //Objekt forma u koju se sprema dohvat podataka
            form: {},
        };
    },

    methods: {
        odustani() {
            this.$router.push("/gospodarstva");
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        azuriraj() {
            if (this.validate() == true) {
                this.$Api
                    .updateData("gospodarstva", this.id, this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },

        ucitajGospodarstvo(id) {
            this.$Api
                .getByID("gospodarstva", id)
                .then((response) => {
                    this.form = response.data;
                })
                .catch((err) => {
                    pokaziPoruku(error.response.data.error);
                });
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajBanke() {
            try {
                this.banke = JSON.parse(localStorage.getItem("banke"));
            } catch (error) {}
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    created() {
        //Dohvat id iz rute i postavlja u varijablu
        this.id = this.$route.params.id;
        this.ucitajMjesta();
        this.ucitajBanke();
        this.ucitajGospodarstvo(this.id);
    },
};
</script>

<style></style>
