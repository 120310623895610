import store from "../store";
import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const HTTP = axios.create({
    baseURL: store.state.apiUrl,
    headers: {
        "X-API-TOKEN": store.state.token,
        "Content-Type": "multipart/form-data",
    },
    // auth: {
    //     username: SOME_USERNAME,
    //     password: SOME_PASSWORD,
    // },
});

export default {
    uploadImage(file) {
        let formData = new FormData();
        formData.append("logotip", file);
        formData.append("id", 1); //Potvrda da je vlasnik ID uvijek 1
        return HTTP.post("/glavni-logotip", formData);
    },
    uploadDokumenta(file, name) {
        let formData = new FormData();
        formData.append("dokument", file);
        formData.append("name", name);
        return HTTP.post("/upload-dokument", formData);
    },
    uploadDokumentaSlanje(file, name) {
        let formData = new FormData();
        formData.append("dokument", file);
        formData.append("name", name);
        return HTTP.post("/upload-dokument-slanje", formData);
    },
};
