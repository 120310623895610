<template>
    <div>
        <!-- <loader-stranica :state="isLoading"></loader-stranica> -->

        <v-card elevation="3">
            <v-card-title>Odaberite Gospodarstvo</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="gospodarstva"
                    key="id"
                    class="tbGospodarstva"
                    sort-by="name"
                    :search="search"
                    no-results-text="Nema pronađenih"
                    no-data-text="Nema podataka"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    :loading="loading"
                    single-select
                    @page-count="pageCount = $event"
                    @click:row="odabirGospodarstva"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="grey lighten-4">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Pretraga"
                                single-line
                                hide-details
                            ></v-text-field>
                            <v-spacer></v-spacer>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            @click="dodajGospodarstvo()"
                                        >
                                            Dodaj
                                        </v-btn>
                                    </span>
                                </template>
                                <span>Dodaj novo gospodarstvo</span>
                            </v-tooltip>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.naziv`]="{ item }">
                        <router-link
                            :to="{
                                name: 'gospodarstvo',
                                params: { id: item.id },
                            }"
                            class="routerLink"
                        >
                            <v-icon class="mr-3">mdi-barn</v-icon>
                            {{ item.naziv }}
                        </router-link>
                    </template>

                    <div class="text-center">
                        <v-pagination
                            v-model="page"
                            :length="4"
                            circle
                        ></v-pagination>
                    </div>
                </v-data-table>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    circle
                ></v-pagination>
            </v-card-text>
        </v-card>

        <v-card id="parcela" v-show="parcelaState" elevation="3">
            <v-card-title>Unošenje informacija o parceli</v-card-title>

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-card-title>Opće informacije</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="form.naziv"
                                :counter="40"
                                :rules="unosRules"
                                label="Naziv"
                                required
                                outlined
                                dense
                            ></v-text-field>
                            <v-text-field
                                v-model="form.povrsina"
                                :rules="povrsinaRules"
                                label="Površina"
                                required
                                outlined
                                suffix="ha"
                                dense
                            ></v-text-field>

                            <v-text-field
                                v-model="form.gps"
                                label="GPS koordinate"
                                required
                                outlined
                                dense
                                readonly
                                append-icon="mdi-map"
                                @click:append="prikaziKartu"
                                placeholder="Klikom na ikonu odaberite koordinate"
                                hint="Klikom na ikonu odaberite koordinate"
                            >
                            </v-text-field>
                            <v-autocomplete
                                v-model="form.vlasnistvo_id"
                                :items="vlasnistvo"
                                item-text="vlasnistvo_naziv"
                                item-value="id"
                                label="Tip vlasništva"
                                outlined
                                clearable
                                :rules="mjestoRules"
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="form.mjesto_parcele_id"
                                :items="mjesta"
                                item-text="naziv"
                                item-value="id"
                                label="Mjesto / Općina"
                                outlined
                                clearable
                                :rules="mjestoRules"
                                required
                                dense
                            ></v-autocomplete>
                            <v-text-field
                                v-model="form.kat_cestica"
                                label="Katastarska čestica"
                                outlined
                                clearable
                                dense
                            ></v-text-field>
                            <v-text-field
                                v-model="form.arkod"
                                label="ARKOD"
                                required
                                outlined
                                clearable
                                dense
                            ></v-text-field>
                            <v-text-field
                                v-model="form.katastar_link"
                                label="Katastar link"
                                outlined
                                dense
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Kulture predkulture</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.plan_kultura"
                                :items="kulture"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Planirana kultura"
                                outlined
                                :rules="selectRule"
                                dense
                                @change="izborKulturePrinos"
                            >
                            </v-select>
                            <v-text-field
                                v-model="form.plan_prinos"
                                :counter="40"
                                :rules="prinosRules"
                                label="Planirani prinos t/h"
                                required
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.pred_kultura"
                                :items="kulture"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Predkultura"
                                outlined
                                :rules="selectRule"
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.pred_kultura_prinos"
                                :items="predKulturaPrinos"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Prinos predkulture"
                                :rules="selectRule"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Proizvodnja</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.proizvodnja"
                                :items="proizvodnja"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="proizvodnja_naziv"
                                item-value="id"
                                :rules="selectRule"
                                label="Proizvodnja"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6"> </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Žetveni ostaci</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.zetveni_ostaci"
                                :items="zetveniOstaci"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Žetveni ostaci"
                                :rules="selectRule"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.zet_ost_h"
                                :items="[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                    14, 15,
                                ]"
                                label="Količina"
                                suffix="t/h"
                                dense
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-title>Organska Gnojidba</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.organsko_vrsta"
                                :items="organskoVrsta"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Vrsta organskog gnojiva"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.organsko_primjena"
                                :items="organskoPrimjena"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Broj godina od primjene"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.organsko_t_h"
                                :items="organskoLista"
                                label="Količina"
                                required
                                suffix="t/h"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Osnovna Gnojidba</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.osnovna_gnojidba"
                                :items="osnovnoGnojidba"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Osnovna gnojidba"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6"> </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Zemljište</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.exp_nagib"
                                :items="expozicijaNagib"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Ekspozicija i nagib"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.navodnjavanje"
                                :items="navodnjavanje"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Navodnjavanje"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.ur_zemljista"
                                :items="uredjenostZemljista"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Uređenost zemljišta"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.ur_zemljista_kategorija"
                                :items="uredjenostKategorija"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Voda na zemljištu"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Informacije o tlu</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.tip_tla"
                                :items="tloTip"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Tip tla"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.dubina_tla"
                                :items="tloDubina"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Dubina tla"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.biogenost_tla"
                                :items="tloBiogenost"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Biogenost tla"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.rodnost_tla"
                                :items="tloRodnost"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Rodnost tla"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.tip_nasada"
                                :items="nasadTip"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Tip nasada"
                                outlined
                                dense
                            >
                            </v-select>
                            <v-select
                                v-model="form.starost_nasada"
                                :items="nasadStarost"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Starost nasada"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Nasad kategorija</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="form.nasad_kategorija_id"
                                :items="nasadKategorija"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="nasad_kategorija_naziv"
                                item-value="id"
                                label="Vrsta nasada"
                                outlined
                                :rules="selectRule"
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.broj_stabala_cokota_ha"
                                label="Unesite količinu"
                                outlined
                                clearable
                                :rules="brojRules"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-title>Agrotehnika i zaštita</v-card-title>
                <v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.agrotehnika"
                                :items="agrotehnika"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Agrotehnika"
                                outlined
                                dense
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="form.zastita"
                                :items="zastita"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="naziv"
                                item-value="id"
                                label="Zaštita"
                                outlined
                                dense
                            >
                            </v-select>
                            <div class="text-right">
                                <!-- <v-btn
                                    color="primary"
                                    class=""
                                    @click="dodaj()"
                                >
                                    Spremi
                                </v-btn> -->
                            </div>
                        </v-col>
                        <IzbornikUredi
                            @spremi="spremi()"
                            @odustani="odustani()"
                        />
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>

        <!-- Dialog dodaj gospodarstvo-->

        <v-dialog
            v-model="dialogDodajGospodarstvo"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-toolbar dark color="primary">
                <v-toolbar-title>Dodavanje gospodarstva</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="zatvoriDodajGospodarstvo">
                        <v-icon>mdi-close</v-icon>
                        Zatvori
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card>
                <v-form ref="form">
                    <v-card-title> </v-card-title>

                    <NovoGospodarstvo
                        :gumb="gumbStanje"
                        :izvana="pozvanoIzvana"
                    ></NovoGospodarstvo>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- Dialog prikazi kartu-->
        <v-dialog
            v-model="dialogKarta"
            persistent
            fullscreen
            hide-overlay
            eager
            transition="dialog-bottom-transition"
        >
            <v-toolbar dark color="primary">
                <v-toolbar-title>Odabir lokacije parcele</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="zatvoriPrikazKarte">
                        <v-icon>mdi-close</v-icon>
                        Zatvori
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-form ref="formKarta">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Odaberite parcelu</span>
                    </v-card-title>

                    <v-container>
                        <v-row>
                            <v-col>
                                <div v-if="dialogKarta">
                                    <AddMap
                                        :centerProp="koordinate"
                                        @click="mapClick"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider> </v-divider>
                                <v-text-field
                                    v-model="form.gps"
                                    label="GPS koordinate"
                                    required
                                    outlined
                                    dense
                                    readonly
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import NovoGospodarstvo from "../gospodarstvo/UnosGospodarstva.vue";
import AddMap from "../../components/leafletmaps/AddMap";
import IzbornikUredi from "../../components/IzbornikUredjivanje.vue";

export default {
    components: {
        AddMap,
        NovoGospodarstvo,
        IzbornikUredi,
    },
    data() {
        return {
            direction: "left",
            fab: false,
            fling: false,
            hover: true,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            transition: "slide-y-reverse-transition",
            mjesta: [],
            kulture: [],
            predKulturaPrinos: [],
            zetveniOstaci: [],
            vlasnistvo: [],
            organskoVrsta: [],
            organskoPrimjena: [],
            organskoLista: [10, 20, 30, 40, 50, 60, 70, 80, 80, 100],
            osnovnoGnojidba: [],
            expozicijaNagib: [],
            navodnjavanje: [],
            uredjenostZemljista: [],
            uredjenostKategorija: [],
            agrotehnika: [],
            zastita: [],
            tloTip: [],
            tloDubina: [],
            tloBiogenost: [],
            tloRodnost: [],
            nasadTip: [],
            nasadStarost: [],
            nasadKategorija: [],
            gospodarstva: [],
            selectedItem: null,
            isLoading: false,
            progressValue: 0,
            responseData: "",
            valid: false,
            parcelaState: false,
            proizvodnja: [],
            koordinate: [],
            //ID  profila
            id: null,
            planiraniPrinos: {}, //Objekt iz odabrane planirane kulture v-select
            unosRules: [(v) => !!v || "Naziv je potreban"],
            mjestoRules: [(v) => !!v || "Odaberite ili unesite mjesto"],
            brojRules: [
                (v) => !!v || "Unesite broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            povrsinaRules: [
                (v) => !!v || "Unesite vrijednost",
                (v) =>
                    /^\d{1,4}(\.\d{1,3})?$/.test(v) ||
                    "Broj mora biti u formatu ####.###",
            ],
            prinosRules: [
                (v) => !!v || "Unesite vrijednost",

                (v) =>
                    /^\d{1,2}(\.\d{1,1})?$/.test(v) ||
                    "Broj mora biti u formatu ##.#",
                (v) =>
                    (v <= Number(this.planiraniPrinos.max) &&
                        v >= Number(this.planiraniPrinos.min)) ||
                    `Realni prinos za odabranu kulturu ${this.planiraniPrinos.min} t/h - ${this.planiraniPrinos.max} t/h`,
            ],
            selectRule: [(v) => !!v || "Odaberite vrijednost"],
            select: null,
            //Podaci iz forme
            form: {
                vlasnistvo_id: 1,
                plan_kultura: 1,
                pred_kultura: 1,
                proizvodnja: 1,
                zetveni_ostaci: 1,
                organsko_vrsta: 1,
                organsko_primjena: 1,
                organsko_t_h: 1,
                osnovna_gnojidba: 1,
                exp_nagib: 1,
                ur_zemljista: 1,
                ur_zemljista_kategorija: 1,
                navodnjavanje: 1,
                tip_tla: 1,
                rodnost_tla: 1,
                dubina_tla: 1,
                tip_nasada: 1,
                starost_nasada: 1,
                nasad_kategorija_id: 3,
                biogenost_tla: 1,
                agrotehnika: 1,
                zastita: 1,
            },
            headers: [
                {
                    text: "Naziv",
                    align: "start",
                    sortable: false,
                    value: "naziv",
                    width: "20%",
                },
                { text: "Ime i prezime", value: "ime" },
                { text: "OIB", value: "oib" },
                { text: "MIBPG", value: "maticni_broj" },
                { text: "Telefon", value: "telefon" },
                { text: "Mobitel", value: "mobitel" },
            ],
            dialogKarta: false,
            dialogDodajGospodarstvo: null,
            gumbStanje: false,
            pozvanoIzvana: true, //Prop u komponenti unos gospodasrtva dali je pozvana komponenta izvvana
            loading: false,
            search: "",
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
        };
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        spremi() {
            if (this.$refs.form.validate()) {
                axios
                    .all([this.$Api.postData("parcela", this.form)])
                    .then(
                        axios.spread((...responses) => {
                            this.pokaziPoruku(
                                "poruke",
                                "success",
                                responses[0].data.message
                            );
                        })
                    )
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        dodajGospodarstvo() {
            this.dialogDodajGospodarstvo = true;
        },
        zatvoriDodajGospodarstvo() {
            this.dialogDodajGospodarstvo = false;
            this.$Api.getData("gospodarstva").then((response) => {
                this.gospodarstva = response.data;
            });
        },
        izborKulturePrinos(item) {
            const prinos = this.kulture.find((element) => element.id == item);
            this.planiraniPrinos = JSON.parse(prinos.prinos);
        },
        pokaziParcelu(item) {
            if (item) {
                this.parcelaState = true;
            }
        },
        zatvoriPrikazKarte() {
            this.dialogKarta = false;
        },
        prikaziKartu() {
            this.dialogKarta = true;
        },
        rowBoja(row) {
            if (!row.isSelected) {
                row.select(true);
            }
        },
        mapClick(ev) {
            this.form.gps =
                ev.latlng.lat.toPrecision(6) +
                "," +
                ev.latlng.lng.toPrecision(6);
        },
        odustani() {
            this.$router.push({ name: "parcele" });
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        odabirGospodarstva(item, row) {
            this.pokaziParcelu(item);
            this.form.gospodarstvo_id = item.id;
            this.rowBoja(row);
        },
        ucitajGospodarstva() {
            this.$Api.getData("gospodarstva").then((response) => {
                this.gospodarstva = response.data;
            });
        },
        async ucitaj() {
            axios
                .all([
                    await this.$Api.getData("kultura"),
                    this.$Api.getData("prinos"),
                    this.$Api.getData("zetveni-ostaci"),
                    await this.$Api.getData("organsko-vrste"),
                    this.$Api.getData("organsko-primjena"),
                    this.$Api.getData("osnovna-gnojidba"),
                    await this.$Api.getData("ekspozicija-nagib"),
                    this.$Api.getData("navodnjavanje"),
                    this.$Api.getData("uredjenost-zemljista"),
                    await this.$Api.getData("uredjenost-kategorija"),
                    this.$Api.getData("agrotehnika"),
                    this.$Api.getData("zastita"),
                    await this.$Api.getData("tip-tla"),
                    this.$Api.getData("dubina-tla"),
                    this.$Api.getData("biogenost-tla"),
                    await this.$Api.getData("rodnost-tla"),
                    this.$Api.getData("nasad-tip"),
                    this.$Api.getData("nasad-starost"),
                    this.$Api.getData("proizvodnja"),
                    await this.$Api.getData("nasad-kategorija"),
                ])
                .then(
                    axios.spread((...responses) => {
                        this.kulture = responses[0].data;
                        this.predKulturaPrinos = responses[1].data;
                        this.zetveniOstaci = responses[2].data;
                        this.organskoVrsta = responses[3].data;
                        this.organskoPrimjena = responses[4].data;
                        this.osnovnoGnojidba = responses[5].data;
                        this.expozicijaNagib = responses[6].data;
                        this.navodnjavanje = responses[7].data;
                        this.uredjenostZemljista = responses[8].data;
                        this.uredjenostKategorija = responses[9].data;
                        this.agrotehnika = responses[10].data;
                        this.zastita = responses[11].data;
                        this.tloTip = responses[12].data;
                        this.tloDubina = responses[13].data;
                        this.tloBiogenost = responses[14].data;
                        this.tloRodnost = responses[15].data;
                        this.nasadTip = responses[16].data;
                        this.nasadStarost = responses[17].data;
                        this.proizvodnja = responses[18].data;
                        this.nasadKategorija = responses[19].data;
                    })
                )
                .catch((errors) => {
                    this.pokaziPoruku("poruke", "error", errors);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.izborKulturePrinos(this.form.plan_kultura);
                });
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajVlasnistvo() {
            this.$Api.getData("vlasnistvo").then((response) => {
                this.vlasnistvo = response.data;
            });
        },
    },
    created() {
        this.ucitajGospodarstva(); //Ucitavanje posebno radi izbora gospodarstva
        this.ucitaj();
        this.ucitajMjesta();
        this.ucitajVlasnistvo();
    },
    mounted() {},
};
</script>

<style scoped>
.dodaj-gospodarstvo {
    margin-top: -7px;
}
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
    position: absolute;
}

#create .v-btn--floating {
    position: relative;
}
tr.v-data-table__selected {
    background: #ccf7d0 !important;
}

.row-tbGospodarstva > .v-data-table__wrapper > table > tbody > tr:hover {
    cursor: pointer !important;
}
</style>
