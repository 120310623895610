<template>
    <div>
        <v-card class="mx-auto">
            <v-container class="pa-3">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="cyan darken-1 mb-5">
                            <span class="subtitle white--text">
                                Pregled i uređivanje preporuke</span
                            >
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-expansion-panels multiple hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="amber lighten-4"
                            >Detaljne informacije parcele i
                            analizi</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Informacije o parceli
                                    </h4>
                                    <v-simple-table
                                        v-if="preporuka"
                                        light
                                        class="osnovnoTablica"
                                        dense
                                    >
                                        <template>
                                            <tbody>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Klijent
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.gospodarstvo_ime
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Uzorak broj
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.uzorak_broj
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Analiza broj
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.analiza_broj
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Naziv
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.parcela_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Planirani prinos
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.parcela_plan_prinos
                                                        }}
                                                        t/ha
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Organsko gnojivo
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.organsko_vrsta_naziv
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Površina
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.parcela_povrsina
                                                        }}
                                                        ha
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Predkultura
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.pred_kultura_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Organsko zaorano
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.organsko_t_h
                                                        }}
                                                        t/ha
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Kultura
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.kultura_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Žetveni ostaci
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div
                                                                    style="
                                                                        text-align: center;
                                                                    "
                                                                >
                                                                    {{
                                                                        preporuka.zetveni_ostaci_naziv
                                                                    }}
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div
                                                                    style="
                                                                        text-align: center;
                                                                    "
                                                                >
                                                                    {{
                                                                        preporuka.zet_ost_h
                                                                    }}
                                                                    t/ha
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Godina primjene
                                                            organskog
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.organsko_primjena_naziv
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Površina
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.parcela_povrsina
                                                        }}
                                                        ha
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Predkultura
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.pred_kultura_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Organsko zaorano
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.organsko_t_h
                                                        }}
                                                        t/ha
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Proizvodnja
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            preporuka.proizvodnja_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Tip nasada
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.tip_nasada_naziv
                                                        }}
                                                    </td>

                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="stupacDesno"
                                                    ></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Rezultat analize
                                        {{ kalkulacija.analiza_broj }}
                                    </h4>
                                    <v-simple-table class="osnovnoTablica">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        pH-KCI
                                                    </th>
                                                    <th class="text-center">
                                                        pH-H<sub>2</sub>O
                                                    </th>
                                                    <th class="text-center">
                                                        AL-P<sub>2</sub>O<sub
                                                            >5</sub
                                                        >
                                                    </th>
                                                    <th class="text-center">
                                                        AL-K<sub>2</sub>O
                                                    </th>
                                                    <th class="text-center">
                                                        Humus %
                                                    </th>
                                                    <th class="text-center">
                                                        CaCO<sub>3</sub>O %
                                                    </th>
                                                    <th class="text-center">
                                                        Hk cmol<sup> (+)</sup>kg
                                                        <sup>-1</sup>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_phkci
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_phh2o
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_alp2o5
                                                        }}
                                                    </td>

                                                    <td>
                                                        {{
                                                            preporuka.analiza_alpk2o
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_humus
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_caco3
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            preporuka.analiza_hkcmol
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Rezultat kalkulacije
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-simple-table v-if="rezultatNPK" dense>
                                        <template v-slot:default>
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Optimalna NPK
                                                        formulacija
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.optimalni_npk
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Minimalni udio dušika
                                                        N-min
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.min_udio_Nmin
                                                        }}
                                                        N/ha/god
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Potreba aktivne tvari
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.potreba_npk
                                                        }}
                                                        kg/ha
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>NPK</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_n
                                                        }}:
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_p
                                                        }}:
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_k
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.npk
                                                                .parcela_ukupno_npk
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UREA</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.urea
                                                                .udio_urea
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.urea
                                                                .parcela_ukupno_urea
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>KAN</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.kan
                                                                .udio_kan
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.kan
                                                                .parcela_ukupno_kan
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Bilanca NPK hraniva u
                                                        kg/ha
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_n
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_p
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_k
                                                        }}
                                                        Status bilance:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .ukupno_bilanca
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Komponenta P</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .p2o5_naziv
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .p2o5_kolicina
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .parcela_ukupno_p2o5
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Komponenta K</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .k2o_naziv
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .k2o_kolicina
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .parcela_ukupno_k2o
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                    <v-col cols="12" class="pa-5"> </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card-title class="cyan darken-1 mb-5">
                            <span class="subtitle white--text">
                                Uredite preporuke</span
                            >
                        </v-card-title>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                v-model.number="form.preporuka_naziv"
                                label="Naziv preporuke"
                                required
                                outlined
                                clearable
                                :rules="nazivRules"
                                dense
                            ></v-text-field>
                        </v-form>
                        <TipTapEditor v-model="form.preporuka_sadrzaj" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" @click="odustani()">
                                Odustani
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="text-right">
                            <v-btn
                                color="primary"
                                @click="spremiPreporuku(preporukaId)"
                            >
                                Spremi promjene
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <!-- {{ preporuka.preporuka_analiza_id }} -->
        </v-card>
    </div>
</template>

<script>
import TipTapEditor from "../../components/Editor.vue";
export default {
    components: { TipTapEditor },
    data: () => ({
        valid: false,
        form: {},
        preporukaId: null,
        preporuka: null,
        kalkulacija: "",
        formatedDate: "",
        rezultatNPK: null,
        preporuke: null,
        noviBrojPreporuka: "",
        nazivRules: [(v) => !!v || "Naziv je potreban"],
    }),
    created() {
        console.log("ROUTE PARAM", this.$route.params.id);
        this.preporukaId = this.$route.params.id;

        this.ucitajPreporuku(this.preporukaId);

        // this.generirajTekstPreporuke();
        //Danasnji datum za bazu
        this.form.preporuka_datum = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");
    },
    methods: {
        ucitajKalkulaciju(id) {
            this.$Api
                .getByID("kalkulacija", id)
                .then((response) => {
                    this.kalkulacija = response.data;
                    this.form.preporuka_analiza_id =
                        this.kalkulacija.analiza_id;

                    this.rezultatNPK = JSON.parse(
                        response.data.kalkulacija_rezultat
                    );

                    this.formatedDate = this.formatDate(
                        this.kalkulacija.kalkulacija_datum
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        spremiPreporuku(preporukaId) {
            if (this.validate()) {
                this.form.preporuka_operater_id = JSON.parse(
                    sessionStorage.getItem("user")
                ).id;
                this.$Api
                    .updateData("preporuka", preporukaId, this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },

        ucitajPreporuku(id) {
            this.$Api
                .getByID("preporuka", id)
                .then((response) => {
                    this.preporuka = response.data;
                    this.form = response.data;
                    this.ucitajKalkulaciju(
                        this.preporuka.preporuka_kalkulacija_id
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        odustani() {
            this.$router.push("/preporuke");
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>

<style scoped>
.naglaseno {
    color: #682a2a;
}
.naglasenoBold {
    font-weight: bold;
}
.verticalSpacer {
    margin-top: 100px;
    margin-bottom: 100px;
}

.osnovnoTablica tr {
    background-color: #faf7e4;
    border: 1px solid;
}
.osnovnoTablica td {
    text-align: center;
}
.osnovnoTablica td.stupacLijevo {
    text-align: left;
    width: 18%;
    background-color: #faf7e4;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}
</style>
