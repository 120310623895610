var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.editor)?_c('div',{staticClass:"editor"},[_c('v-container',{attrs:{"pa-0":"","ma-0":""}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"editorMenu pa-2"},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},[_c('v-icon',[_vm._v("mdi-undo")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},[_c('v-icon',[_vm._v("mdi-redo")])],1),_vm._v(" "),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('v-icon',[_vm._v("mdi-format-bold")])],1),_vm._v(" "),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('v-icon',[_vm._v("mdi-format-italic")])],1),_vm._v(" "),_c('v-icon',[_vm._v("mdi-dots-vertical")]),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive({
                                textAlign: 'left',
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .setTextAlign('left')
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-align-left")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive({
                                textAlign: 'center',
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .setTextAlign('center')
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-align-center")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive({
                                textAlign: 'right',
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .setTextAlign('right')
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-align-right")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive({
                                textAlign: 'justify',
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .setTextAlign('justify')
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-align-justify")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('paragraph'),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},[_c('v-icon',[_vm._v("mdi-format-paragraph")])],1),_vm._v(" "),_c('v-icon',[_vm._v("mdi-dots-vertical")]),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('heading', {
                                level: 1,
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 1 })
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-1")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('heading', {
                                level: 2,
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-2")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('heading', {
                                level: 3,
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 3 })
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-3")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('heading', {
                                level: 4,
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 4 })
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-4")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('heading', {
                                level: 5,
                            }),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 5 })
                                .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-5")])],1),_vm._v(" "),_c('v-icon',[_vm._v("mdi-dots-vertical")]),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('bulletList'),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('orderedList'),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1),_vm._v(" "),_c('v-btn',{class:{
                            'is-active': _vm.editor.isActive('blockquote'),
                        },attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().toggleBlockquote().run()}}},[_c('v-icon',[_vm._v("mdi-format-quote-open")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.editor.chain().focus().setHorizontalRule().run()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)])],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }