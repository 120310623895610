<template>
    <div>
        <h2>PREPORUKA TESt</h2>
        <v-text-field v-model="search" placeholder="Pretraga" label="Pretraga">
        </v-text-field>

        <v-data-table
            ref="datatableAnalize"
            :headers="headersAnalize"
            :items="analize"
            key="id"
            item-key="analiza_id"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            loading-text="Učitavanje... Molimo pričekajte"
            @page-count="pageCount = $event"
            :sort-desc="true"
            @click:row="odabranaAnaliza"
            single-select
            :search="search"
        >
            <div class="text-center"></div>
            <template v-slot:footer>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    circle
                    :total-visible="10"
                ></v-pagination>
            </template>
        </v-data-table>

        <v-row>
            <v-col>
                <v-text-field
                    v-model="unosPhKci"
                    placeholder="PhKCI"
                    label="Ph"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="unosAlP2O5"
                    placeholder="ALPo5"
                    label="Fosfor"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="unosAlK2O"
                    placeholder="AlK2O"
                    label="Kalij"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="unosHumus"
                    placeholder="Humus"
                    label="Humus"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="unosCaco3"
                    placeholder="CaCO3"
                    label="Karbonat"
                >
                </v-text-field
            ></v-col>
        </v-row>
        <v-row>
            <v-col>
                ODGOVOR: {{ odgovorPhkci }}
                <br />
                <h4>Kiselost tla:</h4>
                {{ odgovorPhkci.odgovor }}
            </v-col>
            <v-col>
                ODGOVOR: {{ odgovorAlP2O5 }}
                <br />
                <h4>Opskrbljenost tla fosforom:</h4>
                {{ odgovorAlP2O5.odgovor }}
            </v-col>
            <v-col>
                ODGOVOR: {{ odgovorAlK2O }}
                <br />
                <h4>Opskrbljenost tla kalijem:</h4>
                {{ odgovorAlK2O.odgovor }}
            </v-col>
            <v-col>
                ODGOVOR: {{ odgovorHumus }}
                <br />
                <h4>Opskrbljenost tla humusom:</h4>
                {{ odgovorHumus.odgovor }}
            </v-col>
            <v-col>
                ODGOVOR: {{ odgovorCaco3 }}
                <br />
                <h4>Karbonacija tla:</h4>
                {{ odgovorCaco3.odgovor }}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                POTREBA Ca:
                <br />
                {{ potrebaCa }}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div v-html="PreporukaRezultat"></div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import TestIspis from "../preporuka/GeneriranjePreporuke";
import izracunCa from "../../formule/izracunKalcilizacije";
export default {
    components: {},
    data() {
        return {
            PreporukaRezultat: "",
            unosPhKci: "",
            odgovorPhkci: "",
            unosAlP2O5: "",
            odgovorAlP2O5: "",
            unosAlK2O: "",
            odgovorAlK2O: "",
            unosHumus: "",
            odgovorHumus: "",
            unosCaco3: "",
            odgovorCaco3: "",
            potrebaCa: 0,
            analize: [],
            singleAnaliza: {},
            bazaJson: {
                phKCI: [
                    { min: 0, max: 5.5, odgovor: "Jako kiselo tlo" },
                    { min: 5.5, max: 6, odgovor: "Kiselo tlo" },
                    { min: 6, max: 6.5, odgovor: "Slabo kiselo tlo" },
                    { min: 6.5, max: 7.3, odgovor: "Neutralno tlo" },
                    { min: 7.3, max: 100, odgovor: "Alkalno tlo" },
                    { min: 100, max: 1000000, odgovor: "Neispravn podatak" },
                ],
                AlP2O5: [
                    { min: 0, max: 5, odgovor: "Jako siromašno" },
                    { min: 5, max: 12, odgovor: "Siromašno" },
                    { min: 12, max: 20, odgovor: "Dobro" },
                    { min: 20, max: 30, odgovor: "Visoko" },
                    { min: 30, max: 100, odgovor: "Ekstremno Visoko" },
                    { min: 100, max: 1000000, odgovor: "Neispravn podatak" },
                ],
                AlK2O: [
                    { min: 0, max: 12, odgovor: "Jako siromašno" },
                    { min: 12, max: 20, odgovor: "Siromašno" },
                    { min: 20, max: 30, odgovor: "Dobro" },
                    { min: 30, max: 45, odgovor: "Visoko" },
                    { min: 45, max: 100, odgovor: "Ekstremno Visoko" },
                    { min: 100, max: 1000000, odgovor: "Neispravn podatak" },
                ],
                humus: [
                    { min: 0, max: 1, odgovor: "Vrlo slabo humozno tlo" },
                    { min: 1, max: 3, odgovor: "Slabo humozno tlo" },
                    { min: 3, max: 5, odgovor: "Humozno tlo" },
                    { min: 5, max: 10, odgovor: "Jako humozno tlo" },
                    { min: 10, max: 100, odgovor: "Vrlo jako humozno tlo" },
                    { min: 100, max: 1000000, odgovor: "Neispravn podatak" },
                ],
                Caco3: [
                    { min: 0, max: 10, odgovor: "Slabo karbonatno" },
                    { min: 10, max: 30, odgovor: "Srednje karbonatno" },
                    { min: 30, max: 100, odgovor: "Jako karbonatno" },
                    { min: 100, max: 1000000, odgovor: "Neispravn podatak" },
                ],
            },
            page: 1,
            pageCount: 0,
            itemsPerPage: 7,
            search: "",
            headersAnalize: [
                {
                    text: "pH-KCI",
                    sortable: false,
                    value: "analiza_phkci",
                },
                {
                    text: "pH-H2O",
                    sortable: false,
                    value: "analiza_phh2o",
                },
                {
                    text: "AL-P2O5",
                    sortable: false,
                    value: "analiza_alp2o5",
                },
                {
                    text: "AL-K2O",
                    sortable: false,
                    value: "analiza_alpk2o",
                },
                {
                    text: "Humus",
                    sortable: false,
                    value: "analiza_humus",
                },
                {
                    text: "CaCO3",
                    sortable: false,
                    value: "analiza_caco3",
                },
                {
                    text: "Hk cmol",
                    sortable: false,
                    value: "analiza_hkcmol",
                },
            ],
        };
    },
    created() {
        this.ucitajAnalize();
    },
    watch: {
        unosPhKci(newVal) {
            this.odgovorPhkci = this.dohvatiPhKCI(newVal);
        },
        unosAlP2O5(newVal) {
            this.odgovorAlP2O5 = this.dohvatiAlP2O5(newVal);
        },
        unosAlK2O(newVal) {
            this.odgovorAlK2O = this.dohvatiAlK2O(newVal);
        },
        unosHumus(newVal) {
            this.odgovorHumus = this.dohvatiHumus(newVal);
        },
        unosCaco3(newVal) {
            this.odgovorCaco3 = this.dohvatiCaco3(newVal);
        },
    },
    methods: {
        dohvatiPhKCI(phKciArg) {
            var result;

            if (phKciArg == "") return (result = 0);

            this.bazaJson.phKCI.find((item) => {
                if (
                    parseFloat(phKciArg) >= item.min &&
                    parseFloat(phKciArg) <= item.max
                ) {
                    result = item;
                }
            });

            return result;
        },
        dohvatiAlP2O5(AlP2O5) {
            var result;
            if (AlP2O5 == "") return (result = 0);
            this.bazaJson.AlP2O5.find((item) => {
                if (
                    parseFloat(AlP2O5) >= item.min &&
                    parseFloat(AlP2O5) <= item.max
                ) {
                    result = item;
                }
            });

            return result;
        },
        dohvatiAlK2O(AlK2O) {
            var result;
            if (AlK2O == "") return (result = 0);
            this.bazaJson.AlK2O.find((item) => {
                if (
                    parseFloat(AlK2O) >= item.min &&
                    parseFloat(AlK2O) <= item.max
                ) {
                    result = item;
                }
            });
            return result;
        },
        dohvatiHumus(humus) {
            var result;
            if (humus == "") return (result = 0);
            this.bazaJson.humus.find((item) => {
                if (
                    parseFloat(humus) >= item.min &&
                    parseFloat(humus) <= item.max
                ) {
                    result = item;
                }
            });
            return result;
        },
        dohvatiCaco3(Caco3) {
            var result;
            if (Caco3 == "") return (result = 0);
            this.bazaJson.Caco3.find((item) => {
                if (
                    parseFloat(Caco3) >= item.min &&
                    parseFloat(Caco3) <= item.max
                ) {
                    result = item;
                }
            });
            return result;
        },
        ucitajAnalize() {
            this.loading = true;
            this.$Api
                .getData("analize")
                .then((response) => {
                    this.analize = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        generiranjePreporuke() {
            this.PreporukaRezultat += "Informacije o gospodarstvu i parceli";
            this.PreporukaRezultat = TestIspis.GenerirajPreporuku(
                this.singleAnaliza
            );
        },
        odabranaAnaliza(item, row) {
            if (!row.isSelected) {
                row.select(true);
            }
            this.singleAnaliza = item;

            this.unosPhKci = item.analiza_phkci;
            this.unosAlP2O5 = item.analiza_alp2o5;
            this.unosAlK2O = item.analiza_alpk2o;
            this.unosHumus = item.analiza_humus;
            this.unosCaco3 = item.analiza_caco3;

            this.potrebaCa = izracunCa.izracunKalcilizacije(
                item.analiza_hkcmol,
                item.analiza_phkci
            );
        },
    },
};
</script>
