<template>
    <div>
        <router-view></router-view>
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                    Odabir gospodarstva i parcele
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2">
                    Unos informacija uzorka
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3"> Spremanje </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="mb-12" color="">
                        <v-row justify="space-between">
                            <v-col cols="12" md="12">
                                <h3 class="mb-3">Odaberite gospodarstvo</h3>
                                <v-data-table
                                    class="tbGospodarstva"
                                    :headers="headersGospodarstvo"
                                    :items="gospodarstva"
                                    key="id"
                                    sort-by="naziv"
                                    :search="searchGospodarstvo"
                                    no-results-text="Nema pronađenih"
                                    fixed-header
                                    :page.sync="page"
                                    :items-per-page="itemsPerPage"
                                    :item-class="rowColor"
                                    hide-default-footer
                                    :loading="loading"
                                    single-select
                                    v-model="odabranoGospodarstva"
                                    @page-count="pageCount = $event"
                                    @click:row="ucitajParcele"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-text-field
                                                v-model="searchGospodarstvo"
                                                append-icon="mdi-magnify"
                                                label="Pretraga"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </template>

                                    <div class="text-center">
                                        <v-pagination
                                            v-model="page"
                                            :length="4"
                                            circle
                                        ></v-pagination>
                                    </div>
                                </v-data-table>
                                <v-pagination
                                    v-model="page"
                                    :length="pageCount"
                                    circle
                                    :total-visible="10"
                                ></v-pagination>
                                <h3 class="mb-3">Odaberite parcelu</h3>
                                <v-data-table
                                    :headers="headers"
                                    :items="parcele"
                                    key="parcela_id"
                                    sort-by="name"
                                    :search="searchParcela"
                                    no-results-text="Nema pronađenih"
                                    no-data-text="Nema podataka"
                                    fixed-header
                                    :page.sync="page"
                                    :items-per-page="itemsPerPageParcele"
                                    hide-default-footer
                                    single-select
                                    :loading="loading"
                                    v-model="odabranoParcele"
                                    @page-count="pageCount = $event"
                                    @click:row="odabirParcele"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-text-field
                                                v-model="searchParcela"
                                                append-icon="mdi-magnify"
                                                label="Pretraga"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.naziv`]="{ item }">
                                        <router-link
                                            :to="{
                                                name: 'parcela',
                                                params: { id: item.id },
                                            }"
                                        >
                                            {{ item.naziv }}
                                        </router-link>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="provjeriStatus(1)"
                        :disabled="gumbStatus"
                    >
                        Dalje
                    </v-btn>

                    <v-btn text :to="{ name: 'uzorci' }">Odustani</v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card class="mb-12" color="">
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                v-model="form.naziv"
                                :rules="nazivRules"
                                label="Naziv"
                                required
                                outlined
                                dense
                            ></v-text-field>
                            <v-text-field
                                v-model="uzorakBroj"
                                :rules="labBrojRules"
                                label="Broj uzorka"
                                placeholder="Laboratorijski broj"
                                required
                                outlined
                                :prefix="uzorakbrojPrefix"
                                dense
                            ></v-text-field>

                            <v-menu
                                v-model="izbornikDatum"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formatedDate"
                                        label="Datum"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="datum"
                                    @input="izbornikDatum = false"
                                    locale="hr-HR"
                                ></v-date-picker>
                            </v-menu>

                            <v-select
                                v-model="form.dubina"
                                :items="['0-30', '30-60']"
                                item-text="naziv"
                                item-value="id"
                                required
                                :rules="dubinaUzorka"
                                label="Dubina uzorka"
                                dense
                                outlined
                                suffix="cm"
                            ></v-select>
                            <v-textarea
                                v-model="form.napomena"
                                outlined
                                name="input-7-4"
                                label="Napomena"
                                value="Ovdje ide napomena"
                            ></v-textarea>
                            <v-switch
                                class="ml-5"
                                v-model="form.korisnik_uzorkovao"
                                label="Uzorak uzeo korisnik samostalno"
                                color="success"
                                input-value="true"
                            >
                            </v-switch>
                        </v-form>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="provjeriStatus(2)"
                        :disabled="gumbStatus"
                    >
                        Dalje
                    </v-btn>

                    <v-btn text @click="step = 1"> Povratak </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card class="mb-12" color="">
                        <v-card-text>
                            <v-container>
                                <v-row justify="space-between">
                                    <v-col
                                        cols="12"
                                        md="12"
                                        class="orange lighten-5"
                                    >
                                        <h1 class="pb-2 display-1">
                                            Uzorak
                                            <span>{{ izvjestaj.broj }}</span>
                                        </h1>
                                        <h3 class="pb-2 font-weight-light">
                                            {{ izvjestaj.operator }}
                                        </h3>
                                        <h4 class="pb-2 font-weight-light">
                                            {{ izvjestaj.datum }}
                                        </h4>
                                    </v-col>
                                </v-row>

                                <v-row justify="space-between">
                                    <v-col cols="12" md="6">
                                        <h2 class="pb-3 font-weight-light">
                                            Gospodarstvo
                                        </h2>
                                        <v-divider></v-divider>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">
                                                Naziv gospodartsva
                                            </h4>
                                            {{ izvjestaj.gospodarstvo }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Mjesto općina</h4>
                                            {{ izvjestaj.mjesto }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Vlasnik OPG</h4>
                                            {{ izvjestaj.vlasnik }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <h2 class="pb-3 font-weight-light">
                                            Parcela
                                        </h2>
                                        <v-divider></v-divider>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Naziv parcele</h4>
                                            {{ izvjestaj.parcela_naziv }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">
                                                Površina parcele
                                            </h4>
                                            {{ izvjestaj.parcela_povrsina }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">
                                                Katastarska čestica
                                            </h4>
                                            {{ izvjestaj.parcela_cestica }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row justify="space-between">
                                    <v-col cols="12" md="6">
                                        <h2 class="pb-3 font-weight-light">
                                            Uzorak
                                        </h2>

                                        <v-divider></v-divider>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Naziv uzorka</h4>
                                            {{ izvjestaj.uzorak_naziv }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Broj uzorka</h4>
                                            {{ izvjestaj.uzorak_broj }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">
                                                Laboratorijski Broj uzorka
                                            </h4>
                                            {{ izvjestaj.lab_broj }}
                                        </div>
                                        <div class="pb-3 pt-1">
                                            <h4 class="pb-2">Dubina uzorka</h4>
                                            {{ izvjestaj.uzorak_dubina }} cm
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <h2 class="pb-3 font-weight-light">
                                            <h2 class="pb-3 font-weight-light">
                                                Napomena
                                            </h2>
                                        </h2>
                                        <div class="pb-2 pt-1">
                                            {{ izvjestaj.uzorak_napomena }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>

                    <v-btn color="primary" @click="spremiUzorak()">
                        Spremi
                    </v-btn>

                    <v-btn text @click="step = 2"> Povratak </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
<script>
export default {
    data() {
        return {
            uzorakBroj: null,
            uzorakbrojPrefix: "",
            uzorakBrojKonacno: null,
            laboratorijskiBroj: null,
            step: 1,
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            itemsPerPageParcele: 10,
            searchGospodarstvo: "",
            searchParcela: "",
            valid: true,
            odabranoGospodarstva: [],
            odabranoParcele: [],
            rowColor: "",
            izbornikDatum: false,
            loading: false,
            datum: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            formatedDate: "",
            gospodarstva: [],
            gospodarstvoId: "",
            parcele: [],
            mjesta: [],
            uzorci: [],
            form: {
                dubina: "0-30", //Postavljeno na default 0-30
                datum: null,
                korisnik_uzorkovao: false,
                broj: 0,
            },
            izvjestaj: {},
            nazivRules: [(v) => !!v || "Naziv je potreban"],
            unosRules: [(v) => !!v || "Ime i prezime je potrebno"],
            dubinaUzorka: [(v) => !!v || "Potreban je odabir"],
            brojRules: [
                (v) => !!v || "Unesite broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            labBrojRules: [
                (v) =>
                    !this.provjeriLabBroj(v) || "Laboratorijski Broj postoji",
            ],
            gumbStatus: true,
            headersGospodarstvo: [
                {
                    text: "Naziv",
                    align: "start",
                    sortable: false,
                    value: "naziv",
                },
                { text: "Ime i prezime", value: "ime" },
                { text: "OIB", value: "oib" },
                { text: "MIBPG", value: "maticni_broj" },
                { text: "Mjesto", value: "gospodarstvo_mjesto_naziv" },
                { text: "Ulica", value: "ulica" },
                { text: "Kučni broj", value: "kucni_broj" },
                { text: "Telefon", value: "telefon" },
                { text: "Mobitel", value: "mobitel" },
                { text: "Email", value: "email" },
                { text: "Web", value: "web" },
            ],
            headers: [
                {
                    text: "Naziv",
                    align: "start",
                    sortable: true,
                    value: "naziv",
                },
                {
                    text: "Gospodarstvo",
                    value: "gospodarstvo_naziv",
                    sortable: false,
                },
                {
                    text: "Mjesto/Općina",
                    value: "mjesto_parcele_naziv",
                    sortable: false,
                },
                { text: "Površina", value: "povrsina", sortable: false },
                { text: "ARKOD", value: "arkod", sortable: false },
                {
                    text: "Katastarska čestica",
                    value: "kat_cestica",
                    sortable: false,
                },
                {
                    text: "Koordinate",
                    value: "gps",
                    sortable: false,
                },
                {
                    text: "Katastar veza",
                    value: "katastar_link",
                    sortable: false,
                },
            ],
            testBroj: 0,
        };
    },

    created() {
        this.ucitaj();
        this.ucitajGospodarstva();
        this.ucitajUzorke();
        this.formatedDate = this.todayDate();
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.form.datum = this.formatISODate(val);
        },
        uzorakBroj(newVal) {
            this.uzorakBrojKonacno = this.uzorakbrojPrefix + newVal;
            this.form.naziv = this.uzorakbrojPrefix + newVal;
            this.laboratorijskiBroj = newVal;
            this.form.lab_broj = parseInt(newVal);
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + "." + mm + "." + yyyy;

            return today;
        },

        ucitaj() {
            this.form.operator_id = JSON.parse(
                sessionStorage.getItem("user")
            ).id;
            this.form.analiza_id = null;
            this.ucitajMjesta();
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajUzorke() {
            this.$Api
                .getData("uzorci")
                .then((response) => {
                    this.uzorci = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajGospodarstva() {
            this.$Api
                .getData("gospodarstva")
                .then((response) => {
                    this.gospodarstva = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },

        generirajNovibrojUzorka(parcela) {
            let zupanijaParcele = parcela.parcela_zupanija_broj;
            let mjestoParcele = parcela.mjesto_parcele_broj;
            let djelatnost = JSON.parse(
                sessionStorage.getItem("user")
            ).djelatnost_broj;
            this.uzorakbrojPrefix =
                zupanijaParcele + "-" + mjestoParcele + "-" + djelatnost + "-";
            return (
                zupanijaParcele +
                "-" +
                mjestoParcele +
                "-" +
                djelatnost +
                "-" +
                this.slijedeciLabBroj()
            );
        },
        slijedeciLabBroj() {
            let najveciBroj = 0;
            this.uzorci.forEach((uzorak) => {
                if (uzorak.lab_broj > najveciBroj)
                    najveciBroj = uzorak.lab_broj;
            });
            return this.formatirajBrojUzorka(najveciBroj + 1);
        },
        provjeriLabBroj(newVal) {
            //Ako je true onda postoji
            let rezultat = false;
            this.uzorci.forEach((uzorak) => {
                if (uzorak.lab_broj == newVal) rezultat = true;
            });

            return rezultat;
        },
        ucitajParcele(item, row) {
            this.gumbStatus = true;
            this.rowBoja(row);
            this.izvjestaj.gospodarstvo = item.naziv;
            this.form.mjesto_id = parseInt(item.mjesto_id);
            this.izvjestaj.vlasnik = item.ime;

            this.izvjestaj.gospodarstvo = item.mjesto;
            this.$Api
                .getParceleByGospodarstvoId(item.id)
                .then((response) => {
                    this.parcele = response.data;
                    //Sprema odabrano gospodarstvo id u form
                    this.form.gospodarstvo_id = item.id;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        stvoriIzvjestaj() {
            this.izvjestaj.broj = this.uzorakBrojKonacno;
            this.izvjestaj.operator = JSON.parse(
                sessionStorage.getItem("user")
            ).name;
            this.izvjestaj.datum = this.formatedDate;
            this.izvjestaj.uzorak_naziv = this.form.naziv;
            this.izvjestaj.uzorak_broj = this.uzorakBrojKonacno;
            this.izvjestaj.uzorak_dubina = this.form.dubina;
            this.izvjestaj.uzorak_napomena = this.form.napomena;
            this.izvjestaj.lab_broj = this.form.lab_broj;
        },
        spremiUzorak() {
            if (this.provjeriLabBroj(this.uzorakBroj)) {
                this.pokaziPoruku(
                    "poruke",
                    "error",
                    "Laboratorijski broj postoji"
                );
                return false;
            }

            this.form.datum = this.datum;
            this.form.broj = this.uzorakBrojKonacno; //Postavljanje broja za spremanje u konacni broj
            this.$Api
                .postData("uzorak", this.form)
                .then((response) => {
                    this.ponovnoInit();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    //Ponovno posatvljanje radi dupliciranja zbog konacnog broja
                    this.form.broj = this.slijedeciLabBroj();
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ponovnoInit() {
            this.step = 1;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.odabranoGospodarstva = [];
            this.odabranoParcele = [];
        },
        odabirParcele(item, row) {
            this.form.parcela_id = item.id;
            this.gumbStatus = false;
            this.rowBoja(row);
            this.izvjestaj.parcela_naziv = item.naziv;
            this.izvjestaj.parcela_povrsina = item.povrsina;
            this.izvjestaj.parcela_cestica = item.kat_cestica;
            this.izvjestaj.mjesto = this.mjesta.find(
                (o) => o.id === this.form.mjesto_id
            ).naziv;
            this.uzorakBroj = this.slijedeciLabBroj();
            this.uzorakBrojKonacno = this.generirajNovibrojUzorka(item);
            this.form.naziv = this.generirajNovibrojUzorka(item);
        },
        formatirajBrojUzorka(broj) {
            return String(broj).padStart(6, "0");
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        rowBoja(row) {
            if (!row.isSelected) {
                row.select(true);
            }
        },
        provjeriStatus(step) {
            switch (step) {
                case 1:
                    this.step += 1;
                    break;
                case 2:
                    if (this.$refs.form.validate()) {
                        this.step += 1;
                        this.stvoriIzvjestaj();
                    }
                    break;
                case 3:
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
<style>
tr.v-data-table__selected {
    background: #ccf7d0 !important;
}

.row-tbGospodarstva > .v-data-table__wrapper > table > tbody > tr:hover {
    cursor: pointer !important;
}
</style>
