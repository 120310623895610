<template></template>
<template>
    <v-card>
        <v-tabs v-model="tab" vertical>
            <v-tab
                v-for="item in items"
                :key="item.tab"
                class="tab"
                active-class="tabActive"
            >
                {{ item.tab }}
            </v-tab>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="item in items"
                    :key="item.tab"
                    active-class="tabItemActive"
                >
                    <v-card flat>
                        <v-card-text class="pa-0"
                            ><component v-bind:is="item.content"></component
                        ></v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-card>
</template>
<script>
import TipTla from "./TipTla.vue";
import DubinaTla from "./DubinaTla.vue";
import BiogenostTla from "./BiogenostTla.vue";
import TipNasada from "./TipNasada.vue";
import StarostNasada from "./StarostNasada.vue";
import ZetveniOstaci from "./ZetveniOstaci.vue";
import ExpozicijaNagib from "./ExpozicijaNagib.vue";
import Navodnjavanje from "./Navodnjavanje.vue";
import UredjenostZemljista from "./UredjenostZemljista.vue";
import AgroTehnika from "./AgroTehnika.vue";
import Zastita from "./Zastita.vue";

export default {
    components: {
        "tip-tla": TipTla,
        "dubina-tla": DubinaTla,
        "biogenost-tla": BiogenostTla,
        "tip-nasada": TipNasada,
        "starost-nasada": StarostNasada,
        "zetveni-ostaci": ZetveniOstaci,
        "expozicija-nagib": ExpozicijaNagib,
        "navod-njavanje": Navodnjavanje,
        "uredjenost-zemljista": UredjenostZemljista,
        "agro-tehnika": AgroTehnika,
        zastita: Zastita,
    },
    data() {
        return {
            tab: null,
            items: [
                { tab: "Tekstura Tla", content: "tip-tla" },
                { tab: "Dubina Tla", content: "dubina-tla" },
                {
                    tab: "Biogenost Tla",
                    content: "biogenost-tla",
                    icon: "mdi-barn",
                },
                { tab: "Tip nasada", content: "tip-nasada" },
                {
                    tab: "Starost nasada",
                    content: "starost-nasada",
                },
                {
                    tab: "Žetveni ostaci",
                    content: "zetveni-ostaci",
                },
                {
                    tab: "Ekspozicija i nagib",
                    content: "expozicija-nagib",
                },
                {
                    tab: "Navodnjavanje",
                    content: "navod-njavanje",
                },
                {
                    tab: "Uređenost zemljišta",
                    content: "uredjenost-zemljista",
                },
                {
                    tab: "Agrotehnika",
                    content: "agro-tehnika",
                },
                {
                    tab: "Zaštita",
                    content: "zastita",
                },
            ],
        };
    },
};
</script>

<style>
/* Helper classes */
.tab {
    background-color: #fffbe6 !important;
    justify-content: left !important;
}
.tabActive {
    background-color: #90918f !important;
    color: #fffbe6 !important;
}
/* .tabItemActive {
   
} */
</style>
