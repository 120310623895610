import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import PreporukaTest from "../pages/testStranice/PreporukaTest.vue";
import TestJedan from "../pages/testStranice/TestJedan.vue";
// import TestDva from "../pages/testStranice/TestDva.vue";
import Footer from "../components/Footer.vue";
import Dashboard from "../pages/Dashboard.vue";
import Uzorci from "../pages/uzorci/Uzorci.vue";
import Analiza from "../pages/analiza/Analiza.vue";
import AnalizaDodaj from "../pages/analiza/AnalizaDodaj.vue";
import AnalizaSingle from "../pages/analiza/AnalizaSingle.vue";
import PregledPreporuka from "../pages/preporuka/PregledPreporuka.vue";
import PreporukaSingle from "../pages/preporuka/PreporukaSingle.vue";
import UnosPreporuka from "../pages/preporuka/UnosPreporuka.vue";
import Parcele from "../pages/parcela/Parcele.vue";
import Gospodarstvo from "../pages/gospodarstvo/Gospodarstvo.vue";
import GospodarstvoSingle from "../pages/gospodarstvo/GospodarstvoSingle.vue";
import UnosGospodarstva from "../pages/gospodarstvo/UnosGospodarstva.vue";
import Kalkulacije from "../pages/kalkulacije/Kalkulacije.vue";
import NpkKalkulacijaSingle from "../pages/kalkulacije/NpkKalkulacijaSingle.vue";
import Statistika from "../pages/statistika/Statistika.vue";
import Glavniprofil from "../pages/admin/GlavniProfil.vue";
import Korisnici from "../pages/admin/Korisnici/Korisnici.vue";
import KorisnikSingle from "../pages/admin/Korisnici/KorisnikSingle.vue";
import NoviKorisnik from "../pages/admin/Korisnici/NoviKorisnik.vue";
import MojProfil from "../pages/korisnik/MojProfil";
import Nedozvoljeno from "../pages/nedozvoljeno/Nedozvoljeno";
import Nepostoji from "../pages/nepostoji/Nepostoji";
import PregledMjesta from "../pages/opci/Mjesta";
import UnosMjesta from "../pages/opci/UnosMjesta";
import PregledBanka from "../pages/opci/Banka";
import UnosBanka from "../pages/opci/UnosBanka";
import Kultura from "../pages/kultura/Kulture";
import TlaNasadiRodnost from "../pages/tla/TlaNasadiRodnost";
import ParcelaSingle from "../pages/parcela/ParcelaSingle";
import ParcelaDodaj from "../pages/parcela/ParcelaDodaj";
import UzorakDodaj from "../pages/uzorci/noviUzorak";
import UzorakSingle from "../pages/uzorci/UzorakSingle";
import GnojidbaGnojiva from "../pages/gnojiva/GnojidbaGnojiva";
import Racuni from "../pages/racuni/Racuni.vue";
import RacunSingle from "../pages/racuni/RacunSingle.vue";
import RacunDodaj from "../pages/racuni/RacunDodaj.vue";

import Izvjestaji from "../pages/izvjestaji/Izvjestaj.vue";
import Login from "../Login.vue";
import store from "../store";
import AnalizaVanjskiPregled from "../pages/analiza/AnalizaVanjskiPregled.vue";

import DataTable from "../pages/uzorci/DataTable.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/logout",
        name: "logout",
        component: Footer,
    },
    {
        path: "/",
        name: "home",
        component: Dashboard,
        meta: {
            requiresAuth: true,
            naslov: "Statistika",
            opis: "Pregled statistike uzoraka, analize",
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
            naslov: "Kontrolna ploča",
            opis: "Prikaz informacija o sustavu",
            breadcrumb: "Kontrolna ploča",
        },
    },
    // ------------STRANICE PORUKE GRESKE-------------
    {
        path: "/nedozvoljeno",
        name: "nedozvoljeno",
        component: Nedozvoljeno,
        meta: {
            requiresAuth: true,
            naslov: "Nedozvoljeno",
            opis: "Nemate prava pristupa ovoj stranici",
        },
    },
    {
        path: "*",
        name: "nepostoji",
        component: Nepostoji,
        meta: {
            requiresAuth: true,
            naslov: "Nepostojeća stranica",
            opis: "Stranica koju ste posjetili ne postoji u aplikaciji Agrosys!",
        },
    },
    // ------------STATISTIKA-------------
    {
        path: "/statistika",
        name: "statistika",
        component: Statistika,
        meta: {
            naslov: "Statistika",
            opis: "Pregled statistike uzoraka, analize",
        },
    },

    // ------------DATATABLE-------------
    {
        path: "/datatable",
        name: "datatable",
        component: DataTable,
        meta: {
            requiresAuth: true,
            naslov: "datatable",
            opis: "datatable",
            breadcrumb: "datatable",
        },
    },

    // ------------UZORCI-------------
    {
        path: "/uzorci",
        name: "uzorci",
        component: Uzorci,
        meta: {
            requiresAuth: true,
            naslov: "Uzorci",
            opis: "Pregled i uređivanje uzoraka",
            breadcrumb: "Uzorci",
        },
    },
    {
        path: "/uzorci/:id",
        name: "uzorak",
        component: UzorakSingle,
        meta: {
            requiresAuth: true,
            naslov: "Uzorak",
            opis: "Pregled i uređivanje uzorka",
        },
    },
    {
        path: "/uzorak/novo",
        name: "novi-uzorak",
        component: UzorakDodaj,
        meta: {
            requiresAuth: true,
            naslov: "Novi uzorak",
            opis: "Unos novog uzoraka",
            breadcrumb: "Novi uzorak",
        },
    },

    // ------------ANALIZA-------------
    {
        path: "/analize",
        name: "analize",
        component: Analiza,
        meta: {
            requiresAuth: true,
            naslov: "Analize",
            opis: "Pregled i uređivanje analiza",
        },
    },
    {
        path: "/analiza/:id",
        name: "analiza-single",
        component: AnalizaSingle,
        meta: {
            requiresAuth: true,
            naslov: "Analiza",
            opis: "Pregled i uređivanje analize",
        },
    },
    {
        path: "/analize/novo",
        name: "nova-analiza",
        component: AnalizaDodaj,
        meta: {
            requiresAuth: true,
            naslov: "Dodaj analizu",
            opis: "Unos nove analize",
        },
    },

    // ------------PARCELA-------------
    {
        path: "/parcele",
        name: "parcele",
        component: Parcele,
        meta: {
            requiresAuth: true,
            naslov: "Parcele",
            opis: "Pregled i uređivanje parcela",
        },
    },
    {
        path: "/parcele/:id",
        name: "parcela",
        component: ParcelaSingle,
        meta: {
            requiresAuth: true,
            naslov: "Parcele",
            opis: "Pregled i uređivanje parcela",
        },
    },
    {
        path: "/parcela/novo",
        name: "nova-parcela",
        component: ParcelaDodaj,
        meta: {
            requiresAuth: true,
            naslov: "Parcele",
            opis: "Dodavanje nove parcele",
        },
    },
    // ------------GOSPODARSTVO-------------
    {
        path: "/gospodarstva",
        name: "gospodarstva",
        component: Gospodarstvo,
        meta: {
            requiresAuth: true,
            naslov: "Gospodarstva i obrtnici",
            opis: "Pregled i uređivanje gospodarstava",
        },
    },
    {
        path: "/gospodarstva/:id",
        name: "gospodarstvo",
        component: GospodarstvoSingle,
        meta: {
            requiresAuth: true,
            naslov: "Gospodarstva i obrtnici",
            opis: "Pregled i uređivanje gospodarstava",
        },
    },
    {
        path: "/gospodarstvo/novo",
        name: "novo-gospodarstvo",
        component: UnosGospodarstva,
        meta: {
            requiresAuth: true,
            naslov: "Gospodarstva i obrtnici",
            opis: "Unos novog gospodarstva i obrtnika",
        },
    },
    // ------------KALKULACIJE-------------
    {
        path: "/kalkulacije",
        name: "kalkulacije",
        component: Kalkulacije,
        meta: {
            requiresAuth: true,
            naslov: "Kalkulacije",
            opis: "Pregled kalkulacija i stvaranje preporuka",
        },
    },
    {
        path: "/kalkulacije/npk/:id",
        name: "npk-kalkulacija-single",
        component: NpkKalkulacijaSingle,
    },

    // ------------PREPORUKE-------------
    {
        path: "/preporuke",
        component: PregledPreporuka,
        meta: {
            requiresAuth: true,
            naslov: "Preporuke",
            opis: "Pregled i uređivanje preporuka",
        },
    },
    {
        path: "/preporuka/novo/:kalkulacija_id",
        name: "nova-preporuka",
        component: UnosPreporuka,
        meta: {
            requiresAuth: true,
            naslov: "Preporuka",
            opis: "Stvaranje preporuke",
        },
    },
    {
        path: "/preporuka/:id",
        name: "preporuka",
        component: PreporukaSingle,
        meta: {
            requiresAuth: true,
            naslov: "Preporuke",
            opis: "Pregled i uređivanje preporuke",
        },
    },
    // ------------- RAČUNI ---------------
    {
        path: "/racuni",
        component: Racuni,
        meta: {
            requiresAuth: true,
            naslov: "Računi",
            opis: "Pregled i uređivanje računa",
        },
    },
    {
        path: "/racuni/:id",
        name: "racun-single",
        component: RacunSingle,
        meta: {
            requiresAuth: true,
            naslov: "Račun",
            opis: "Pregled i uređivanje računa",
        },
    },
    {
        path: "/racun/novo/:analiza_id",
        name: "novi-racun",
        component: RacunDodaj,
        meta: {
            requiresAuth: true,
            naslov: "Račun",
            opis: "Dodavanje računa",
        },
    },
    // ------------OPCI PODACI-------------
    {
        path: "/opci/mjesta",
        name: "mjesta",
        component: PregledMjesta,
        meta: {
            roles: [1, 2],
            requiresAuth: true,
            naslov: "Mjesta i opčine",
            opis: "Pregled i uređivanje mjesta i opčina",
        },
    },
    // ------------OBRASCI-------------
    {
        path: "/obrasci",
        name: "obrasci",
        component: () =>
            import(
                /* webpackChunkName: "obrasci" */ "../pages/obrasci/Obrasci.vue"
            ),
        meta: {
            requiresAuth: true,
            naslov: "Obrasci",
            opis: "Pregled uređivanje ispis obrazaca",
        },
    },
    // ------------IZVJESTAJI-------------
    {
        path: "/izvjestaji",
        name: "izvjestaji",
        component: Izvjestaji,
        meta: {
            requiresAuth: true,
            naslov: "Izvještaji",
            opis: "Pregled i analitika ",
        },
    },
    // {
    //     path: "/opci/mjesta/novo",
    //     name: "mjesta-novo",
    //     component: UnosMjesta,
    //     meta: {
    //         requiresAuth: true,
    //         naslov: "Mjesta i opčine",
    //         opis: "Unos podataka mjesta",
    //     },
    // },
    {
        path: "/opci/banka",
        name: "banka",
        component: PregledBanka,
        meta: {
            roles: [1, 2],
            requiresAuth: true,
            naslov: "Banke",
            opis: "Pregled i uređivanje banaka",
        },
    },
    // {
    //     path: "/opci/banka/novo",
    //     name: "banka-novo",
    //     component: UnosBanka,
    //     meta: {
    //         requiresAuth: true,
    //         naslov: "Banke",
    //         opis: "Unos podataka banke",
    //     },
    // },

    // ------------KULTURA-------------
    {
        path: "/kultura",
        name: "Kultura",
        component: Kultura,
        meta: {
            roles: [1, 2],
            requiresAuth: true,
            naslov: "Kulture",
            opis: "Pregled i uređivanje kultura",
        },
    },
    // ------------TLA NASADi RODNOST-------------
    {
        path: "/tla-nasadi-rodnost",
        name: "tla-nasadi-rodnost",
        component: TlaNasadiRodnost,
        meta: {
            roles: [1, 2],
            requiresAuth: true,
            naslov: "Tla, nasadi i rodnost",
            opis: "Pregled i uređivanje",
        },
    },
    // ------------GNOJIDBA i GNOJIVA-------------
    {
        path: "/gnojidba-gnojiva",
        name: "gnojidba-gnojiva",
        component: GnojidbaGnojiva,
        meta: {
            roles: [1, 2],
            requiresAuth: true,
            naslov: "Gnojidba i gnojiva",
            opis: "Pregled i uređivanje",
        },
    },

    // ------------KORISNICI I OPERATORI-------------
    {
        path: "/korisnici",
        name: "korisnici",
        component: Korisnici,
        meta: {
            roles: [1],
            requiresAuth: true,
            naslov: "Korisnici i operatori",
            opis: " Administracija korisnika i operatora",
        },
    },
    {
        path: "/korisnici/:id",
        name: "korisnik",
        component: KorisnikSingle,
        meta: {
            requiresAuth: true,
            naslov: "Korisnici i operatori",
            opis: "Pregled i uređivanje korisnika",
        },
    },
    {
        path: "/korisnik/novi",
        name: "novi-korisnik",
        component: NoviKorisnik,
        meta: {
            roles: [1],
            requiresAuth: true,
            naslov: "Novi korisnik",
            opis: " Dodavanje novog korisnika",
        },
    },
    // ------------ADMIN-------------
    {
        path: "/glavni-profil",
        name: "glavni-profil",
        component: Glavniprofil,
        meta: {
            requiresAuth: true,
            roles: [1],
            naslov: "Administrator",
            opis: " Uređivanje i ažuriranje glavnog profila",
        },
    },
    // ------------KORISNIK-------------
    {
        path: "/moj-profil",
        name: "moj-profil",
        component: MojProfil,
        meta: {
            roles: [1, 2, 3],
            requiresAuth: true,
            naslov: "Moj Profil",
            opis: "Uređivanje i ažuriranje mog profila",
        },
    },

    //-------------- ANALIZA VANJSKI PREGELD----------------

    {
        path: "/analiza-pregled",
        name: "analiza-pregled",
        component: AnalizaVanjskiPregled,
        meta: {
            requiresAuth: false,
            naslov: "Pregled Analiza",
            opis: "Pregledajte svoj dokument",
        },
    },
    // ------------------------ TESTOVI -------------------------
    {
        path: "/preporuka-test",
        name: "preporuka-test",
        component: PreporukaTest,
        meta: {
            roles: [1],
            requiresAuth: true,
            naslov: "Preporuka test",
            opis: "Preporuka test",
        },
    },
    {
        path: "/test-1",
        name: "Test 1",
        component: TestJedan,
        meta: {
            roles: [1],
            requiresAuth: true,
            naslov: "Test 1",
            opis: "Test 1",
        },
    },

    // {
    //     path: "/test-1",
    //     name: "Test 1",
    //     component: TestJedan,
    //     meta: {
    //         roles: [1],
    //         requiresAuth: true,
    //         naslov: "Test 1",
    //         opis: "Test 1",
    //     },
    // },
    // {
    //     path: "/test-2",
    //     name: "Test 2",
    //     component: TestDva,
    //     meta: {
    //         roles: [1],
    //         requiresAuth: true,
    //         naslov: "Test 2",
    //         opis: "Test 2",
    //     },
    // },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             if (to.meta.roles) {
//                 if (to.meta.roles.includes(store.getters.getRole)) {
//                     next();
//                     return;
//                 } else {
//                     next("/nedozvoljeno");
//                     return;
//                 }
//             }
//             next();
//             return;
//         }
//         next("/login");
//     } else {
//         next();
//     }
// });

// router.beforeEach((to, from) => {
//     if (to == "gost-analiza") {
//         next("/gost-analiza");
//     }
// });

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if (to.meta.roles) {
                if (to.meta.roles.includes(parseInt(store.getters.getRole))) {
                    next();
                    return;
                } else {
                    next("/nedozvoljeno");
                    return;
                }
            }
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});
export default router;
