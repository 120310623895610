<template>
    <div id="map" ref="mapElement">
        <l-map
            :center="center"
            :zoom="zoom"
            class="map"
            ref="map"
            @update:zoom="zoomUpdated"
            @click="repositionMarker"
            v-on="$listeners"
        >
            <l-marker :key="marker.id" :lat-lng="center"> </l-marker>

            <l-tile-layer :url="url"> </l-tile-layer>
            <!-- <l-wms-tile-layer
                :key="wmsLayer.name"
                :base-url="wmsLayer.url"
                :layers="wmsLayer.layers"
                :visible="wmsLayer.visible"
                :name="wmsLayer.name"
                :attribution="wmsLayer.attribution"
                :transparent="true"
                format="image/png"
                layer-type="base"
            >
            </l-wms-tile-layer> -->
            <l-wms-tile-layer
                :key="wmsLayer.name"
                :base-url="wmsLayer.url"
                :layers="wmsLayer.layers"
                :visible="wmsLayer.visible"
                :name="wmsLayer.name"
                :attribution="wmsLayer.attribution"
                format="image/png"
                layer-type="base"
                :transparent="true"
            >
            </l-wms-tile-layer>

            <v-geosearch :options="geosearchOptions"></v-geosearch>
        </l-map>
    </div>
</template>

<script>
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
const provider = new OpenStreetMapProvider({
    params: {
        "accept-language": "hr",
        countrycodes: "hr",
        addressdetails: 1,
    },
});

export default {
    components: { VGeosearch },
    props: {
        centerProp: [],
        parceleSloj: false,
    },
    watch: {
        centerProp(value) {
            this.center = value;
        },
    },
    data() {
        return {
            // wmsLayer: {
            //     // url: "https://oss.uredjenazemlja.hr/OssWebServices/inspireService/wms?token=21c56fefab39e6554a7db68e3280ba3a2d9c3430f7d151702f8d4df91d7c0b82",
            //     url: "https://api.uredjenazemlja.hr/services/inspire/cp/wfs?VERSION=1.0.0",

            //     name: "parcele",
            //     visible: this.parceleSloj,
            //     format: "image/png",
            //     layers: "CP.CadastralParcel",
            //     transparent: true,
            //     attribution: "",
            // },
            wmsLayer: {
                url: "https://api.uredjenazemlja.hr/services/inspire/cp_wms/wms",

                name: "parcele",
                visible: this.parceleSloj,
                format: "image/png",
                layers: "cp:CP.CadastralParcel",
                transparent: true,
                attribution: "",
            },
            geosearchOptions: {
                // Important part Here
                provider: provider,
                autoComplete: true, // optional: true|false  - default true
                autoCompleteDelay: 250, // optional: number      - default 250
                autoClose: true,
                showMarker: true, // optional: true|false  - default true
                showPopup: true, // optional: true|false  - default false
                marker: {
                    // optional: L.Marker    - default L.Icon.Default
                    icon: new L.Icon.Default(),
                    draggable: false,
                },
                keepResult: false, // optional: true|false  - default false
                popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label,
                // resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
                resultFormat: function (t) {
                    return "" + t.label;
                },
                style: "bar",
            },
            marker: [
                // { id: 1, coordinates: [45.22219450261548, 19.375869832456267] },
                L.latLng(47.412, -1.218),
            ],
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            center: [0, 0],
            zoom: 100,
        };
    },
    methods: {
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        repositionMarker(event) {
            this.center = event.latlng;
        },
    },
    async created() {},
    mounted() {},
};
</script>

<style>
.map {
    width: 100%;
    height: 400px !important;
    overflow: hidden;
}
.leaflet-grab {
    cursor: pointer !important;
}
.leaflet-dragging .leaflet-grab {
    cursor: grab !important;
}
</style>
