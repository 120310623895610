<template>
    <div>
        <v-overlay :value="overlay" z-index="9999">
            <h2>Preuzimanje u tijeku!</h2>
            <!-- <v-progress-circular
                :size="20"
                color="amber"
                indeterminate
            ></v-progress-circular> -->
        </v-overlay>

        <v-data-table
            ref="datatableUzorci"
            :headers="headers"
            :items="dataItems"
            key="id"
            sort-by="uzorak_datum"
            :search="search"
            no-results-text="Nema pronađenih"
            no-data-text="Nema podataka"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            loading-text="Učitavanje... Molimo pričekajte"
            @page-count="pageCount = $event"
            group-by="gospodarstvo_naziv"
            :sort-desc="true"
        >
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="12" class="teal lighten-4">
                    <v-row>
                        <v-col cols="2">
                            <v-icon @click="toggle" class="mr-0">
                                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                            </v-icon>
                            <span class="">{{
                                items[0].gospodarstvo_naziv
                            }}</span>
                        </v-col>
                        <v-col>
                            <h4>OIB: {{ items[0].gospodarstvo_oib }}</h4>

                            <h4>
                                MIBPG:
                                {{ items[0].gospodarstvo_maticni_broj }}
                            </h4>
                        </v-col>
                    </v-row>
                </th>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <h2 class="mr-5">Uzorci</h2>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisFiltriranih"
                                    class="mr-2 ml-2"
                                    color="red lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispis tablice uzoraka</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFiltriranih"
                                    class="mr-2"
                                    color="blue lighten-1"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi tablicu uzoraka</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download :filename="FilenameXlsx">
                                        <v-icon
                                            @click="izvozExcell"
                                            class="mr-2"
                                            color="green lighten-1"
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </span>
                        </template>
                        <span>Izvoz tablice uzoraka</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="userRole === 1 || userRole === 2">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    :to="{ name: 'novi-uzorak' }"
                                >
                                    Dodaj +
                                </v-btn>
                            </span>
                        </template>
                        <span>Dodaj novi uzorak</span>
                    </v-tooltip>
                </v-toolbar>
            </template>
            <!-- Filter slot -->
            <template v-slot:header="{ props: { headers } }">
                <th colspan="12">
                    <v-expansion-panels accordion tile focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="blue lighten-4">
                                <v-row no-gutters align="center">
                                    <v-col cols="2" class="d-flex">
                                        <div>
                                            <v-icon>mdi-filter</v-icon>
                                        </div>
                                        <div class="mt-1">
                                            Filtrirajte podatke
                                        </div>
                                    </v-col>
                                    <v-col cols="10" class="d-flex">
                                        <div v-if="godinaFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                                color="primary"
                                            >
                                                Godina uzorka
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ godinaFilter }}
                                            </h5>
                                        </div>

                                        <div v-if="mjestoFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Mjesto uzorka
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ mjestoFilter }}
                                            </h5>
                                        </div>
                                        <div v-if="operatorFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Operator
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ operatorFilter }}
                                            </h5>
                                        </div>
                                        <div v-if="datumFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Datum uzorka
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ formatedDate }}
                                            </h5>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="mt-1">
                                    <v-col>
                                        <v-select
                                            :items="godineLista()"
                                            label="Izbor godine"
                                            v-model="godinaFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-select
                                    ></v-col>
                                    <v-col>
                                        <v-autocomplete
                                            :items="mjesta"
                                            label="Izbor mjesta"
                                            item-text="naziv"
                                            item-value="naziv"
                                            v-model="mjestoFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete
                                            :items="operatori"
                                            label="Izbor operatora"
                                            item-text="name"
                                            item-value="name"
                                            v-model="operatorFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="izbornikDatum"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="formatedDate"
                                                    label="Datum"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    required
                                                    clearable
                                                    dense
                                                    @click:clear="
                                                        ocistiDatumFilter
                                                    "
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="datumFilter"
                                                @input="izbornikDatum = false"
                                                locale="hr-HR"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </th>
            </template>
            <template v-slot:[`item.naziv`]="{ item }">
                <div
                    class="text-center"
                    v-if="userRole === 1 || userRole === 2"
                >
                    <v-icon class="mr-3">mdi-eyedropper</v-icon>
                    <router-link
                        :to="{
                            name: 'uzorak',
                            params: { id: item.id },
                        }"
                        class="routerLink"
                    >
                        {{ item.naziv }}
                    </router-link>
                </div>
                <div v-else>
                    {{ item.naziv }}
                </div>
            </template>
            <template v-slot:[`item.dubina`]="{ item }">
                <v-chip
                    v-if="item.dubina == '0-30'"
                    color="green darken-1"
                    class="ml-0 mr-2 black--text"
                    small
                >
                    <span class="white--text"> {{ item.dubina }}</span>
                </v-chip>

                <v-chip
                    v-else
                    color="orange darken-3"
                    class="ml-0 mr-2 black--text"
                    small
                >
                    <span class="white--text"> {{ item.dubina }}</span>
                </v-chip>
            </template>
            <template v-slot:[`item.datum`]="{ item }">
                <span> {{ formatDate(item.datum) }}</span>
            </template>
            <template v-slot:[`item.status_analize`]="{ item }">
                <v-avatar
                    v-if="item.status_analize"
                    color="green lighten-1"
                    size="14"
                >
                </v-avatar>
                <v-avatar v-else color="red lighten-1" size="14"> </v-avatar>
            </template>
            <template v-slot:[`item.unos_analize`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-if="userRole === 1 || userRole === 2"
                                color="primary"
                                @click="unesiAnalizuDialog(item)"
                            >
                                mdi-expand-all
                            </v-icon>
                            <v-icon v-else color="primary">
                                mdi-expand-all
                            </v-icon>
                        </span>
                    </template>
                    <span>Unesi analizu za odabrani uzorak</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="ispisObrasca(item)"
                                class="mr-1"
                                color="green lighten-2"
                            >
                                mdi-printer
                            </v-icon>
                        </span>
                    </template>
                    <span>Ispiši Uzorak</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="downloadObrasca(item)"
                                class="mr-1"
                                color="blue lighten-2"
                            >
                                mdi-download
                            </v-icon>
                        </span>
                    </template>
                    <span>Preuzmi obrazac</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-if="userRole === 1 || userRole === 2"
                                @click="obrisiDialog(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                            <v-icon
                                v-else
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši</span>
                </v-tooltip>
            </template>
            <div class="text-center">
                <v-pagination v-model="page" :length="4" circle></v-pagination>
            </div>
        </v-data-table>

        <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            :total-visible="10"
        ></v-pagination>

        <!-- Dialog obrisi-->
        <v-dialog v-model="dialogObrisi" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Potvrdite brisanje!</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogObrisi = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="obrisi(obrisiId)">
                        Obriši
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog dodaj analizu-->
        <v-dialog v-model="dialogDodajAnalizu" persistent max-width="640px">
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="submit"
                >
                    <v-card class="mt-4">
                        <v-card-subtitle>
                            <h2 class="font-weight-light">
                                Analiza broj:{{ noviBrojAnalize }}
                            </h2>

                            <v-divider></v-divider>

                            <v-spacer></v-spacer>
                        </v-card-subtitle>

                        <v-card-text class="mt-4">
                            <v-row justify="space-between">
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="analizaForm.analiza_naziv"
                                        :rules="nazivRules"
                                        label="Naziv analize"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-menu
                                        v-model="izbornikDatum"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="formatedDate"
                                                label="Datum"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                required
                                                dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="datum"
                                            @input="izbornikDatum = false"
                                            locale="hr-HR"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title> Detalji analize </v-card-title>
                        <v-card-text class="mt-3 mb-0">
                            <v-row no-gutters>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_phkci
                                        "
                                        label="pH-KCI"
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_phh2o
                                        "
                                        label=""
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    >
                                        <template #label>
                                            <label>pH-H<sub>2</sub>O</label>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_alp2o5
                                        "
                                        label=""
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    >
                                        <template #label>
                                            <label
                                                >AL-P<sub>2</sub>O<sub
                                                    >5</sub
                                                ></label
                                            >
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_alpk2o
                                        "
                                        label=""
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    >
                                        <template #label>
                                            <label>AL-K<sub>2</sub>O</label>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_humus
                                        "
                                        label="Humus %"
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_caco3
                                        "
                                        label=""
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    >
                                        <template #label>
                                            <label>CaCO<sub>3</sub>O %</label>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        v-model.number="
                                            analizaForm.analiza_hkcmol
                                        "
                                        label=""
                                        required
                                        outlined
                                        clearable
                                        dense
                                        :rules="decimalRules"
                                    >
                                        <template #label>
                                            <label>
                                                Hk cmol<sup> (+)</sup>kg<sup
                                                    >-1</sup
                                                >
                                            </label>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="analizaForm.analiza_napomena"
                                        outlined
                                        label="Napomena"
                                        value="Ovdje ide napomena"
                                        rows="4"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <div class="text-left">
                                        <v-btn
                                            color="primary"
                                            class=""
                                            @click="odustani()"
                                        >
                                            Odustani
                                        </v-btn>
                                    </div></v-col
                                >
                                <v-col>
                                    <div class="text-right">
                                        <v-btn
                                            color="primary"
                                            class=""
                                            @click="spremiAnalizu"
                                        >
                                            Spremi
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- ISPIS  -->
        <div id="printUzorak" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>
                <uzorak-obrazac
                    :stavka="uzorakIspis"
                    v-if="uzorakIspis"
                ></uzorak-obrazac>
                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- ISPIS FILTRIRANIH ANALIZA -->
        <div id="printFiltrirano" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <uzorak-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </uzorak-filtrirano>

                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- -DOWNLOAD  -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfDownload"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <uzorak-obrazac
                        :stavka="uzorakDownload"
                        v-if="uzorakDownload"
                    ></uzorak-obrazac>
                    <div class="verticalSpacer"></div>

                    <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
                </v-container>
            </section>
        </vue-html2pdf>
        <!-- -DOWNLOAD  FILTRIRANIH :paginate-elements-by-height="1400"   -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :filename="imeDatoteke"
            :preview-modal="true"
            :pdf-quality="2"
            :manual-pagination="true"
            :paginate-elements-by-height="1400"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfFiltrirano"
            :html-to-pdf-options="downloadFilterOptions"
            @hasDownloaded="hasDownloaded($event)"
            @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <uzorak-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </uzorak-filtrirano>

                <!-- <div class="verticalSpacer"></div> -->

                <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import UzorakIspis from "../obrasci/UzorakIspis.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
    components: {
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "uzorak-obrazac": UzorakIspis,
        VueHtml2pdf,
        "uzorak-filtrirano": () => import("../obrasci/UzorakFilterIspis.vue"),
    },
    data: () => ({
        userRole: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        loading: true,
        godinaFilter: null, //Vrijednost filtera godine
        datumFilter: null, //Vrijednost filtera godine
        mjestoFilter: null, //Vrijednost filtera mjesta
        operatorFilter: null, //Vrijednost filtera operator
        unosRules: [(v) => !!v || "Unos je potreban"],
        dataItems: [],
        uzorakIspis: null,
        uzorakDownload: null,
        dataItemsFlitrirane: [], // Stavke nakon filtera ili pretrage
        dataItemsKoristeniFilteri: {
            datumFilter: null,
            godinaFilter: null,
            operatorFilter: null,
            mjestoFilter: null,
        },
        downloadFilterOptions: {
            filename: "Tablica_uzoraka",
            pagebreak: {
                mode: ["avoid-all", "css", "legacy"],
            },
            jsPDF: {
                format: "a4",
                orientation: "landscape",
            },
            // html2canvas: {
            //     useCORS: true,
            //     allowTaint: true,
            //     scrollX: 0,
            //     scrollY: 0,
            // },
        },
        analize: [],
        mjesta: [],
        operatori: [],
        obrisiId: "",
        form: {},
        overlay: false,
        analizaForm: {},
        dialogNovo: false,
        dialogUredi: false,
        dialogObrisi: false,
        dialogDodajAnalizu: false,
        noviBrojAnalize: 0,
        valid: false,
        izbornikDatum: false,
        datum: null,
        izbornikDatum: false,
        formatedDate: "",
        imeDatoteke: null,
        FilenameXlsx: null,
        sheetName: "uzorci",
        sheets: [
            {
                name: "Uzorci",
                data: [{}],
            },
        ],
        collection: [{ a: 1, b: 2 }],
        nazivRules: [(v) => !!v || "Naziv je potreban"],
        decimalRules: [
            (v) => !!v || "Unesite vrijednost",
            (v) =>
                /^\d{1,2}(\.\d{1,3})?$/.test(v) ||
                "Broj mora biti u formatu ##.###",
        ],
    }),
    computed: {
        headers() {
            return [
                {
                    text: "Naziv ",
                    align: "start",
                    sortable: false,
                    value: "naziv",
                    width: "20%",
                    filterable: false,
                },
                {
                    text: "Gospodarstvo",
                    value: "gospodarstvo_naziv",
                    sortable: false,
                },
                {
                    text: "OIB",
                    value: "gospodarstvo_oib",
                    sortable: false,
                    align: " d-none", //Skriveno , tu za pretragu
                },
                {
                    text: "Korisnik MB",
                    value: "gospodarstvo_maticni_broj",
                    sortable: false,
                    align: " d-none", //Skriveno , tu za pretragu
                    filterable: false,
                },
                {
                    text: "Dubina",
                    value: "dubina",
                    sortable: true,
                    align: "center",
                    filterable: false,
                },
                {
                    text: "Mjesto/Opčina",
                    value: "uzorak_mjesto_naziv",
                    filter: this.mjestaFilter,
                    sortable: true,
                },
                { text: "Broj uzorka", value: "broj", sortable: false },
                {
                    text: "Laboratorijski broj",
                    value: "lab_broj",
                    sortable: true,
                },
                {
                    text: "Datum uzorka",
                    value: "datum",
                    sortable: false,
                    width: "10%",
                    filter: this.datumiFilter,
                },
                {
                    text: "Godina uzorka",
                    value: "godina",
                    sortable: false,
                    width: "8%",
                    filter: this.godineFilter,
                },

                {
                    text: "Parcela",
                    value: "parcela_naziv",
                    sortable: false,
                    filterable: false,
                },
                {
                    text: "Operater",
                    value: "operator_ime",
                    filter: this.operatoriFilter,
                    sortable: false,
                },
                {
                    text: "Status analize",
                    value: "status_analize",
                    sortable: false,
                    align: "center",
                    width: "5%",
                    filterable: false,
                },

                {
                    text: "Unos analize",
                    value: "unos_analize",
                    sortable: false,
                    align: "center",
                    width: "8%",
                    filterable: false,
                },

                {
                    text: "Operacije",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    filterable: false,
                },
            ];
        },
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.analizaForm.analiza_datum = this.formatISODate(val);
        },
        datumFilter(novo, staro) {
            this.formatedDate = this.formatDate(novo);
            this.dataItemsKoristeniFilteri.datumFilter = this.formatedDate;
        },
        //Prati i sprema odabrani filter u varijablu za ispis tablice analiza s filterima
        godinaFilter(novo) {
            this.dataItemsKoristeniFilteri.godinaFilter = novo;
        },
        //Prati i sprema odabrani filter u varijablu za ispis tablice analiza s filterima
        operatorFilter(novo) {
            this.dataItemsKoristeniFilteri.operatorFilter = novo;
        },
        mjestoFilter(novo) {
            this.dataItemsKoristeniFilteri.mjestoFilter = novo;
        },
    },
    created() {
        this.ucitaj();
        this.ucitajAnalize();
        this.ucitajMjesta();
        this.ucitajOperatore();
        this.userRole = this.$store.getters.getRole; //Postavljanje uloge
    },

    methods: {
        //uzima datum i dodaje prprety godina u objekt
        dodajGodinaColumn(items) {
            items.forEach((obj) => {
                obj.godina = new Date(obj.datum).getFullYear();
            });
            this.dataItems = items;
        },
        dodajDialog() {
            this.form.id = "";
            this.form.naziv = "";
            this.form.vrijednost = "";
            this.dialogNovo = true;
        },
        urediDialog(item) {
            this.dialogUredi = true;
            this.form.id = item.id;
            this.form.naziv = item.naziv;
            this.form.vrijednost = item.vrijednost;
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        //Unosenje anlaize na osnovu odabranog uzorka
        unesiAnalizuDialog(item) {
            this.analizaForm.uzorak_id = item.id;
            this.noviBrojAnalize = this.generirajBrojAnalize(item);
            this.analizaForm.analiza_naziv = "Analiza " + this.noviBrojAnalize;
            this.analizaForm.analiza_broj = this.noviBrojAnalize;
            this.dialogDodajAnalizu = true;
            this.formatedDate = this.todayDate();
            this.analizaForm.analiza_datum = new Date()
                .toJSON()
                .slice(0, 10)
                .replace(/-/g, "/");
        },
        generirajBrojAnalize(item) {
            let noviBroj = 0;
            const filterAnalize = this.analize.filter((analiza) => {
                return analiza.uzorak_id == item.id;
            });
            noviBroj = filterAnalize.length + 1;
            return item.broj + "-" + noviBroj;
        },
        spremiAnalizu() {
            if (this.validate()) {
                this.analizaForm.operator_id = JSON.parse(
                    sessionStorage.getItem("user")
                ).id;
                let analizaId;
                this.$Api
                    .postData("analiza", this.analizaForm)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                        analizaId = response.data.lastInsertId;
                        this.odustani();
                    })
                    .then((response) => {
                        //Generira link za vanjski pregled
                        this.$Api
                            .generirajAnalizaVanjskiLink(analizaId)
                            .then((response) => {
                                this.signatureAnaliza(analizaId, response.data);
                            })
                            .catch((err) => {
                                this.pokaziPoruku("poruke", "error", err);
                            });

                        this.$router.push("/analize");
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        async signatureAnaliza(id, sigLink) {
            //Izdvajanje signanture iz linka
            let word = "signature=";
            sigLink = sigLink.slice(sigLink.indexOf(word) + word.length);
            this.$Api
                .umetniSigntureAnaliza(id, {
                    analiza_signature_link: sigLink,
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        odustani() {
            this.dialogDodajAnalizu = false;
            this.analizaForm = {};
        },
        ucitaj() {
            this.loading = true;
            this.$Api
                .getData("uzorci")
                .then((response) => {
                    // this.dataItems = response.data;
                    this.dodajGodinaColumn(response.data);
                    this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajAnalize() {
            this.$Api
                .getData("analize")
                .then((response) => {
                    this.analize = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajOperatore() {
            this.$Api
                .getData("korisnici")
                .then((response) => {
                    this.operatori = response.data;
                    // this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },

        dodaj() {
            if (this.$refs.form.validate()) {
                this.$Api
                    .postData("uzorak", this.form)
                    .then((response) => {
                        this.dialogNovo = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
            this.resetforme();
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("uzorak", id, this.form)
                    .then((response) => {
                        this.dialogUredi = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        obrisi(id) {
            this.$Api
                .delete("uzorak", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        hasDownloaded(e) {
            this.overlay = false;
        },
        onProgress(e) {
            this.printPdfProgress = e;
        },
        async ispisObrasca(item) {
            // this.forceRerender();
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            var gospodarstvoSingle;
            await this.$Api
                .getByGospodarstvoID(item.gospodarstvo_id)
                .then((response) => {
                    gospodarstvoSingle = response.data;
                });

            this.uzorakIspis = item;
            //Umetanje gospodarstva u objekt za ispis
            this.uzorakIspis.gospodarstvo = gospodarstvoSingle;
            setTimeout(() => {
                this.$htmlToPaper("printUzorak", localOptions);
            }, "500");
        },
        ispisFiltriranih() {
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.dataItemsFlitrirane =
                this.$refs.datatableUzorci.$children[0].filteredItems;
            setTimeout(() => {
                this.$htmlToPaper("printFiltrirano", localOptions);
            }, "500");
        },
        async downloadObrasca(item) {
            this.imeDatoteke = "Uzorak-" + item.broj;
            this.overlay = true;
            await this.$Api
                .getByGospodarstvoID(item.gospodarstvo_id)
                .then((response) => {
                    this.uzorakDownload = item;
                    this.uzorakDownload.gospodarstvo = response.data;
                });

            setTimeout(() => {
                this.$refs.html2PdfDownload.generatePdf();
                this.overlay = false;
            }, "1000");
        },

        //Preuzimanje filtriranih analiza
        downloadFiltriranih() {
            this.overlay = true;
            this.imeDatoteke = "Tablica Uzoraka";
            this.dataItemsFlitrirane =
                this.$refs.datatableUzorci.$children[0].filteredItems;
            setTimeout(() => {
                this.generateReportFiltriranih();
            }, "500");
        },
        async generateReportFiltriranih() {
            this.$refs.html2PdfFiltrirano.generatePdf();
        },
        formirajExcell() {
            this.FilenameXlsx = "Tablica_uzoraka.xlsx";
            this.sheets[0].data = [{}]; //Nuliranje array

            this.$refs.datatableUzorci.$children[0].filteredItems.forEach(
                (element) => {
                    this.sheets[0].data.push({
                        Naziv: element.naziv,
                        "Broj uzorka": element.broj,
                        "Datum uzorka": this.formatDate(element.datum),
                        "Dubina uzorka (cm)": element.dubina,
                        Korisnik: element.gospodarstvo_naziv,
                        "Korisnik OIB": element.gospodarstvo_oib,
                        "Korisnik MB": element.gospodarstvo_maticni_broj,
                        Parcela: element.parcela_naziv,
                        "Katastarska općina": element.uzorak_mjesto_naziv,
                        "Katastarska čestica": element.parcela_kat_cestica,
                        "Analiza napravljena": this.statusAnalize(
                            element.status_analize
                        ),
                    });
                }
            );
        },

        //Nista ne radi samo pokazuje cursor, download ide iz komponente xlsx
        izvozExcell() {
            this.formirajExcell();
            return false;
        },
        statusAnalize(uzorak) {
            return (uzorak = 1 ? "DA" : "NE");
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + "." + mm + "." + yyyy;

            return today;
        },
        godineLista() {
            let tmpArr = [];
            let currYear = new Date().getFullYear();

            for (let i = 0; i <= 50; i++) {
                tmpArr.push({ text: currYear - i, value: currYear - i });
            }
            return tmpArr;
        },
        //FILTERI ZA UZORKE
        godineFilter(value) {
            if (!this.godinaFilter) {
                return true;
            }

            return value === this.godinaFilter;
        },
        datumiFilter(value) {
            if (!this.datumFilter) {
                return true;
            }

            return value === this.datumFilter;
        },
        ocistiDatumFilter() {
            this.datumFilter = "";
        },
        mjestaFilter(value) {
            if (!this.mjestoFilter) {
                return true;
            }
            return value === this.mjestoFilter;
        },
        operatoriFilter(value) {
            if (!this.operatorFilter) {
                return true;
            }

            return value === this.operatorFilter;
        },
        //Dohvat filtriranih stavki
        getFiltered() {
            // if (this.$refs.datatableUzorci) {
            //     this.dataItemsFlitrirane =
            //         this.$refs.datatableUzorci.$children[0].filteredItems;
            // }
        },

        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
