import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);
// import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: "",
        authenticated: sessionStorage.getItem("loggedin") || "",
        user: JSON.parse(sessionStorage.getItem("user")) || "",
        mjesta: [],
        banke: [],
        apiUrl: process.env.MIX_API_URL,
        logoUrl: process.env.MIX_LOGO_URL,
        analizaPregledUrl: process.env.APP_URL + "/analiza-pregled",
    },
    getters: {
        isLoggedIn: (state) => !!state.authenticated,
        authStatus: (state) => state.status,
        getRole: (state) => {
            return parseInt(state.user.role);
        },
        getMjesta: (state) => {
            return state.mjesta;
        },
        getApiUrl: (state) => {
            return state.apiUrl;
        },
        getLogoUrl: (state) => {
            return state.logoUrl;
        },
    },
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, payload) {
            sessionStorage.setItem("loggedin", true);
            sessionStorage.setItem("user", JSON.stringify(payload.user));
            $cookies.set("auth", payload.token, { httpOnly: true });
            state.status = "success";
            state.user = payload.user;
            state.authenticated = true;
        },
        auth_error(state) {
            sessionStorage.removeItem("user");
            $cookies.remove("auth");
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.user = "";
            state.authenticated = false;
            sessionStorage.removeItem("loggedin");
            sessionStorage.removeItem("gp");
            sessionStorage.removeItem("user");
            localStorage.removeItem("mjesta");
            localStorage.removeItem("banke");
            $cookies.remove("auth");
        },
        initMjesta_MU: (state, data) => {
            state.mjesta = JSON.parse(data);
        },
        initBanke_MU: (state, data) => {
            state.banke = JSON.parse(data);
        },
    },
    actions: {
        login({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({
                    url: "api/login",
                    data: user,
                    method: "POST",
                    withCredentials: true,
                })
                    .then((resp) => {
                        const payload = {
                            token: resp.data.token,
                            user: resp.data.user,
                        };

                        commit("auth_success", payload);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        reject(err);
                    });
            });
        },
        //------------------ Glavni profil------------------------
        initGlavni_AC({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.apiUrl + "/glavni")
                    .then((response) => {
                        sessionStorage.setItem(
                            "gp",
                            JSON.stringify(response.data[0])
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        sessionStorage.removeItem("gp");
                        reject(err);
                    });
            });
        },
        //------------------ MJESTA------------------------
        initMjesta_AC({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.apiUrl + "/mjesta")
                    .then((response) => {
                        const mjesta = [JSON.stringify(response.data)];
                        localStorage.setItem("mjesta", mjesta);
                        resolve(response);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("mjesta");
                        reject(err);
                    });
            });
        },
        //------------------ BANKE------------------------
        initBanka_AC({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.apiUrl + "/banke")
                    .then((response) => {
                        const banke = [JSON.stringify(response.data)];
                        localStorage.setItem("banke", banke);
                        resolve(response);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("banke");
                        reject(err);
                    });
            });
        },
        reloadBanka_AC({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.apiUrl + "/banke")
                    .then((response) => {
                        const mjesta = [JSON.stringify(response.data)];
                        localStorage.setItem("banke", mjesta);
                        resolve(response);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("banke");
                        reject(err);
                    });
            });
        },
        //----------------KORISNICI-------------
        reloadKorisnici_AC({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.apiUrl + "/korisnici")
                    .then((response) => {
                        this.initialize(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => (this.loading = false));
            });
        },

        // register({ commit }, user) {
        //     return new Promise((resolve, reject) => {
        //         commit("auth_request");
        //         axios({
        //             url: "http://agrosys.work/api/register",
        //             data: user,
        //             method: "POST",
        //         })
        //             .then((resp) => {
        //                 const token = resp.data.token;
        //                 const user = resp.data.user;

        //                 localStorage.setItem("token", token);
        //                 axios.defaults.headers.common["Authorization"] = token;
        //                 commit("auth_success", { token: token, user: user });
        //                 resolve(resp);
        //             })
        //             .catch((err) => {
        //                 commit("auth_error", err);
        //                 localStorage.removeItem("token");
        //                 reject(err);
        //             });
        //     });
        // },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.state.apiUrl + "/logout", {
                        email: this.state.user.email,
                    })
                    .then(function (response) {
                        if (response.data.success) {
                            // this.logoutStatus = true;
                        }
                    })
                    .catch(function (error) {});

                commit("logout");

                delete axios.defaults.headers.common["Authorization"];
                resolve();
            });
        },
    },
    modules: {},
});
