<template>
    <div>
        <v-row justify="space-between">
            <v-col cols="12" md="12">
                <v-data-table
                    :headers="headers"
                    :items="dataItems"
                    item-key="preporuka_id"
                    sort-by="preporuka_id"
                    group-by="gospodarstvo_naziv"
                    :search="search"
                    no-results-text="Nema pronađenih"
                    no-data-text="Nema podataka"
                    fixed-header
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    :loading="loading"
                    @page-count="pageCount = $event"
                    :expanded.sync="expanded"
                    single-expand
                    show-expand
                >
                    <template
                        v-slot:[`group.header`]="{ items, isOpen, toggle }"
                    >
                        <th colspan="12" class="teal lighten-4">
                            <v-row>
                                <v-col cols="2">
                                    <v-icon @click="toggle" class="mr-0">
                                        {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                                    </v-icon>
                                    <span class="">{{
                                        items[0].gospodarstvo_naziv
                                    }}</span>
                                </v-col>
                                <v-col>
                                    <h4>
                                        OIB:
                                        {{ items[0].gospodarstvo_maticni_oib }}
                                    </h4>

                                    <h4>
                                        MIBPG:
                                        {{ items[0].gospodarstvo_maticni_broj }}
                                    </h4>
                                </v-col>
                            </v-row>
                        </th>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <div
                                            class="preporukaIspis"
                                            v-html="item.preporuka_sadrzaj"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <div class="text-right">
                                            <v-btn
                                                color="primary"
                                                dark
                                                @click="ispisObrasca(item)"
                                            >
                                                Ispiši
                                                <v-icon small class="ml-2">
                                                    mdi-printer
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                dark
                                                @click="
                                                    preuzimanjeObrasca(item)
                                                "
                                            >
                                                Preuzmi
                                                <v-icon small class="ml-2">
                                                    mdi-download
                                                </v-icon>
                                            </v-btn>

                                            <v-btn
                                                color="primary"
                                                class=""
                                                @click="posaljiDialog(item)"
                                            >
                                                <v-icon></v-icon>
                                                Pošalji
                                                <v-icon small class="ml-2">
                                                    mdi-email
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat class="grey lighten-4">
                            <h2 class="mr-5">Preporuke</h2>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Pretraga"
                                single-line
                                hide-details
                            ></v-text-field>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.preporuka_naziv`]="{ item }">
                        <v-icon class="mr-3">mdi-eyedropper</v-icon>
                        <router-link
                            :to="{
                                name: 'preporuka',
                                params: { id: item.preporuka_id },
                            }"
                            class="routerLink"
                        >
                            {{ item.preporuka_naziv }}
                        </router-link>
                    </template>

                    <template v-slot:[`item.preporuka_datum`]="{ item }">
                        <span> {{ formatDate(item.preporuka_datum) }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="ispisObrasca(item)"
                                        class="mr-2"
                                        color="green lighten-2"
                                    >
                                        mdi-printer
                                    </v-icon>
                                </span>
                            </template>
                            <span>Ispiši preporuku</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="preuzimanjeObrasca(item)"
                                        class="mr-2"
                                        color="blue lighten-2"
                                    >
                                        mdi-download
                                    </v-icon>
                                </span>
                            </template>
                            <span>Preuzmi preporuku</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        @click="obrisiDialog(item.preporuka_id)"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red lighten-2"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </span>
                            </template>
                            <span>Obriši</span>
                        </v-tooltip>
                    </template>
                    <!-- Filter slot -->
                    <template v-slot:header="{ props: { headers } }">
                        <th colspan="11">
                            <v-expansion-panels accordion tile focusable>
                                <v-expansion-panel>
                                    <v-expansion-panel-header
                                        class="blue lighten-4"
                                    >
                                        <v-row no-gutters align="center">
                                            <v-col cols="2" class="d-flex">
                                                <div>
                                                    <v-icon>mdi-filter</v-icon>
                                                </div>
                                                <div class="mt-1">
                                                    Filtrirajte podatke
                                                </div>
                                            </v-col>
                                            <v-col cols="8" class="d-flex">
                                                <!-- <div
                                                v-if="godinaFilter"
                                                class="ml-4"
                                            >
                                                <span
                                                    class="text--secondary font-weight-light"
                                                    color="primary"
                                                >
                                                    Godina analize
                                                </span>
                                                <h5 class="text--secondary">
                                                    {{ godinaFilter }}
                                                </h5>
                                            </div> -->

                                                <div
                                                    v-if="operaterFilter"
                                                    class="ml-4"
                                                >
                                                    <span
                                                        class="text--secondary font-weight-light"
                                                    >
                                                        Operater
                                                    </span>
                                                    <h5 class="text--secondary">
                                                        {{ operaterFilter }}
                                                    </h5>
                                                </div>
                                                <div
                                                    v-if="datumFilter"
                                                    class="ml-4"
                                                >
                                                    <span
                                                        class="text--secondary font-weight-light"
                                                    >
                                                        Datum kalkullacije
                                                    </span>
                                                    <h5 class="text--secondary">
                                                        {{ formatedDate }}
                                                    </h5>
                                                </div>
                                            </v-col>
                                            <v-col cols="2" class="text-center">
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row class="mt-1">
                                            <!-- <v-col>
                                        <v-select
                                            :items="godineLista()"
                                            label="Izbor godine"
                                            v-model="godinaFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-select
                                    ></v-col> -->
                                            <v-col>
                                                <v-autocomplete
                                                    :items="operatori"
                                                    label="Izbor operatora"
                                                    item-text="name"
                                                    item-value="name"
                                                    v-model="operaterFilter"
                                                    clearable
                                                    dense
                                                    hide-details
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-menu
                                                    v-model="izbornikDatum"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                formatedDate
                                                            "
                                                            label="Datum"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            required
                                                            clearable
                                                            dense
                                                            @click:clear="
                                                                ocistiDatumFilter
                                                            "
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="datumFilter"
                                                        @input="
                                                            izbornikDatum = false
                                                        "
                                                        locale="hr-HR"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </th>
                    </template>
                    <div class="text-center">
                        <v-pagination
                            v-model="page"
                            :length="4"
                            circle
                        ></v-pagination>
                    </div>
                </v-data-table>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    circle
                ></v-pagination>
            </v-col>

            <!-- Dialog obrisi-->
            <v-dialog v-model="dialogObrisi" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Potvrdite brisanje!</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogObrisi = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="obrisi(obrisiId)"
                        >
                            Obriši
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog slanje email-->
            <v-dialog v-model="dialogSlanjeEmail" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5"
                            >Potvrdite slanje na unešenu e-mail adrsu!</span
                        >
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4"></v-col>
                            <v-col cols="8"></v-col>
                        </v-row>
                        <v-form
                            ref="formSlanje"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                v-model.number="emailSlanje"
                                label="Email adresa"
                                required
                                outlined
                                clearable
                                :rules="emailRules"
                                dense
                            ></v-text-field>
                        </v-form>
                        <div class="text-center" v-show="slanjeStatus">
                            <h3 class="font-light">Slanje u tijeku!</h3>

                            <v-progress-linear
                                indeterminate
                                color="cyan"
                            ></v-progress-linear>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogSlanjeEmail = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="posaljiPreporuku"
                        >
                            Pošalji e-mail
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- ---------------SLANJE ------------- -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            @beforeDownload="beforeDownloadSlanje($event)"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfSlanje"
            :html-to-pdf-options="slanjeOptions"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <preporuka-obrazac
                        :preporuka="PreporukaIspis"
                        v-if="PreporukaIspis"
                    ></preporuka-obrazac>
                    <div class="verticalSpacer"></div>
                </v-container>
            </section>
        </vue-html2pdf>

        <!-- ---------------DOWNLOAD ------------- -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <preporuka-obrazac
                        :preporuka="PreporukaIspis"
                        v-if="PreporukaIspis"
                    ></preporuka-obrazac>
                    <div class="verticalSpacer"></div>
                </v-container>
            </section>
        </vue-html2pdf>
        <div>
            <!-- ISPIS  -->
            <div id="printMe" class="hidden-screen-only">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <preporuka-obrazac
                        :preporuka="PreporukaIspis"
                        v-if="PreporukaIspis"
                    ></preporuka-obrazac>
                    <obrazac-podnozje></obrazac-podnozje>
                    <div class="verticalSpacer"></div>
                </v-container>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import PreporukaObrazac from "../obrasci/PreporukaObrazac.vue";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import ObrazacPodnozje from "../obrasci/ObrazacPodnozje.vue";
import uploadDokumenta from "../../api/upload";
export default {
    components: {
        VueHtml2pdf,
        "preporuka-obrazac": PreporukaObrazac,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "obrazac-podnozje": ObrazacPodnozje,
    },
    data: () => ({
        valid: false,
        vlasnikProfil: {},
        PreporukaIspis: null,
        operaterIme: "",
        izbornikDatum: null,
        formatedDate: null,
        datumFilter: null,
        operaterFilter: null,
        podnozjeTekst: "",
        page: 1,
        imeDatoteke: "",
        expanded: [],
        operatori: [],
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        loading: false,
        slanjeStatus: false,
        slanjeOptions: {
            filename: `Preporuka.pdf`,
        },
        emailSlanje: "", //Varijabla za spremanje email adrese
        dialogSlanjeEmail: false,

        dataItems: [],
        analize: [],
        obrisiId: "",
        dialogObrisi: false,
        dokument: null,
        description: "",
        emailRules: [
            (v) => !!v || "E-mail je potrebna",
            (v) => /.+@.+\..+/.test(v) || "E-mail adresa nije ispravna",
        ],
    }),

    created() {
        this.ucitaj();
        this.ucitajGlavniProfil();
        this.ucitajOperatore();
    },
    computed: {
        headers() {
            return [
                {
                    text: "Naziv ",
                    align: "start",
                    sortable: false,
                    value: "preporuka_naziv",
                    width: "25%",
                },
                {
                    text: "Gospodarstvo",
                    value: "gospodarstvo_naziv",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Broj preporuke",
                    value: "preporuka_broj",
                    sortable: false,
                    align: "center",
                },

                {
                    text: "Broj uzorka",
                    value: "uzorak_broj",
                    sortable: false,
                },
                {
                    text: "Broj analize",
                    value: "analiza_broj",
                    sortable: false,
                },
                {
                    text: "Datum preporuke",
                    value: "preporuka_datum",
                    sortable: false,
                    filter: this.datumiFilter,
                },
                {
                    text: "Preporuku izradio",
                    value: "preporuka_operator",
                    sortable: false,
                    filter: this.operateriFilter,
                },
                {
                    text: "OIB",
                    value: "gospodarstvo_maticni_oib",
                    sortable: false,
                    align: " d-none", //Skriveno , tu za pretragu
                },
                {
                    text: "Operacije",
                    value: "actions",
                    sortable: false,
                    width: "130px",
                },
            ];
        },
    },
    watch: {
        datumFilter(novo, staro) {
            this.formatedDate = this.formatDate(novo);
            // this.dataItemsKoristeniFilteri.datumFilter = this.formatedDate;
        },
    },
    methods: {
        selectFile(dok) {
            let file = dok;
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onerror = (evt) => {
                console.error(evt);
            };
            this.dokument = dok;
        },

        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        ucitaj() {
            this.$Api
                .getData("preporuke")
                .then((response) => {
                    this.dataItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajOperatore() {
            this.$Api
                .getData("korisnici")
                .then((response) => {
                    this.operatori = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        async ucitajGlavniProfil() {
            this.vlasnikProfil = JSON.parse(sessionStorage.getItem("gp"));
        },
        obrisi(id) {
            this.$Api
                .delete("preporuke/delete", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })

                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ocistiDatumFilter() {
            this.datumFilter = "";
        },
        datumiFilter(value) {
            if (!this.datumFilter) {
                return true;
            }
            return value === this.datumFilter;
        },
        operateriFilter(value) {
            if (!this.operaterFilter) {
                return true;
            }
            return value === this.operaterFilter;
        },
        preuzimanjeObrasca(item) {
            this.PreporukaIspis = item;
            this.imeDatoteke = "Preporuka-" + item.preporuka_broj + ".pdf";
            this.postaviPodnozje();
            setTimeout(() => {
                this.generateReport();
            }, "500");
        },
        //Otvara dialog i postavlja item u PreporukaIspis
        posaljiDialog(item) {
            this.PreporukaIspis = item;
            this.dialogSlanjeEmail = true;

            this.emailSlanje = this.PreporukaIspis.gospodarstvo_email;
        },

        //Generira dokument i prosljeđuje za slanje
        posaljiPreporuku(item) {
            if (this.$refs.formSlanje.validate()) {
                this.slanjeStatus = true;
                this.slanjeOptions.filename =
                    "Preporuka" +
                    this.PreporukaIspis.preporuka_broj.replace("/", "-");
                let email = {
                    email_primatelj_adresa: this.emailSlanje,
                    email_posiljatelj_adresa: this.vlasnikProfil.email,
                    email_subject:
                        this.vlasnikProfil.name +
                        " " +
                        this.slanjeOptions.filename,
                    html_body: this.PreporukaIspis.preporuka_sadrzaj,
                    email_attachment: "Preporuka",
                    email_document_path:
                        "dokumenti/" + this.slanjeOptions.filename + ".pdf",
                    kupac_data: JSON.stringify(this.PreporukaIspis),
                };

                this.generateSlanje();

                setTimeout(() => {
                    this.$Api.posaljiEmail(email).then((response) => {
                        this.slanjeStatus = false;
                        this.dialogSlanjeEmail = false;
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    });
                }, "2000");
            }
        },
        async ispisObrasca(item) {
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.PreporukaIspis = item;
            this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printMe", localOptions);
            }, "500");
        },
        //Generira za download
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        //Generira za slanje
        async generateSlanje() {
            this.$refs.html2PdfSlanje.generatePdf();
        },
        //Slanje
        async beforeDownloadSlanje({ html2pdf, options, pdfContent }) {
            html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .output("datauristring")
                .then(function (pdfAsString) {
                    // The PDF has been converted to a Data URI string and passed to this function.
                    // Use pdfAsString however you like (send as email, etc)!
                    this.dokument = pdfAsString;

                    setTimeout(() => {
                        uploadDokumenta
                            .uploadDokumenta(pdfAsString, options.filename)
                            .then((response) => {});
                    }, "1000");
                });
        },

        //Preuzimanje dokumenta
        async beforeDownload({ html2pdf, options, pdfContent }) {
            html2pdf().set(options).from(pdfContent).toPdf().save();
        },
        postaviPodnozje() {
            this.podnozjeTekst = this.vlasnikProfil.name;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
<style>
/* Stil za download */

.preporukaIspis h2 {
    background-color: #5b7883;
    color: #eeeeee;
    font-size: 1rem;
    padding: 5px;
    margin-bottom: 0;
    padding-left: 10px;
}
.preporukaIspis table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.preporukaIspis td {
    border: 1px solid #ced4da;
    font-size: 0.8rem;
}
.preporukaIspis th {
    min-width: 1em;
    border: 1px solid #ced4da;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}
</style>
