<template>
    <v-container>
        <v-row>
            <v-col cols="4" md="4">
                <analitika-uzorci></analitika-uzorci>
            </v-col>
            <v-col cols="4" md="4">
                <analitika-analiza></analitika-analiza>
            </v-col>
            <v-col cols="4" md="4">
                <analitika-klijenti></analitika-klijenti>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AnalitikaUzoraka from "../../components/dashboard/AnalitikaUzorci.vue";
import AnalitikaAnaliza from "../../components/dashboard/AnalitikaAnaliza.vue";
import Analitikaklijenti from "../../components/dashboard/AnalitikaKlijenti.vue";
export default {
    components: {
        "analitika-uzorci": AnalitikaUzoraka,
        "analitika-analiza": AnalitikaAnaliza,
        "analitika-klijenti": Analitikaklijenti,
    },
    data() {
        return {
            brojUzoraka: null,
            brojAnaliza: null,
            brojKlijenata: null,
            uzorci: [],
            analize: [],
            klijenti: [],
        };
    },
    methods: {
        ucitajUzorke() {
            this.$Api.getData("uzorci").then((response) => {
                this.uzorci = response.data;
                this.uzorciBroj();
            });
        },
        ucitajAnalize() {
            this.$Api.getData("analize").then((response) => {
                this.analize = response.data;
                this.analizeBroj();
            });
        },
        ucitajKlijente() {
            this.$Api.getData("gospodarstva").then((response) => {
                this.klijenti = response.data;
                this.klijentiBroj();
            });
        },
        uzorciBroj() {
            this.brojUzoraka = this.uzorci.length;
        },
        analizeBroj() {
            this.brojAnaliza = this.analize.length;
        },
        klijentiBroj() {
            this.brojKlijenata = this.klijenti.length;
        },
    },
    created() {
        this.ucitajUzorke();
        this.ucitajAnalize();
        this.ucitajKlijente();
    },
};
</script>

<style></style>
