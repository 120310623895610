<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <v-card color="primary">
                        <v-card-title class="white--text">
                            Agrokemijska analiza tla
                        </v-card-title>
                        <v-card-text class="text-right" v-if="podatak">
                            <v-row class="justify-end">
                                <v-btn @click="ispisObrasca" color="primary">
                                    Ispis</v-btn
                                >

                                <v-btn @click="downloadObrasca" color="primary"
                                    >Preuzimanje</v-btn
                                >

                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download
                                        disable-wrapper-click
                                        :filename="FilenameXlsx"
                                    >
                                        <template #default="{ download }">
                                            <v-btn
                                                @click="download"
                                                color="primary"
                                            >
                                                Izvoz
                                            </v-btn>
                                        </template>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-4" elevation="17">
                        <div id="printMe">
                            <v-row no-gutters>
                                <v-col>
                                    <div class="text-left firmaLogo">
                                        <img
                                            v-if="logotip"
                                            :src="logotip"
                                            max-width="140"
                                            width="140"
                                            class="ml-5 logotip-print"
                                        />

                                        <p
                                            class="text-left firmaInfo"
                                            v-if="vlasnikProfil"
                                        >
                                            {{ vlasnikProfil.name }} |
                                            {{ vlasnikProfil.ulica }}
                                            {{ vlasnikProfil.kucni_broj }}
                                            |
                                            {{
                                                vlasnikProfil.glavni_mjesto_broj
                                            }}
                                            {{
                                                vlasnikProfil.glavni_mjesto_naziv
                                            }}
                                            <br />
                                            {{ vlasnikProfil.web }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <analiza-obrazac
                                :analiza="podatak"
                                v-if="podatak"
                            ></analiza-obrazac>
                            <div class="verticalSpacer"></div>
                            <v-card-text
                                v-if="podatak == null"
                                class="text-center"
                            >
                                <h1>{{ poruka }}</h1>
                            </v-card-text>
                            <div class="footer"></div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col> </v-col>
            </v-row>
        </v-container>
        <!-- -DOWNLOAD  -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <v-row no-gutters>
                        <v-col>
                            <div class="text-left firmaLogo">
                                <img
                                    v-if="logotip"
                                    :src="logotip"
                                    max-width="140"
                                    width="140"
                                    class="ml-5 logotip-print"
                                />

                                <p
                                    class="text-left firmaInfo"
                                    v-if="vlasnikProfil"
                                >
                                    {{ vlasnikProfil.name }} |
                                    {{ vlasnikProfil.ulica }}
                                    {{ vlasnikProfil.kucni_broj }}
                                    |
                                    {{ vlasnikProfil.glavni_mjesto_broj }}
                                    {{ vlasnikProfil.glavni_mjesto_naziv }}
                                    <br />
                                    {{ vlasnikProfil.web }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <analiza-obrazac
                        :analiza="podatak"
                        v-if="podatak"
                    ></analiza-obrazac>
                    <div class="verticalSpacer"></div>

                    <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
                </v-container>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import AnalizaObrazac from "../obrasci/AnalizaObrazac.vue";
import ObrazacPodnozje from "../obrasci/ObrazacPodnozje.vue";
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";

export default {
    components: {
        VueHtml2pdf,
        "obrazac-podnozje": ObrazacPodnozje,
        "analiza-obrazac": AnalizaObrazac,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    data: () => ({
        podatak: null,
        poruka: "Nemate pravo pristupa traženim podacima!",
        imeDatoteke: null,
        FilenameXlsx: null,
        sheetName: "Analiza",
        sheets: [
            {
                name: "Analiza",
                data: [],
            },
        ],
        logotip: "",
        vlasnikProfil: null,
        collection: [{ a: 1, b: 2 }],
    }),
    methods: {
        prikaziAnalizu(signature, analizaId) {
            this.$Api
                .prikaziAnalizuVanjski(signature, analizaId)
                .then((response) => {
                    this.podatak = response.data;
                    this.formirajExcell(this.podatak);
                })
                .catch((err) => {
                    // this.podatak = err;

                    this.podatak = null;
                });
        },
        async ucitajVlasnikInformacije() {
            await this.$Api
                .getVlasnikBasicInfo()
                .then((response) => {
                    this.vlasnikProfil = response.data;
                })
                .catch((err) => {
                    this.vlasnikProfil = null;
                });
        },
        ucitajlogo() {
            this.logotip =
                process.env.MIX_LOGO_URL + this.vlasnikProfil.logotip;
        },
        formirajExcell(analiza) {
            this.FilenameXlsx = "Analiza " + analiza.analiza_broj + ".xlsx";
            let zaglavlje = {
                Korisnik: analiza.gospodarstvo_ime,
                OIB: analiza.gospodarstvo_oib,
                MIBPG: analiza.gospodarstvo_maticni_broj,
                Adresa:
                    analiza.gospodarstvo_ulica +
                    " " +
                    analiza.gospodarstvo_kucni_broj +
                    ", " +
                    analiza.gospodarstvo_mjesto_naziv,
                "Analiza broj": analiza.analiza_broj,
                Parcela: analiza.parcela_naziv,
                ARKOD: analiza.parcela_naziv,
                "Katastarska čestica": analiza.parcela_kat_cestica,
                "Broj uzorka": analiza.uzorak_broj,
                "Dubina uzorka": analiza.uzorak_dubina,
                "Datum uzorka": this.formatDate(analiza.uzorak_datum),
                "pH-KCI": analiza.analiza_phkci,
                "pH-H2O": analiza.analiza_phh2o,
                "AL-P2O5 mg/100g tla": analiza.analiza_alp2o5,
                "AL-K2O mg/100g tla": analiza.analiza_alpk2o,
                "Humus %": analiza.analiza_humus,
                "CaCO3 %": analiza.analiza_alpk2o,
                "Hk cmol (+)kg -1": analiza.analiza_hkcmol,
            };

            this.sheets[0].name = "Analiza" + analiza.analiza_broj;
            this.sheets[0].data.push(zaglavlje);
            // this.sheets[0].data.push(zaglavlje);
        },
        async ispisObrasca() {
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };

            this.$htmlToPaper("printMe", localOptions);
        },
        downloadObrasca() {
            this.imeDatoteke = "Analiza-" + this.podatak.analiza_broj;

            setTimeout(() => {
                this.generateReport();
            }, "500");
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    async created() {
        await this.ucitajVlasnikInformacije();
        this.ucitajlogo();
    },
    mounted() {
        this.prikaziAnalizu(
            this.$route.query.signature,
            this.$route.query.analizaId
        );

        // this.prikaziAnalizu(
        //     "f40a2a8ef895505bd2f050b8d8aef6abb674a38ddb1d65452131aa0d104c5664",
        //     12
        // );
    },
};
</script>

<style scoped>
.firmaLogo {
    font-size: 12px;
    color: #7a7a7a;
    text-align: left;
    margin-top: 30px;
}
.firmaInfo {
    font-size: 12px;
    color: #7a7a7a;
    text-align: left;
}
</style>
