<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.name"
                            :counter="40"
                            :rules="imeRules"
                            label="Ime i Prezime"
                            prepend-icon="mdi-account"
                            required
                            outlined
                            dense
                        ></v-text-field>

                        <v-autocomplete
                            v-model="form.mjesto_id"
                            :items="mjesta"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Mjesto"
                            prepend-icon="mdi-city"
                            outlined
                            dense
                            :clearable="true"
                        >
                        </v-autocomplete>

                        <v-text-field
                            v-model="form.ulica"
                            label="Ulica"
                            prepend-icon="mdi-drag-horizontal-variant"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.kucni_broj"
                            label="Kućni broj"
                            prepend-icon="mdi-numeric"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-autocomplete
                            v-model="form.role"
                            :items="uloge"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Uloga"
                            prepend-icon="mdi-account-supervisor-circle"
                            outlined
                            dense
                            :clearable="true"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.telefon"
                            label="Telefon"
                            prepend-icon="mdi-phone"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.mobitel"
                            label="Mobitel"
                            prepend-icon="mdi-cellphone"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.email"
                            :rules="emailRules"
                            label="E-mail"
                            prepend-icon="mdi-email"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.web"
                            label="Web stranica"
                            prepend-icon="mdi-web"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Povratak
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="submitForm">
                                Ažuriraj podatke
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                        <h3 class="mt-4">Promijena zaporke</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-form
                            ref="formLozinka"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-row>
                                <v-col cols="4">
                                    <div class="text-left">
                                        <v-btn
                                            color="primary"
                                            class=""
                                            @click="promjeniLozinku"
                                        >
                                            Promijeni zaporku
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="formLozinka.noviPassword"
                                        :rules="lozinkaRules"
                                        label="Nova zaporka"
                                        prepend-icon="mdi-database-search"
                                        required
                                        outlined
                                        clearable
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="formLozinka.noviPasswordPonovi"
                                        :rules="lozinkaPonovoRules"
                                        label="Ponovi novu zaporku"
                                        prepend-icon="mdi-database-search"
                                        required
                                        outlined
                                        clearable
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            mjesta: [],
            uloge: [
                { id: 1, naziv: "Administrator" },
                { id: 2, naziv: "Operator" },
                { id: 3, naziv: "Vanjski suradnik" },
            ],
            selectedItem: null,
            isLoading: false,
            responseData: "",
            valid: true,
            //ID  profila
            id: null,
            imeRules: [(v) => !!v || "Ime i prezime je potrebno"],
            telefonRules: [
                (v) => !!v || "Unesite telefonski broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            unosRules: [(v) => !!v || "Unos je potreban"],
            ulicaRules: [(v) => !!v || "Unos je potreban"],
            lozinkaRules: [
                (v) => !!v || "Zaporka potrebna",
                // (v) =>
                //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/.test(
                //         v
                //     ) || "Zaporka ne zadovoljava uvjete",
            ],

            errorLozinka: "", //Varijabla greske poruke lozinke
            lozinkaPonovoRules: [
                (v) => !!v || "Zaporka potrebna",
                // (v) =>
                //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/.test(
                //         v
                //     ) || "Zaporka ne zadovoljava uvjete",
                // (v) =>
                //     v == this.formLozinka.noviPassword ||
                //     "Ponovljena zaporka nije jednaka",
            ],
            select: null,
            //Podaci iz forme
            form: {},
            //Podaci za promjenu lozinke
            formLozinka: {},
        };
    },

    methods: {
        odustani() {
            this.$router.push("/korisnici");
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submitForm() {
            if (this.validate() == true) {
                axios
                    .put("/api/korisnici/" + this.id, this.form)
                    .then((res) => {
                        //Perform Success Action
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno ažuriranje"
                        );
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            error.response.data.errors.email[0]
                        );
                    })
                    .finally(() => {
                        //Perform action in always
                    });
            }
        },

        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajKorisnika($id) {
            axios
                .get(this.$store.getters.getApiUrl + "/korisnici/" + $id)
                .then((response) => {
                    this.form = response.data;
                })
                .catch((error) => {
                    pokaziPoruku(error.response.data.error);
                });
        },
        promjeniLozinku() {
            if (this.validateLozinka()) {
                this.$Api
                    .zamjeniLozinkuAdmin(this.formLozinka, this.id)
                    .then((response) => {
                        this.errorLozinka = "";
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                        this.resetFormlozinka();
                    })
                    .catch((err) => {
                        this.errorLozinka = err.response.data.message;
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            err.response.data.message
                        );
                    });
            }
        },
        async usporediLozinku(lozinka, id) {
            let rezultat = false;

            await this.$Api
                .usporediLozinku(lozinka, id)
                .then((response) => {
                    this.errorLozinka = "";
                    rezultat = response.data.status;
                })
                .catch((err) => {
                    this.errorLozinka = err.response.data.message;
                    rezultat = err.response.data.status;
                });

            return rezultat;
        },
        validateLozinka() {
            if (this.$refs.formLozinka.validate()) {
                return true;
            }
        },
        resetFormlozinka() {
            this.$refs.formLozinka.reset();
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.ucitajMjesta();
        this.ucitajKorisnika(this.id);
    },
    watch: {},
};
</script>

<style></style>
