<template>
    <div class="footer">
        <v-row class="text-center">
            <v-col cols="12">
                <div class="obrazacPodnozje">
                    <p class="text-center">
                        {{ vlasnikProfil.name }} | {{ vlasnikProfil.ulica }}
                        {{ vlasnikProfil.kucni_broj }}
                        |
                        {{ vlasnikProfil.glavni_mjesto_broj }}
                        {{ vlasnikProfil.glavni_mjesto_naziv }} |
                        {{ vlasnikProfil.web }} | {{ vlasnikProfil.email }} |
                        {{ vlasnikProfil.telefon }} |
                        {{ vlasnikProfil.mobitel }}
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vlasnikProfil: {},
        };
    },
    computed: {
        prikaziLogo() {
            return this.$store.getters.getLogoUrl + this.vlasnikProfil.logotip;
        },
    },
    methods: {
        ucitajGlavniProfil() {
            this.vlasnikProfil = JSON.parse(sessionStorage.getItem("gp"));
        },
    },
    created() {
        this.ucitajGlavniProfil();
    },
};
</script>

<style scoped>
.footer {
    width: 100%;
    bottom: 0px;
    text-align: center;
    margin-top: 1px;
    color: #818181;
    font-size: 14px;
}

.footer.obrazacPodnozje {
    font-size: 12px;
    color: #7a7a7a;
    text-align: center;
}
</style>
