<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.naziv"
                            :counter="40"
                            :rules="nazivRules"
                            label="Naziv mjesta"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.broj"
                            :counter="5"
                            :rules="post_brojRules"
                            label="Poštanski broj"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.opcina"
                            :rules="opcinaRules"
                            label="Opčina"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-autocomplete
                            v-model="form.zupanija"
                            :items="zupanije"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Županija"
                            prepend-icon="mdi-database-search"
                            outlined
                            dense
                            :clearable="true"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Povratak
                            </v-btn>
                        </div></v-col
                    >
                    <v-col>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="submitForm">
                                Spremi
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";
import zupanije from "../../config/data/zupanije.js";
export default {
    data() {
        return {
            zupanije: zupanije,
            selectedItem: 1,
            isLoading: false,
            search: null,
            responseData: "",
            valid: true,
            //ID glavnog profila
            nazivRules: [(v) => !!v || "Naziv je potreban"],
            post_brojRules: [
                (v) => !!v || "Unesite poštanski broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            opcinaRules: [(v) => !!v || "Opcina je potrebana"],
            select: null,
            //Podaci iz forme
            form: {
                naziv: "",
                broj: "",
                opcina: "",
                zupanija: this.selectedItem,
            },
        };
    },

    methods: {
        odustani() {
            this.$router.push("/opci/mjesta");
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submitForm() {
            if (this.validate()) {
                axios
                    .post(
                        this.$store.getters.getApiUrl + "/mjesta/novo-mjesto",
                        this.form
                    )
                    .then((res) => {
                        //Perform Success Action
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno spremljeno novo mjesto!"
                        );
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            "Greška prilikom ažuriranja!"
                        );
                    })
                    .finally(() => {
                        //Nakon unosa poziva reload iz store
                        if (this.$store.getters.isLoggedIn) {
                            this.reloadMjesta();
                        }
                        this.reset();
                        this.resetValidation();
                    });
            }
        },
        reloadMjesta() {
            this.$store.dispatch("reloadMjesta_AC");
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    mounted() {},
    watch: {},
};
</script>

<style></style>
