<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.oib"
                            :counter="40"
                            :rules="oibRules"
                            label="OIB"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.naziv"
                            :counter="5"
                            :rules="nazivRules"
                            label="Naziv"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.maticni_broj"
                            :counter="10"
                            label="MIBPG"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.iban"
                            :rules="ibanRules"
                            label="IBAN"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.bic_swift"
                            :rules="swiftRules"
                            label="BIC/SWIFT"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Povratak
                            </v-btn>
                        </div></v-col
                    >
                    <v-col>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="submitForm">
                                Spremi
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            responseData: "",
            valid: true,
            //ID glavnog profila
            id: null,

            oibRules: [
                (v) => !!v || "Unesite OIB",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            nazivRules: [(v) => !!v || "Naziv je potreban"],
            ibanRules: [(v) => !!v || "IBAN je potreban"],
            swiftRules: [(v) => !!v || "SWIFT je potreban"],
            //Podaci iz forme
            form: {
                oib: "",
                naziv: "",
                maticni_broj: "",
                iban: "",
                bic_swift: "",
            },
        };
    },

    methods: {
        odustani() {
            this.$router.push("/opci/banka");
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submitForm() {
            // this.kreirajPolja();
            if (this.validate()) {
                axios
                    .post(
                        this.$store.getters.getApiUrl + "/banke/nova-banka",
                        this.form
                    )
                    .then((res) => {
                        //Perform Success Action
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno spremljeno novo mjesto!"
                        );
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            "Greška prilikom ažuriranja!"
                        );
                    })
                    .finally(() => {
                        //Nakon unosa poziva reload iz store
                        if (this.$store.getters.isLoggedIn) {
                            this.reloadBanka();
                        }
                        this.reset();
                        this.resetValidation();
                    });
            }
        },
        reloadBanka() {
            //Reload banaka nakon novog unosa
            this.$store.dispatch("reloadBanka_AC");
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    mounted() {},
    watch: {},
};
</script>

<style></style>
