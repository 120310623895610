<template>
    <div v-if="items.length > 1">
        <v-list-group
            :value="false"
            :prepend-icon="ikonaKategorije"
            active-class="bg-active"
        >
            <template v-slot:activator>
                <v-list-item-title>{{ naslovKategorije }}</v-list-item-title>
            </template>
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
                link
                :to="item.itemLink"
            >
                <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
        </v-list-group>
    </div>
    <div v-else>
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
            link
            :to="item.itemLink"
        >
            <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
    </div>
</template>

<script>
export default {
    props: {
        naslovKategorije: "",
        ikonaKategorije: "",
        items: {
            type: Array,
            required: true,
            default: () => [{ title: 0, itemLink: "", icon: "-" }],
        },
    },
};
</script>
<style scoped>
.bg-active {
    background-color: black;
    color: white !important;
}
</style>
