<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataItems"
            key="id"
            sort-by="name"
            :search="search"
            no-results-text="Nema pronađenih"
            no-data-text="Nema podataka"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            @page-count="pageCount = $event"
        >
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click.stop="dodajDialog()">
                        Dodaj
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.naziv`]="{ item }">
                <a class="routerLink" @click="urediDialog(item)">
                    {{ item.naziv }}</a
                >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="obrisiDialog(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši</span>
                </v-tooltip>
            </template>

            <div class="text-center">
                <v-pagination v-model="page" :length="4" circle></v-pagination>
            </div>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
        <!-- Dialog dodaj-->
        <v-dialog v-model="dialogNovo" persistent max-width="500px">
            <v-form ref="form" lazy-validation v-model="valid">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Dodaj navodnjavanje</span>
                    </v-card-title>

                    <v-container>
                        <v-text-field
                            v-model="form.naziv"
                            label="Naziv"
                            :rules="unosRules"
                            required
                            dense
                            outlined
                        ></v-text-field>
                        <v-text-field
                            v-model="form.vrijednost"
                            label="Vrijednost"
                            outlined
                            dense
                        ></v-text-field>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialogNovo = false">
                            Odustani
                        </v-btn>
                        <v-btn text @click="dodaj"> Spremi </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dialog uredi-->
        <v-dialog v-model="dialogUredi" persistent max-width="500px">
            <v-form ref="form">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Uredi navodnjavanje</span>
                    </v-card-title>

                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.naziv"
                                    label="Naziv"
                                    required
                                    outlined
                                    dense
                                    :rules="unosRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.vrijednost"
                                    label="Vrijednost"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogUredi = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="azuriraj(form.id)"
                        >
                            Ažuriraj
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dialog obrisi-->
        <v-dialog v-model="dialogObrisi" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Potvrdite brisanje!</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogObrisi = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="obrisi(obrisiId)">
                        Obriši
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        valid: true,
        loading: false,
        headers: [
            {
                text: "Navodnjavanje",
                align: "start",
                sortable: false,
                value: "naziv",
            },
            { text: "Uredi", value: "actions", sortable: false, width: "5%" },
        ],
        unosRules: [(v) => !!v || "Unos je potreban"],
        dataItems: [], //Ucitava elemente
        obrisiId: "",
        form: {}, //Objekt za uredjivanje dodavanje spremanje
        dialogNovo: false,
        dialogUredi: false,
        dialogObrisi: false,
    }),

    created() {
        this.ucitaj();
    },

    methods: {
        dodajDialog() {
            this.form.id = "";
            this.form.naziv = "";
            this.form.vrijednost = "";
            this.dialogNovo = true;
        },
        urediDialog(item) {
            this.dialogUredi = true;
            this.form.id = item.id;
            this.form.naziv = item.naziv;
            this.form.vrijednost = item.vrijednost;
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        ucitaj() {
            this.$Api
                .getData("navodnjavanje")
                .then((response) => {
                    this.dataItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        dodaj() {
            if (this.$refs.form.validate()) {
                this.$Api
                    .postData("navodnjavanje", this.form)
                    .then((response) => {
                        this.dialogNovo = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
            this.resetforme();
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("navodnjavanje", id, this.form)
                    .then((response) => {
                        this.dialogUredi = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        obrisi(id) {
            this.$Api
                .delete("navodnjavanje", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
