<template>
    <div>
        <v-card>
            <v-card-text> sadsads </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
