<template>
    <div>
        <v-row>
            <v-col>
                <v-list-item two-line class="naslov">
                    <v-list-item-content>
                        <v-list-item-title>
                            <b>INTERPRETACIJA REZULTATA ANALIZE ZA UZORAK</b>
                            {{ preporuka.preporuka_broj }} <br />
                            <b>NA PARCELI</b> {{ preporuka.parcela_naziv }}

                            <b>POVRŠINE</b>
                            {{ preporuka.parcela_povrsina }} ha ,
                            <b>PLANIRANA KULTURA</b>
                            {{ preporuka.plan_kultura_naziv }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <p>
                    <b>Gospodarstvo: </b>{{ preporuka.gospodarstvo_naziv }}
                    <br />
                    <b>Adresa: </b>{{ mjestoNaziv }} ,
                    {{ preporuka.gospodarstvo_ulica }}
                    {{ preporuka.gospodarstvo_kucni_broj }} <br />
                    <b>Kontakt: </b>{{ preporuka.gospodarstvo_telefon }}/
                    {{ preporuka.gospodarstvo_mobitel }}
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div
                    class="preporukaIspis"
                    v-html="preporuka.preporuka_sadrzaj"
                ></div>
            </v-col>
        </v-row>
        <div class="verticalSpacer"></div>

        <div class="verticalSpacer"></div>
        <v-row>
            <v-col cols="12"> </v-col>
        </v-row>
        <div class="verticalSpacer"></div>
        <div class="verticalSpacer"></div>
        <v-row>
            <v-col cols="8">
                <v-row>
                    <v-col>
                        Preporuku izradio:
                        <b>
                            {{ preporuka.preporuka_operator }}
                        </b>
                        {{ formatDate(preporuka.preporuka_datum) }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"> </v-col>
        </v-row>
        <div class="verticalSpacer"></div>
    </div>
</template>
<script>
export default {
    props: ["preporuka"],
    data() {
        return {
            mjesta: null, // array mjesta iz local storage
            mjestoSingle: null, //Single mjesto prema ID iz
            mjestoNaziv: null,
        };
    },
    watch: {
        mjestoSingle(novo, staro) {
            this.mjestoNaziv = novo.naziv;
        },
    },
    components: {},
    methods: {
        ucitajMjesta() {
            this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
        },
        dohvatiImeMjesta() {
            this.mjestoSingle = this.mjesta.find(
                (mjesto) => mjesto.id === this.preporuka.gospodarstvo_mjesto_id
            );
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
    },

    created() {
        this.ucitajMjesta();
        this.dohvatiImeMjesta();
    },
};
</script>

<style scoped>
.naslov {
    text-align: center;
    margin-top: 15px;
    font-family: "Roboto";
}
.naglaseno {
    color: #682a2a;
}
.verticalSpacer {
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #682a2a;
}
.osnovnoTablica {
    border: 1px solid;
}
.osnovnoTablica td {
    border: 1px solid;
    text-align: center;
}
.osnovnoTablica td.stupacLijevo {
    text-align: right;
    width: 32%;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}

.osnovnoTablicaTlo td.stupacLijevo {
    text-align: left;
    width: 18%;
}
.osnovnoTablicaTlo td.stupacDesno {
    text-align: left;
}
.sadrzajTekst {
    padding: 1.5rem;
}
.preporukaIspis table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    border: 1px solid #682a2a;
}
</style>
