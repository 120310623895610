import PreporukaBaza from "./PreporukaBaza.json";
var preporukaRez = "";
export default {
    dohvatiPhKCI(phKciArg) {
        var result;

        if (phKciArg == "") return (result = 0);

        PreporukaBaza.phKCI.find((item) => {
            if (
                parseFloat(phKciArg) >= item.min &&
                parseFloat(phKciArg) <= item.max
            ) {
                result = { phKCI: item };
            }
        });

        return result;
    },
    dohvatiAlP2O5(AlP2O5) {
        var result;
        if (AlP2O5 == "") return (result = 0);
        PreporukaBaza.AlP2O5.find((item) => {
            if (
                parseFloat(AlP2O5) >= item.min &&
                parseFloat(AlP2O5) <= item.max
            ) {
                result = { AlP2O5: item };
            }
        });

        return result;
    },
    dohvatiAlK2O(AlK2O) {
        var result;
        if (AlK2O == "") return (result = 0);
        PreporukaBaza.AlK2O.find((item) => {
            if (
                parseFloat(AlK2O) >= item.min &&
                parseFloat(AlK2O) <= item.max
            ) {
                result = { AlK2O: item };
            }
        });
        return result;
    },
    dohvatiHumus(humus) {
        var result;
        if (humus == "") return (result = 0);
        PreporukaBaza.humus.find((item) => {
            if (
                parseFloat(humus) >= item.min &&
                parseFloat(humus) <= item.max
            ) {
                result = { humus: item };
            }
        });
        return result;
    },
    dohvatiCaco3(Caco3) {
        var result;
        if (Caco3 == "") return (result = 0);
        PreporukaBaza.Caco3.find((item) => {
            if (
                parseFloat(Caco3) >= item.min &&
                parseFloat(Caco3) <= item.max
            ) {
                result = { Caco3: item };
            }
        });
        return result;
    },

    GenerirajPreporuku(analizaObject) {
        return (preporukaRez = Object.assign(
            this.dohvatiPhKCI(analizaObject.analiza_phkci),
            this.dohvatiAlP2O5(analizaObject.analiza_alp2o5),
            this.dohvatiAlK2O(analizaObject.analiza_alpk2o),
            this.dohvatiHumus(analizaObject.analiza_humus),
            this.dohvatiCaco3(analizaObject.analiza_caco3)
        ));
    },
};
