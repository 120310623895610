<template>
    <div v-if="editor" class="editor">
        <v-container pa-0 ma-0>
            <v-row justify="space-around">
                <v-col cols="12">
                    <div class="editorMenu pa-2">
                        <v-btn
                            icon
                            color="primary"
                            @click="editor.chain().focus().undo().run()"
                        >
                            <v-icon>mdi-undo</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="editor.chain().focus().redo().run()"
                        >
                            <v-icon>mdi-redo</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="editor.chain().focus().toggleBold().run()"
                            :class="{ 'is-active': editor.isActive('bold') }"
                        >
                            <v-icon>mdi-format-bold</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="editor.chain().focus().toggleItalic().run()"
                            :class="{ 'is-active': editor.isActive('italic') }"
                        >
                            <v-icon>mdi-format-italic</v-icon>
                        </v-btn>
                        <v-icon>mdi-dots-vertical</v-icon>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign('left')
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive({
                                    textAlign: 'left',
                                }),
                            }"
                        >
                            <v-icon>mdi-format-align-left</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign('center')
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive({
                                    textAlign: 'center',
                                }),
                            }"
                        >
                            <v-icon>mdi-format-align-center</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign('right')
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive({
                                    textAlign: 'right',
                                }),
                            }"
                        >
                            <v-icon>mdi-format-align-right</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign('justify')
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive({
                                    textAlign: 'justify',
                                }),
                            }"
                        >
                            <v-icon>mdi-format-align-justify</v-icon>
                        </v-btn>

                        <v-btn
                            icon
                            color="primary"
                            @click="editor.chain().focus().setParagraph().run()"
                            :class="{
                                'is-active': editor.isActive('paragraph'),
                            }"
                        >
                            <v-icon>mdi-format-paragraph</v-icon>
                        </v-btn>
                        <v-icon>mdi-dots-vertical</v-icon>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 1 })
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive('heading', {
                                    level: 1,
                                }),
                            }"
                        >
                            <v-icon>mdi-format-header-1</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 2 })
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive('heading', {
                                    level: 2,
                                }),
                            }"
                        >
                            <v-icon>mdi-format-header-2</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 3 })
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive('heading', {
                                    level: 3,
                                }),
                            }"
                        >
                            <v-icon>mdi-format-header-3</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 4 })
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive('heading', {
                                    level: 4,
                                }),
                            }"
                        >
                            <v-icon>mdi-format-header-4</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 5 })
                                    .run()
                            "
                            :class="{
                                'is-active': editor.isActive('heading', {
                                    level: 5,
                                }),
                            }"
                        >
                            <v-icon>mdi-format-header-5</v-icon>
                        </v-btn>
                        <v-icon>mdi-dots-vertical</v-icon>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor.chain().focus().toggleBulletList().run()
                            "
                            :class="{
                                'is-active': editor.isActive('bulletList'),
                            }"
                        >
                            <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor.chain().focus().toggleOrderedList().run()
                            "
                            :class="{
                                'is-active': editor.isActive('orderedList'),
                            }"
                        >
                            <v-icon>mdi-format-list-numbered</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor.chain().focus().toggleBlockquote().run()
                            "
                            :class="{
                                'is-active': editor.isActive('blockquote'),
                            }"
                        >
                            <v-icon>mdi-format-quote-open</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="primary"
                            @click="
                                editor.chain().focus().setHorizontalRule().run()
                            "
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <editor-content :editor="editor" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Heading from "@tiptap/extension-heading";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
export default {
    components: {
        EditorContent,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            editor: null,
        };
    },
    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        },
    },
    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ["heading", "paragraph"],
                }),
                Heading.configure({
                    HTMLAttributes: {
                        class: "mojHeading",
                    },
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                Subscript.configure({
                    HTMLAttributes: {
                        class: "my-custom-class",
                    },
                }),
                Superscript.configure({
                    HTMLAttributes: {
                        class: "my-custom-class",
                    },
                }),
            ],

            onUpdate: () => {
                // HTML
                this.$emit("input", this.editor.getHTML());

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td,
        th {
            min-width: 1em;
            border: 1px solid #ced4da;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;

            > * {
                margin-bottom: 0;
            }
        }

        th {
            font-weight: bold;
            text-align: left;
            background-color: #f1f3f5;
        }

        p {
            margin: 0;
        }
    }

    margin-top: 0.15rem;
    padding: 0.75rem;
    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }
    p {
    }
    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0.75em;
    }
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    mark {
        background-color: #faf594;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        margin-left: 3rem;
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }
}
.is-active {
    // border-color: #393a39;
    // border-style: solid;
    // border-width: 1px;
    background-color: rgba(78, 77, 78, 0.256);
}
*:focus {
    outline: none;
}
.editor {
    display: flex;
    color: #0d0d0d;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid #616161;
}
.editor__content {
}
.editorMenu {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #616161;
}
.mojHeading {
    background-color: #93d3d2;
    padding: 7px;
}
</style>
/* .is-active { border-color: grey; border-style: solid; border-width: 1px; } */
/* *:focus { outline: none; } */
