<template>
    <div>
        <!-- <loader-stranica :state="isLoading"></loader-stranica> -->
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <h3 class="pb-3">Uzorak</h3>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.naziv"
                            :rules="unosRules"
                            label="Naziv"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.broj"
                            :rules="unosRules"
                            label="Broj uzorka"
                            required
                            outlined
                            readonly
                            disabled
                            dense
                        ></v-text-field>
                        <v-select
                            v-model="form.dubina"
                            :items="['0-30', '30-60']"
                            item-text="naziv"
                            item-value="id"
                            required
                            label="Dubina uzorka"
                            dense
                            outlined
                            suffix="cm"
                        ></v-select>
                        <v-menu
                            v-model="izbornikDatum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formatedDate"
                                    label="Odaberite datum"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datum"
                                @input="izbornikDatum = false"
                                locale="hr-HR"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-textarea
                            v-model="form.napomena"
                            outlined
                            label="Napomena"
                            value="Ovdje ide napomena"
                        ></v-textarea>
                        <v-switch
                            class="ml-5"
                            v-model="form.korisnik_uzorkovao"
                            label="Uzorak uzeo korisnik samostalno"
                            color="success"
                            input-value="true"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Odustani
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="text-right">
                            <v-btn
                                color="primary"
                                class=""
                                @click="azuriraj(form.id)"
                            >
                                Ažuriraj podatke
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h3>Opširnije</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">Gospodarstvo</th>
                                    <th class="text-left">Parcela</th>
                                    <th class="text-left">Dubina uzorka</th>
                                    <th class="text-left">Uzorak uzeo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ form.gospodarstvo_naziv }}
                                    </td>
                                    <td>{{ form.parcela_naziv }}</td>
                                    <td>{{ form.dubina }} cm</td>

                                    <td>{{ form.operator_naziv }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            otvorenPanel: 0,
            selectedItem: null,
            isLoading: false,
            progressValue: 0,
            responseData: "",
            valid: true,
            //ID  uzorka
            id: null,
            unosRules: [(v) => !!v || "Unos je potreban"],
            select: null,
            //Podaci iz forme
            form: {},
            datum: null,
            izbornikDatum: false,
            formatedDate: "",
        };
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.form.datum = this.formatISODate(val);
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        azuriraj(uzorakId) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("uzorak", uzorakId, this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        ucitaj(id) {
            this.isLoading = true;
            this.$Api
                .getByID("uzorak", id)
                .then((response) => {
                    this.form = response.data;

                    this.formatedDate = this.formatDate(this.form.datum);
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        odustani() {
            this.$router.push("/uzorci");
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.ucitaj(this.id);
    },
    mounted() {},
};
</script>

<style></style>
