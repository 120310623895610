<template>
    <div class="login-background" id="login-forma">
        <v-container class="pa-5 mt-10">
            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                :color="poruka.color"
                app
                top
            >
                {{ poruka.text }}
            </v-snackbar>

            <v-row align="center" justify="center">
                <v-col xl="6" lg="8" md="8" sm="8" xs="12">
                    <v-card>
                        <v-row>
                            <v-col
                                lg="8"
                                md="8"
                                sm="8"
                                xs="12"
                                class="columnLeft"
                            >
                                <v-card-title class="justify-center">
                                    AgroSolution
                                </v-card-title>

                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                    class="login-form pa-5"
                                >
                                    <v-text-field
                                        name="email"
                                        v-model="email"
                                        :rules="emailRules"
                                        placeholder="E-mail"
                                        prepend-icon="mdi-account"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <v-text-field
                                        @keyup.enter="login"
                                        v-model="password"
                                        type="password"
                                        :rules="passwordRules"
                                        placeholder="Zaporka"
                                        prepend-icon="mdi-form-textbox-password"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>

                                    <div class="text-right">
                                        <v-btn
                                            color="primary"
                                            class=""
                                            @click="login"
                                            :loading="loading"
                                            :disabled="loading"
                                        >
                                            Prijavi se
                                        </v-btn>
                                    </div>
                                </v-form>
                            </v-col>
                            <v-col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="12"
                                class="columnRight"
                            >
                                <v-img
                                    lazy-src="images/logo/firma-logo.png"
                                    src="images/logo/firma-logo.png"
                                    width="150"
                                    class="ma-auto logotip-login mt-10 mb-4"
                                ></v-img>
                                <h6>
                                    Regionalni centar za biotehnološka
                                    istraživanja i razvoj Brodsko-posavske
                                    županije
                                </h6>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <p class="text-center formFooter pt-4 ma-0">
                BSSolutions ©2022 | info@bssolutions.hr
            </p>
        </v-container>
    </div>
</template>
<script>
export default {
    name: "login",

    data() {
        return {
            isRed: true,
            valid: false,
            email: "",
            emailRules: [
                (v) => !!v || "E-mail je potreban",
                (v) => /.+@.+\..+/.test(v) || "E-mail nije ispravan",
            ],
            password: "",
            passwordRules: [(v) => !!v || "Zaporka nije unešena"],
            user: {
                email: "",
                password: "",
            },
            //Poruka
            snackbar: false,
            timeout: 2000,
            loading: false,
            poruka: {
                text: "",
                color: "",
            },
        };
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku($poruka) {
            this.snackbar = true;
            $poruka.text = "Greška prilikom prijave!";
            $poruka.color = "error";
        },
        login: function () {
            if (this.validate()) {
                this.loading = true;
                let email = this.email;
                let password = this.password;
                this.$store
                    .dispatch("login", { email, password })
                    .then(() => {
                        this.$store.dispatch("initGlavni_AC");
                    })
                    .then(() => {
                        this.$router.push("/dashboard");
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false;
                        this.pokaziPoruku(this.poruka);
                    });
            }
        },
    },
    mounted() {},
};
</script>
<style scoped>
.v-progress-circular {
    margin: 1rem;
}
.formFooter {
    font-size: 0.8rem !important;
    font-weight: thin;
    color: #f8f8f8;
}
.login-background {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    text-align: center;
    background-image: url("/images/agrosys-back.jpg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.columnLeft {
}
.columnRight {
    color: #efefef;
    position: relative;
    background-color: #444444;
}
.rightHeader {
    color: #dfdfdf !important;
    font-weight: 100;
    font-size: 1.6rem;
    margin-top: 30px;
    padding-bottom: 5px;
}
.logotip-login {
    /* filter: grayscale(1); */
    filter: brightness(0) invert(1);
}
</style>
