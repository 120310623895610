<template>
    <div>
        <v-row justify="space-between">
            <v-col cols="12" md="6">
                <v-card class="" elevation="4">
                    <v-card-title class="pt-1">
                        Planirane kulture
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="dataItems"
                        key="id"
                        sort-by="name"
                        :search="search"
                        no-results-text="Nema pronađenih"
                        no-data-text="Nema podataka"
                        fixed-header
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                        :loading="loading"
                        @page-count="pageCount = $event"
                    >
                        <template v-slot:top>
                            <v-toolbar flat class="grey lighten-4">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Pretraga"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            <v-btn
                                                color="primary"
                                                dark
                                                @click.stop="dodajDialog()"
                                            >
                                                Dodaj +
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>Dodaj kulturu</span>
                                </v-tooltip>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.naziv`]="{ item }">
                            <a class="routerLink" @click="urediDialog(item)">
                                {{ item.naziv }}</a
                            >
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon
                                            @click="obrisiDialog(item.id)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red lighten-2"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </span>
                                </template>
                                <span>Obriši</span>
                            </v-tooltip>
                        </template>
                        <div class="text-center">
                            <v-pagination
                                v-model="page"
                                :length="4"
                                circle
                            ></v-pagination>
                        </div>
                    </v-data-table>
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        circle
                    ></v-pagination>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <pred-kultura></pred-kultura>
            </v-col>
            <!-- Dialog dodaj-->
            <v-dialog v-model="dialogNovo" persistent max-width="500px">
                <v-form ref="form">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Dodaj kulturu</span>
                        </v-card-title>

                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="form.naziv"
                                        label="Naziv"
                                        required
                                        outlined
                                        dense
                                        :rules="unosRules"
                                    ></v-text-field>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.n"
                                        label="Vrijednost N"
                                        required
                                        dense
                                        outlined
                                        :rules="brojRules"
                                        type="number"
                                        hide-spin-buttons
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.p"
                                        label="Vrijednost P"
                                        required
                                        dense
                                        :rules="brojRules"
                                        type="number"
                                        hide-spin-buttons
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.k"
                                        label="Vrijednost K"
                                        required
                                        dense
                                        type="number"
                                        hide-spin-buttons
                                        :rules="brojRules"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="vrijednost.um_n"
                                        label="Umanjena N komponenta"
                                        required
                                        dense
                                        outlined
                                        maxlength="3"
                                        :rules="brojRules"
                                        hint="Unos je u postotku %"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="prinos.min"
                                        label="Prinos minimum"
                                        required
                                        dense
                                        outlined
                                        :rules="prinosRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="prinos.max"
                                        label="Prinos maksimum"
                                        required
                                        dense
                                        outlined
                                        :rules="prinosRules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogNovo = false">
                                Odustani
                            </v-btn>
                            <v-btn text @click="dodaj"> Spremi </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <!-- Dialog uredi-->
            <v-dialog v-model="dialogUredi" persistent max-width="500px">
                <v-form ref="form">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Uredi kulturu</span>
                        </v-card-title>

                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="form.naziv"
                                        label="Naziv"
                                        required
                                        outlined
                                        dense
                                        :rules="unosRules"
                                    ></v-text-field>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.n"
                                        label="Vrijednost N"
                                        required
                                        dense
                                        outlined
                                        :rules="brojRules"
                                        type="number"
                                        hide-spin-buttons
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.p"
                                        label="Vrijednost P"
                                        required
                                        dense
                                        :rules="brojRules"
                                        type="number"
                                        hide-spin-buttons
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="vrijednost.k"
                                        label="Vrijednost K"
                                        required
                                        dense
                                        type="number"
                                        hide-spin-buttons
                                        :rules="brojRules"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="vrijednost.um_n"
                                        label="Umanjena N komponenta"
                                        required
                                        dense
                                        outlined
                                        maxlength="3"
                                        :rules="brojRules"
                                        hint="Unos je u postotku %"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="prinos.min"
                                        label="Prinos minimum"
                                        required
                                        dense
                                        outlined
                                        :rules="prinosRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="prinos.max"
                                        label="Prinos maksimum"
                                        required
                                        dense
                                        outlined
                                        :rules="prinosRules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="dialogUredi = false"
                            >
                                Odustani
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="azuriraj(form.id)"
                            >
                                Ažuriraj
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <!-- Dialog obrisi-->
            <v-dialog v-model="dialogObrisi" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Potvrdite brisanje!</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogObrisi = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="obrisi(obrisiId)"
                        >
                            Obriši
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import PredKultura from "./PredKultura.vue";
export default {
    components: {
        "pred-kultura": PredKultura,
    },
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        loading: false,
        headers: [
            {
                text: "Naziv kulture",
                align: "start",
                sortable: false,
                value: "naziv",
            },
            { text: "Uredi", value: "actions", sortable: false, width: "5%" },
        ],
        unosRules: [(v) => !!v || "Unos je potreban"],
        brojRules: [
            (v) => !!v || "Unesite vrijednost",
            (v) => (v >= 0 && v < 100) || "Unesite broj između 0 i 100",
        ],
        prinosRules: [
            (v) => !!v || "Unesite vrijednost",
            (v) =>
                /^\d{1,2}(\.\d{1,2})?$/.test(v) ||
                "Broj mora biti u formatu ##.##",
        ],
        dataItems: [],
        obrisiId: "",
        form: {},
        vrijednost: {},
        prinos: {},
        dialogNovo: false,
        dialogUredi: false,
        dialogObrisi: false,
    }),

    created() {
        this.ucitaj();
    },

    methods: {
        dodajDialog() {
            this.form.id = "";
            this.form.naziv = "";
            this.dialogNovo = true;
            this.vrijednost = {};
            this.prinos = {};
        },
        urediDialog(item) {
            this.dialogUredi = true;
            this.form.id = item.id;
            this.form.naziv = item.naziv;
            this.form.vrijednost = item.vrijednost;
            this.citajVrijednost(item.vrijednost);
            this.citajPrinos(item.prinos);
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        ucitaj() {
            this.$Api
                .getData("kultura")
                .then((response) => {
                    this.dataItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        dodaj() {
            if (this.$refs.form.validate()) {
                this.dodijeliVrijednost();
                this.dodijeliPrinos();
                this.$Api
                    .postData("kultura", this.form)
                    .then((response) => {
                        this.dialogNovo = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
            // this.resetforme();
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.dodijeliVrijednost();
                this.dodijeliPrinos();
                this.$Api
                    .updateData("kultura", id, this.form)
                    .then((response) => {
                        this.dialogUredi = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        obrisi(id) {
            this.$Api
                .delete("kultura", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        //Razlaze string iz baze na vrijednosti
        citajVrijednost(vrijednostString) {
            this.vrijednost = JSON.parse(vrijednostString);
        },
        dodijeliVrijednost() {
            this.form.vrijednost = JSON.stringify(this.vrijednost);
        },

        citajPrinos(prinosString) {
            this.prinos = JSON.parse(prinosString);
        },
        dodijeliPrinos() {
            this.form.prinos = JSON.stringify(this.prinos);
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
