<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.name"
                            :counter="40"
                            :rules="imeRules"
                            label="Ime i Prezime"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            dense
                        ></v-text-field>

                        <v-autocomplete
                            v-model="form.mjesto_id"
                            :items="mjesta"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Mjesto"
                            prepend-icon="mdi-database-search"
                            outlined
                            dense
                            :clearable="true"
                        >
                        </v-autocomplete>

                        <v-text-field
                            v-model="form.ulica"
                            :rules="ulicaRules"
                            label="Ulica"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.kucni_broj"
                            label="Kućni broj"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.telefon"
                            :rules="telefonRules"
                            label="Telefon"
                            prepend-icon="mdi-database-search"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.mobitel"
                            :rules="telefonRules"
                            label="Mobitel"
                            prepend-icon="mdi-database-search"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.email"
                            :rules="emailRules"
                            label="E-mail"
                            prepend-icon="mdi-database-search"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.web"
                            label="Web stranica"
                            prepend-icon="mdi-database-search"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="submitForm">
                                Ažuriraj podatke
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                        <h3 class="mt-4">Promijena zaporke</h3>
                    </v-col>
                </v-row>

                <v-form
                    ref="formLozinka"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="submit"
                >
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="formLozinka.password"
                                :rules="unosRules"
                                label="Stara zaporka"
                                prepend-icon="mdi-database-search"
                                required
                                outlined
                                clearable
                                dense
                                :error-messages="errorLozinka"
                            >
                            </v-text-field>
                            <div class="text-left">
                                <v-btn
                                    color="primary"
                                    class=""
                                    @click="promjeniLozinku"
                                >
                                    Promijeni zaporku
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="formLozinka.noviPassword"
                                :rules="lozinkaRules"
                                label="Nova zaporka"
                                prepend-icon="mdi-database-search"
                                required
                                outlined
                                clearable
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="formLozinka.noviPasswordPonovi"
                                :rules="lozinkaPonovoRules"
                                label="Ponovi novu zaporku"
                                prepend-icon="mdi-database-search"
                                required
                                outlined
                                clearable
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            mjesta: [],
            selectedItem: null,
            naziv: "",
            isLoading: false,
            search: null,
            responseData: "",
            valid: true,
            //ID  profila
            id: null,
            imeRules: [(v) => !!v || "Ime i prezime je potrebno"],
            telefonRules: [
                // (v) => !!v || "Unesite telefonski broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            emailRules: [
                (v) => !!v || "E-mail adresa potrebna",
                (v) => /.+@.+\..+/.test(v) || "E-mail adresa nije valjana",
            ],
            unosRules: [(v) => !!v || "Unos je potreban"],
            ulicaRules: [(v) => !!v || "Unos je potreban"],

            lozinkaRules: [
                (v) => !!v || "Zaporka potrebna",
                // (v) =>
                //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/.test(
                //         v
                //     ) || "Zaporka ne zadovoljava uvjete",
            ],

            errorLozinka: "", //Varijabla greske poruke lozinke
            lozinkaPonovoRules: [
                (v) => !!v || "Zaporka potrebna",
                // (v) =>
                //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/.test(
                //         v
                //     ) || "Zaporka ne zadovoljava uvjete",
                // (v) =>
                //     v == this.formLozinka.noviPassword ||
                //     "Ponovljena zaporka nije jednaka",
            ],

            select: null,
            checkbox: false,
            //Podaci iz forme
            form: {},
            //Podaci za promjenu lozinke
            formLozinka: {},
        };
    },

    methods: {
        submitForm() {
            if (this.validate() == true) {
                axios
                    .put("/api/korisnici/" + this.id, this.form)
                    .then((res) => {
                        //Perform Success Action
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno ažuriranje"
                        );
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            error.response.data.errors.email[0]
                        );
                    })
                    .finally(() => {
                        //Perform action in always
                    });
            }
        },

        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
            } catch (error) {}
        },
        ucitajKorisnika($id) {
            axios
                .get(this.$store.getters.getApiUrl + "/korisnici/" + $id)
                .then((response) => {
                    this.form = response.data;
                })
                .catch((error) => {
                    pokaziPoruku(error.response.data.error);
                });
        },
        promjeniLozinku() {
            if (this.validateLozinka()) {
                if (this.usporediLozinku(this.formLozinka, this.id)) {
                    this.$Api
                        .zamjeniLozinku(this.formLozinka, this.id)
                        .then((response) => {
                            this.errorLozinka = "";
                            this.pokaziPoruku(
                                "poruke",
                                "success",
                                response.data.message
                            );
                            this.resetFormlozinka();
                        })
                        .catch((err) => {
                            this.errorLozinka = err.response.data.message;
                            this.pokaziPoruku(
                                "poruke",
                                "error",
                                err.response.data.message
                            );
                        });
                } else {
                    // console.log("NIJE OK");
                }
            }
        },
        async usporediLozinku(lozinka, id) {
            let rezultat = false;

            await this.$Api
                .usporediLozinku(lozinka, id)
                .then((response) => {
                    this.errorLozinka = "";
                    rezultat = response.data.status;
                })
                .catch((err) => {
                    this.errorLozinka = err.response.data.message;
                    rezultat = err.response.data.status;
                });

            return rezultat;
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        validateLozinka() {
            if (this.$refs.formLozinka.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetFormlozinka() {
            this.$refs.formLozinka.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    created() {
        this.id = this.$store.state.user.id;
        this.ucitajMjesta();
        this.ucitajKorisnika(this.id);
    },
    watch: {},
};
</script>

<style></style>
