<template>
    <div>
        <v-app id="appMain">
            <div v-if="this.$store.getters.isLoggedIn == false">
                <div v-if="this.$router.currentRoute.name == 'analiza-pregled'">
                    <gost-analiza-component></gost-analiza-component>
                </div>

                <login-stranica v-else></login-stranica>
            </div>
            <div v-else>
                <navigation></navigation>
                <v-main>
                    <!-- Komponenta poruka -->
                    <notifications
                        group="poruke"
                        position="top center"
                        width="25%"
                        classes="poruke"
                        :duration="1000"
                    >
                        <template slot="body" slot-scope="{ item, close }">
                            <v-card elevation="5" class="porukeCard">
                                <div class="vue-notification-wrapper">
                                    <div class="poruke">
                                        <div :class="item.type">
                                            <div class="notification-title">
                                                {{ item.title }}
                                            </div>
                                            <div class="notification-content">
                                                <div v-html="item.text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </template>
                    </notifications>

                    <v-container fluid>
                        <!-- vue-router -->
                        <v-row>
                            <v-col cols="12">
                                <v-card elevation="4">
                                    <v-spacer></v-spacer>

                                    <div class="v-card-gradient">
                                        <v-card-title class="justify-end">
                                            {{ naslov }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="pt-1 mb-4 text-right"
                                        >
                                            {{ opis }}
                                        </v-card-subtitle>
                                    </div>
                                    <v-card-text>
                                        <loader-stranica
                                            :state="isLoading"
                                        ></loader-stranica>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-slide-x-transition
                                                    mode="out-in"
                                                    :duration="{
                                                        enter: 100,
                                                        leave: 500,
                                                    }"
                                                >
                                                    <router-view> </router-view>
                                                </v-slide-x-transition>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-main>
                <!-- <footer-komp> </footer-komp> -->
            </div>
        </v-app>
    </div>
</template>

<script>
import Login from "./Login.vue";
import GostAnaliza from "./pages/analiza/AnalizaVanjskiPregled.vue";
export default {
    components: {
        "login-stranica": Login,
        "gost-analiza-component": GostAnaliza,
    },
    data: () => ({
        //Naslov i opis stranice iz routera
        klasaNotifications: "poruke",
        naslov: "",
        opis: "",
        drawer: null,
        isLoggedIn: 0,
        isLoading: false,
    }),
    watch: {
        //Dohvat naslova i opisa iz router meta
        $route(to) {
            this.naslov = to.meta.naslov || "Agrosys";
            this.opis = to.meta.opis || "Agrosys";
        },
        isLoggedIn(novo, staro) {
            if (novo) {
            }
        },
    },
    async created() {
        this.isLoading = true;
    },
    methods: {},
    mounted() {
        this.naslov = this.$route.meta.naslov || "Agrosys";
        this.opis = this.$route.meta.opis || "Agrosys";

        this.isLoading = false;
    },
};
</script>
<style scoped>
.agroCard {
    padding: 0 !important;
}
</style>
