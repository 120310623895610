<template>
    <v-card
        class="mx-auto text-center"
        color="purple lighten-3"
        dark
        max-width="600"
    >
        <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                    :value="value"
                    color="rgba(255, 255, 255, .7)"
                    height="100"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                    :labels="labels"
                >
                </v-sparkline>
            </v-sheet>
        </v-card-text>

        <v-card-text class="pa-1">
            <div class="text-h5 font-weight-thin">Naši klijenti</div>
        </v-card-text>

        <v-card-actions class="justify-center">
            <v-btn block text> Izvještaj </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: { propData: Array },
    data: () => ({
        labels: ["S", "V", "O", "T", "S", "L", "S", "K", "R", "L", "S", "P"],
        value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        gospodarstva: null,
    }),
    computed: {},
    methods: {
        ucitajUzorke() {
            this.$Api.getData("gospodarstva").then((response) => {
                this.gospodarstva = response.data;
                this.izracunPodataka();
            });
        },
        izracunPodataka() {
            this.gospodarstva.forEach((item) => {
                let datum = new Date(item.created_at);
                let mjesecIndex = datum.getMonth();
                for (let i = 0; i <= 11; i++) {
                    if (mjesecIndex == i) {
                        let broj = this.value[i] + 1;
                        this.value.splice(i, 1, broj);
                    }
                }
            });
        },
    },
    mounted() {
        this.ucitajUzorke();
    },
};
</script>

<style></style>
