<template>
    <div>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Pregled računa</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h4>Korisnik</h4>
                                <b>Naziv:</b>
                                {{ analiza.gospodarstvo_naziv }} <br />
                                <b>OIB:</b>
                                {{ analiza.gospodarstvo_oib }} <br />
                                <b>Mjesto:</b>
                                {{ analiza.gospodarstvo_mjesto_naziv }} <br />
                            </v-col>
                            <v-col>
                                <h4>Uzorak</h4>
                                <b>Broj:</b>
                                {{ analiza.uzorak_broj }} <br />
                                <b>Datum:</b>
                                {{ formatDate(analiza.uzorak_datum) }} <br />
                                <b>Dubina:</b>
                                {{ analiza.uzorak_dubina }} <br />
                            </v-col>
                            <v-col>
                                <h4>Analiza</h4>
                                <b>Broj:</b>
                                {{ analiza.analiza_broj }} <br />
                                <b>Datum:</b>
                                {{ formatDate(analiza.analiza_datum) }} <br />
                                <b>Parcela:</b>
                                {{ analiza.parcela_naziv }} <br />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.racun_naziv"
                            :counter="40"
                            :rules="nazivRules"
                            label="Naziv računa"
                            required
                            outlined
                            dense
                        ></v-text-field>

                        <v-menu
                            v-model="izbornikDatum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formatedDate"
                                    label="Datum"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    required
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datum"
                                @input="izbornikDatum = false"
                                locale="hr-HR"
                            ></v-date-picker>
                        </v-menu>

                        <vuetify-money
                            v-model="form.racun_iznos"
                            label="Iznos računa"
                            :outlined="true"
                            :clearable="false"
                            :options="options"
                            :properties="properties"
                        />

                        <v-switch
                            v-model="form.racun_status"
                            label="Status Računa (plaćen/neplaćen)"
                            color="success"
                            :false-value="0"
                            :true-value="1"
                        >
                        </v-switch>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-form
                            ref="uploadForm"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-file-input
                                accept=".pdf"
                                label="Dokument računa"
                                outlined
                                dense
                                @change="selectImage"
                                prepend-icon=""
                                append-icon="mdi-file-document"
                                :rules="racunRules"
                                ref="uploadRacunDokument"
                                v-model="odabraniDokument"
                                hint="Makismalna veličina datoteke 3MB"
                                :persistent-hint="true"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip small label color="primary">
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-form>
                        <v-list dense>
                            <v-subheader>PRILOŽENA DATOTEKA</v-subheader>

                            <v-list-item class="grey lighten-3">
                                <v-list-item-avatar>
                                    <v-icon dark color="blue">
                                        mdi-file-pdf-box
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Priloženi račun
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="brisiDokument">
                                        <v-icon>mdi-close-thick</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                        <v-btn class="ma-2" outlined :href="downloadLink">
                            Preuzmi priloženu datoteku
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-right mb-5">
                            <v-btn
                                color="primary"
                                class=""
                                @click="azurirajRacun"
                            >
                                Spremi promjene
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { throwStatement } from "@babel/types";
import uploadDokument from "../../api/upload";
export default {
    data: () => ({
        valid: false,
        racunId: null,
        //Objekt forma u koju se sprema dohvat podataka
        form: { racun_status: 0, racun_iznos: 0, racun_file: "" }, //Postavljen default u 0
        analiza: {},
        odabraniDokument: null,
        izbornikDatum: false,
        datotekaRacun: null,
        downloadLink: null,
        datum: null,
        formatedDate: "",
        options: {
            locale: "hr-HR",
            prefix: "€",
            suffix: "",
            length: 11,
            precision: 2,
        },
        properties: {
            dense: true,
            clearable: false,
            hint: "Unosite iznos bez točaka i zareza",
            // You can add other v-text-field properties, here.
        },
        nazivRules: [(v) => !!v || "Naziv je potreban"],
        racunRules: [
            // (value) => !!value || "Unos je potreban",
            (value) =>
                !value ||
                value.size < 3000000 ||
                "Dokument bi trebao biti ispod 3 MB!",
        ],
    }),
    methods: {
        ucitajRacun(id) {
            this.$Api
                .getByID("racuni", id)
                .then((response) => {
                    this.form = response.data;
                    this.formatedDate = this.formatDate(this.form.racun_datum);
                    this.ucitajAnalizu(this.form.racun_analiza_id);
                    this.krerirajDownloadLink(this.form.racun_file);
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajAnalizu(id) {
            this.$Api
                .getByID("analize", id)
                .then((response) => {
                    this.analiza = response.data;
                    // this.formatedDate = this.formatDate(
                    //     this.form.analiza_datum
                    // );
                    this.form.racun_gospodarstvo_id =
                        this.analiza.gospodarstvo_id;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        async azurirajRacun() {
            if (!this.$refs.uploadForm.validate()) {
                return 0;
            }

            if (this.odabraniDokument)
                await this.uploadDok(this.odabraniDokument);

            this.$Api
                .updateData("racuni", this.racunId, this.form)
                .then((response) => {
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        brisiDokument() {
            console.log("BRISANJE");
        },
        nazivRacuna() {
            this.form.racun_naziv = "Račun " + this.analiza.analiza_broj;
        },
        selectImage(image) {
            let file = image;
            if (!file || file.type.indexOf("image/") !== 0) return;

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    this.logoWidth = img.width;
                    this.logoHeight = img.height;
                };
                img.src = evt.target.result;
            };

            reader.onerror = (evt) => {
                console.error(evt);
            };
            // Dokument bi trebao biti maksimalno 300px u širini
            this.odabraniDokument = image;
        },
        async uploadDok(file) {
            // if (this.validateUpload() == true) {
            // }
            await uploadDokument
                .uploadDokumenta(file, this.form.racun_naziv)
                .then((response) => {
                    this.form.racun_file = response.data.url;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err.response);
                });
        },
        krerirajDownloadLink(racunName) {
            this.downloadLink =
                this.$store.state.apiUrl + "/file/download/" + racunName;
        },
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        postaviDatumForma() {
            this.form.racun_datum = new Date()
                .toJSON()
                .slice(0, 10)
                .replace(/-/g, "/");
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + "." + mm + "." + yyyy;

            return today;
        },
        validateRacun() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.form.racun_datum = this.formatISODate(val);
        },
    },
    created() {
        this.formatedDate = this.todayDate();
        this.racunId = this.$route.params.id;
        this.ucitajRacun(this.$route.params.id);
        this.postaviDatumForma();
    },
};
</script>

<style scoped>
.v-input__append-outer .gumb-prilozi {
    margin-top: -6px !important;
}
</style>
