<template>
    <div>
        <div>
            <v-data-table
                :headers="racuniHeaders"
                :items="dataItems"
                key="id"
                sort-by="name"
                :search="search"
                no-results-text="Nema pronađenih"
                no-data-text="Nema podataka"
                fixed-header
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                :loading="loading"
                @page-count="pageCount = $event"
                group-by="racun_gospodarstvo_id"
            >
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th colspan="8" class="teal lighten-4">
                        <v-row>
                            <v-col cols="3">
                                <v-icon @click="toggle" class="mr-0">
                                    {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                                </v-icon>
                                <span class="">{{
                                    items[0].racun_gospodarstvo_naziv
                                }}</span>
                            </v-col>
                            <v-col>
                                <h4>
                                    OIB: {{ items[0].racun_gospodarstvo_oib }}
                                </h4>

                                <h4>
                                    MIBPG:
                                    {{
                                        items[0].racun_gospodarstvo_maticni_broj
                                    }}
                                </h4>
                            </v-col>
                        </v-row>
                    </th>
                </template>
                <template v-slot:top>
                    <v-toolbar flat class="grey lighten-4">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pretraga"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <!-- <v-btn
                                        color="primary"
                                        dark
                                        @click.stop="dodajDialog()"
                                    >
                                        Dodaj +
                                    </v-btn> -->
                                </span>
                            </template>
                            <span>Dodaj novu kalkulaciju</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.racun_naziv`]="{ item }">
                    <v-icon class="mr-3">mdi-receipt-text</v-icon>
                    <router-link
                        :to="{
                            name: 'racun-single',
                            params: {
                                id: item.racun_id,
                            },
                        }"
                        class="routerLink"
                    >
                        {{ item.racun_naziv }}
                    </router-link>
                </template>
                <template v-slot:[`item.racun_status`]="{ item }">
                    <v-avatar
                        v-if="item.racun_status"
                        color="green lighten-1"
                        size="14"
                    >
                    </v-avatar>
                    <v-avatar v-else color="red lighten-1" size="14">
                    </v-avatar>
                </template>
                <template v-slot:[`item.racun_datum`]="{ item }">
                    <span> {{ formatDate(item.racun_datum) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <router-link
                                    :to="{
                                        name: 'nova-preporuka',
                                        params: {
                                            kalkulacija_id: item.kalkulacija_id,
                                        },
                                    }"
                                    class="routerLink"
                                >
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="blue lighten-2"
                                    >
                                        mdi-file-document
                                    </v-icon>
                                </router-link>
                            </span>
                        </template>
                        <span>Izradi preporuku</span>
                    </v-tooltip> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    @click="dialogRacunPlacen(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="purple lighten-2"
                                >
                                    mdi-credit-card-outline
                                </v-icon>
                            </span>
                        </template>
                        <span>Označi kao plaćeno</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    @click="brisiDialog(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red lighten-2"
                                >
                                    mdi-delete
                                </v-icon>
                            </span>
                        </template>
                        <span>Obriši</span>
                    </v-tooltip>
                </template>

                <!-- Filter slot -->
                <template v-slot:header="{ props: { headers } }">
                    <th colspan="11">
                        <v-expansion-panels accordion tile focusable>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    class="blue lighten-4"
                                >
                                    <v-row no-gutters align="center">
                                        <v-col cols="2" class="d-flex">
                                            <div>
                                                <v-icon>mdi-filter</v-icon>
                                            </div>
                                            <div class="mt-1">
                                                Filtrirajte podatke
                                            </div>
                                        </v-col>
                                        <v-col cols="8" class="d-flex">
                                            <!-- <div
                                                v-if="godinaFilter"
                                                class="ml-4"
                                            >
                                                <span
                                                    class="text--secondary font-weight-light"
                                                    color="primary"
                                                >
                                                    Godina analize
                                                </span>
                                                <h5 class="text--secondary">
                                                    {{ godinaFilter }}
                                                </h5>
                                            </div> -->

                                            <div
                                                v-if="operaterFilter"
                                                class="ml-4"
                                            >
                                                <span
                                                    class="text--secondary font-weight-light"
                                                >
                                                    Operater
                                                </span>
                                                <h5 class="text--secondary">
                                                    {{ operaterFilter }}
                                                </h5>
                                            </div>
                                            <div
                                                v-if="datumFilter"
                                                class="ml-4"
                                            >
                                                <span
                                                    class="text--secondary font-weight-light"
                                                >
                                                    Datum kalkullacije
                                                </span>
                                                <h5 class="text--secondary">
                                                    {{ formatedDate }}
                                                </h5>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="text-center">
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="mt-1">
                                        <!-- <v-col>
                                        <v-select
                                            :items="godineLista()"
                                            label="Izbor godine"
                                            v-model="godinaFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-select
                                    ></v-col> -->
                                        <v-col>
                                            <v-autocomplete
                                                :items="operatori"
                                                label="Izbor operatora"
                                                item-text="name"
                                                item-value="name"
                                                v-model="operaterFilter"
                                                clearable
                                                dense
                                                hide-details
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-menu
                                                v-model="izbornikDatum"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="formatedDate"
                                                        label="Datum"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        required
                                                        clearable
                                                        dense
                                                        @click:clear="
                                                            ocistiDatumFilter
                                                        "
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="datumFilter"
                                                    @input="
                                                        izbornikDatum = false
                                                    "
                                                    locale="hr-HR"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </th>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    test
                </template>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="4"
                        circle
                    ></v-pagination>
                </div>
            </v-data-table>
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
            <v-card></v-card>
            <!-- Dialog racun placen-->
            <v-dialog
                v-model="dialogRacunPlacenStatus"
                persistent
                max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h6"
                            >Potvrdite promjenu statusa računa!</span
                        >
                    </v-card-title>
                    <v-card-text>
                        Klikom na gumb potvrdi postavljate status računa u
                        plačen.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogRacunPlacenStatus = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="oznaciRacunPlacen()"
                        >
                            Potvrdi
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog obrisi-->
            <v-dialog v-model="dialogObrisi" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Potvrdite brisanje!</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogObrisi = false"
                        >
                            Odustani
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="obrisi(obrisiId)"
                        >
                            Obriši
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        idealniNPK: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        valid: true,
        izbornikDatum: null,
        formatedDate: null,
        loading: false,
        datumFilter: null,
        operaterFilter: null,
        unosRules: [(v) => !!v || "Unos je potreban"],
        dataItems: [], //Ucitava elemente
        operatori: [],
        obrisiId: null,
        racunId: null,
        form: {}, //Objekt za uredjivanje dodavanje spremanje
        dialogNovo: false,
        dialogUredi: false,
        dialogObrisi: false,
        dialogRacunPlacenStatus: false,
    }),
    watch: {
        datumFilter(novo, staro) {
            this.formatedDate = this.formatDate(novo);
            // this.dataItemsKoristeniFilteri.datumFilter = this.formatedDate;
        },
    },
    computed: {
        racuniHeaders() {
            return [
                {
                    text: "Naziv",
                    align: "start",
                    sortable: false,
                    value: "racun_naziv",
                },
                {
                    text: "Analiza",
                    align: "start",
                    sortable: false,
                    value: "analiza_naziv",
                },
                {
                    text: "Uzorak",
                    align: "start",
                    sortable: false,
                    value: "uzorak_naziv",
                },
                // {
                //     text: "Parcela",
                //     align: "start",
                //     sortable: false,
                //     value: "parcela_naziv",
                // },
                {
                    text: "Iznos €",
                    align: "start",
                    sortable: false,
                    value: "racun_iznos",
                },
                {
                    text: "Datum računa",
                    align: "start",
                    sortable: false,
                    value: "racun_datum",
                    filter: this.datumiFilter,
                },
                {
                    text: "Račun plaćen",
                    align: "start",
                    sortable: false,
                    value: "racun_status",
                },
                {
                    text: "Operater",
                    align: "start",
                    sortable: false,
                    filter: this.operateriFilter,
                    value: "racun_operater_ime",
                },
                {
                    text: "Uredi",
                    value: "actions",
                    sortable: false,
                    width: "8%",
                },
            ];
        },
    },
    methods: {
        ucitajRacune() {
            this.$Api
                .getData("racuni")
                .then((response) => {
                    this.dataItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajOperatore() {
            this.$Api
                .getData("korisnici")
                .then((response) => {
                    this.operatori = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        dialogRacunPlacen(racun) {
            this.dialogRacunPlacenStatus = true;
            this.racunId = racun.racun_id;
        },
        oznaciRacunPlacen() {
            this.$Api
                .setRacunPlacenStatus(this.racunId)
                .then((response) => {
                    this.dialogRacunPlacenStatus = false;
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                    this.ucitajRacune();
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ocistiDatumFilter() {
            this.datumFilter = "";
        },
        datumiFilter(value) {
            if (!this.datumFilter) {
                return true;
            }
            return value === this.datumFilter;
        },
        operateriFilter(value) {
            if (!this.operaterFilter) {
                return true;
            }
            return value === this.operaterFilter;
        },
        obrisi(id) {
            this.$Api
                .delete("racuni", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitajRacune();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        brisiDialog(item) {
            this.dialogObrisi = true;
            this.obrisiId = item.racun_id;
        },
        izradiPreporuku(item) {
            this.$router.push({
                name: "nova-preporuka",
                params: { kalulacija: "1" },
            });
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
    },

    created() {
        this.ucitajRacune();
        this.ucitajOperatore();
    },
};
</script>

<style></style>
