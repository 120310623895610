<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div>
                    <v-img
                        class="mx-auto mt-5"
                        lazy-src="./images/logo/bss-logo-dark.png"
                        max-width="121"
                        src="./images/logo/bss-logo-dark.png"
                    ></v-img>
                </div>

                <h1 class="text-center mt-4 pa-5">
                    Nemate prava pristupa željenoj stranici!
                </h1>

                <p class="text-center">
                    Obratite se korisničkoj službi Agrosys sustava. Zahvaljujemo
                    na razumijevanju.
                </p>
                <div class="text-center mt-4 mb-5"></div>
                <p class="text--disabled text-center">BSSolutions 2022</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
