<template>
    <div>
        <v-row>
            <v-col>
                <v-list-item two-line class="naslov">
                    <v-list-item-content>
                        <v-list-item-title>
                            REZULTAT AGROKEMIJSKE ANALIZE TLA</v-list-item-title
                        >
                        <v-list-item-subtitle
                            >za uzorak
                            <span class="naglaseno">{{
                                analiza.uzorak_broj
                            }}</span>

                            na parceli
                            <span class="naglaseno"
                                >{{ analiza.parcela_naziv }}
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
            <v-col cols="3" class="d-flex justify-end align-right"> </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col>
                        <b> Korisnik: </b>
                        {{ analiza.gospodarstvo_naziv }} <br />
                        <b> OIB: </b>
                        {{ analiza.gospodarstvo_oib }} <br />
                        <b> MIBPG: </b>
                        {{ analiza.gospodarstvo_maticni_broj }} <br />
                        <b> Odgovorna osoba: </b>
                        {{ analiza.gospodarstvo_ime }}<br />
                        <b> Adresa: </b>
                        {{ analiza.gospodarstvo_ulica }}
                        {{ analiza.gospodarstvo_kucni_broj }},
                        {{ analiza.gospodarstvo_mjesto_naziv }}<br />
                        <span v-if="analiza.gospodarstvo_telefon">
                            {{ analiza.gospodarstvo_telefon }}<br
                        /></span>
                        <b> Kontakt: </b>
                        {{ analiza.gospodarstvo_mobitel }}<br />
                        {{ analiza.gospodarstvo_email }}<br />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <div>
                    <qrcode-vue
                        :value="qrcode.value"
                        :size="qrcode.size"
                        render-as="svg"
                        level="H"
                        class="qr-code"
                    />
                </div>
            </v-col>
        </v-row>
        <h3>Detaljnije informacije</h3>
        <table class="osnovnoTablica">
            <tr>
                <td>
                    <b>Analiza broj:</b>

                    {{ this.analiza.analiza_broj }}
                </td>
                <td class="stupacDesno">
                    <b> Klijent: </b>
                    {{ this.analiza.gospodarstvo_naziv }}
                    <v-spacer></v-spacer>
                    <b> Parcela: </b>
                    {{ this.analiza.parcela_naziv }}
                    <v-spacer></v-spacer>
                    <b> ARKOD čestice: </b>
                    {{ this.analiza.parcela_arkod }}
                    <v-spacer></v-spacer>
                    <b> Katastarska čestica: </b>
                    {{ this.analiza.parcela_kat_cestica }}
                </td>
                <td class="stupacDesno">
                    <b> Naziv uzorka:</b>
                    {{ this.analiza.uzorak_naziv }}
                    <v-spacer></v-spacer>
                    <b> Broj uzorka: </b>
                    {{ this.analiza.uzorak_broj }}
                    <v-spacer></v-spacer>
                    <b> Dubina uzorkovanja: </b>
                    {{ this.analiza.uzorak_dubina }} cm
                    <v-spacer></v-spacer>
                    <b> Datum uzorkovanja: </b>
                    {{ this.formatDate(this.analiza.uzorak_datum) }}
                </td>
            </tr>
        </table>
        <br />
        <br />
        <v-row>
            <v-col cols="12">
                <h4 style="margin-bottom: 7px">
                    Rezultat agrokemijske analize
                </h4>
                <table class="osnovnoTablica">
                    <thead>
                        <tr>
                            <th class="text-left">pH-KCI</th>
                            <th class="text-left">pH-H<sub>2</sub>O</th>
                            <th class="text-left">
                                AL-P<sub>2</sub>O<sub
                                    >5 <br />
                                    ( mg/100g tla)</sub
                                >
                            </th>
                            <th class="text-left">
                                AL-K<sub>2</sub>O <br />( mg/100g tla)
                            </th>
                            <th class="text-left">Humus %</th>
                            <th class="text-left">CaCO<sub>3</sub> %</th>
                            <th class="text-left">
                                Hk cmol<sup> (+)</sup>kg
                                <sup>-1</sup>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{ analiza.analiza_phkci }}
                            </td>
                            <td>
                                {{ analiza.analiza_phh2o }}
                            </td>
                            <td>
                                {{ analiza.analiza_alp2o5 }}
                            </td>

                            <td>
                                {{ analiza.analiza_alpk2o }}
                            </td>
                            <td>
                                {{ analiza.analiza_humus }}
                            </td>
                            <td>
                                {{ analiza.analiza_caco3 }}
                            </td>
                            <td>
                                {{ analiza.analiza_hkcmol }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="10">
                <br />

                Procjena mehaničkog sastava tla : {{ analiza.tlo_tip_naziv }}
            </v-col>
            <v-col cols="2"> </v-col>
        </v-row>
        <div class="verticalSpacer"></div>

        <v-row>
            <v-col cols="8"> </v-col>
            <v-col cols="4">
                <br />
                <br />

                Odgovorna osoba:
            </v-col>
        </v-row>
        <br />
        <br />
        <div class="napomena text-left">
            Dokument je obrađen elektroničkim putem te je kao takav valjan bez
            potpisa i pečata.
        </div>

        <div class="verticalSpacer"></div>
    </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
    components: {
        QrcodeVue,
    },
    props: ["analiza"],
    data() {
        return {
            qrcode: {
                value: "",
                size: 100,
                class: "qrcode",
            },
        };
    },
    watch: {
        //Potrebno nadgledati promjenu AnalizaId poradi refresha qr koda
        "analiza.analiza_id"() {
            this.generirajQrCode();
        },
    },

    methods: {
        generirajQrCode() {
            if (this.analiza.analiza_signature_link == null) {
                this.qrcode.value = process.env.MIX_APP_URL;
                return false;
            }

            this.qrcode.value =
                process.env.MIX_APP_URL +
                "/analiza-pregled?analizaId=" +
                this.analiza.analiza_id +
                "&signature=" +
                this.analiza.analiza_signature_link;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
    },
    created() {
        // this.ucitajMjesta();
        // this.dohvatiImeMjesta();
    },
    mounted() {
        this.generirajQrCode();
    },
};
</script>

<style scoped>
.naslov {
    text-align: center;
    margin-top: 15px;
    font-family: "Roboto";
}
.naglaseno {
    color: #682a2a;
}
.verticalSpacer {
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #682a2a;
}

.osnovnoTablica {
    border: 1px solid;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}
.osnovnoTablica td {
    border: 1px solid;
    text-align: center;
    padding: 4px;
}
.osnovnoTablica td.stupacLijevo {
    text-align: left;
    width: 32%;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}
.osnovnoTablicaTlo {
    padding: 4px;
}
.osnovnoTablicaTlo p {
    padding: 0px;
}
.osnovnoTablicaTlo td.stupacLijevo {
    text-align: left;
    width: 18%;
}
.osnovnoTablicaTlo td.stupacDesno {
    text-align: left;
}
.sadrzajTekst {
    padding: 1.5rem;
}
.qr-code {
    text-align: right;
    width: 100%;
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}
</style>
