import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: {
                    base: "#E91E63",
                    darken1: colors.purple.base,
                },
                background: "#34ff45",
                secondary: colors.grey.lighten1,
                // accent: colors.shades.black,
                accent: colors.indigo.base,
                error: colors.red.accent3,
                success: "#66BB6A",
            },
        },
    },
});
