<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-container>
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.ime"
                            :counter="40"
                            :rules="imeRules"
                            label="Ime i Prezime"
                            prepend-icon="mdi-account"
                            required
                            outlined
                            dense
                        ></v-text-field>

                        <v-autocomplete
                            v-model="form.mjesto_id"
                            :items="mjesta"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Mjesto"
                            prepend-icon="mdi-city"
                            outlined
                            dense
                        >
                        </v-autocomplete>

                        <v-text-field
                            v-model="form.ulica"
                            label="Ulica"
                            prepend-icon="mdi-drag-horizontal-variant"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.kucni_broj"
                            label="Kućni broj"
                            prepend-icon="mdi-numeric"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-select
                            v-model="form.role"
                            :items="uloge"
                            :loading="isLoading"
                            hide-no-data
                            hide-selected
                            item-text="naziv"
                            item-value="id"
                            label="Uloga"
                            prepend-icon="mdi-account-supervisor-circle"
                            outlined
                            required
                            dense
                            :rules="selectRule"
                            :clearable="true"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="form.telefon"
                            label="Telefon"
                            prepend-icon="mdi-phone"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.mobitel"
                            label="Mobitel"
                            prepend-icon="mdi-cellphone"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.email"
                            :rules="emailRules"
                            label="E-mail"
                            prepend-icon="mdi-email"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.web"
                            label="Web stranica"
                            prepend-icon="mdi-web"
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form.password"
                            :rules="unosRules"
                            label="Zaporka"
                            prepend-icon="mdi-lock"
                            required
                            outlined
                            clearable
                            dense
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    style="top: -7px"
                                    @click="generirajlozinku()"
                                >
                                    Generiraj
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn color="primary" class="" @click="odustani()">
                                Odustani
                            </v-btn>
                        </div></v-col
                    >
                    <v-col>
                        <div class="text-right">
                            <v-btn color="primary" class="" @click="submitForm">
                                Spremi
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            mjesta: [],
            uloge: [
                { id: 1, naziv: "Administrator" },
                { id: 2, naziv: "Operator" },
                { id: 3, naziv: "Vanjski suradnik" },
            ],
            selectedItem: null,
            selectedUloga: null,
            isLoading: false,
            search: null,
            responseData: "",
            valid: true,
            //ID glavnog profila
            id: null,

            imeRules: [(v) => !!v || "Ime i prezime je potrebno"],
            telefonRules: [
                (v) => !!v || "Unesite telefonski broj",
                (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
            ],
            emailRules: [
                (v) => !!v || "E-mail je obavezan",
                (v) => /.+@.+\..+/.test(v) || "E-mail adresa nije ispravna",
            ],
            unosRules: [(v) => !!v || "Unos je potreban"],
            selectRule: [(v) => !!v || "Odaberite vrijednost"],
            //Podaci iz forme
            form: {
                ime: "",
                ulica: "",
                mjesto_id: this.selectedItem,
                kucni_broj: "",
                telefon: "",
                mobitel: "",
                email: "",
                web: "",
                logotip: "",
                role: this.selectedUloga,
                password: null,
            },
        };
    },

    methods: {
        odustani() {
            this.$router.push("/korisnici");
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        generirajlozinku() {
            var generatedPassword = "";
            var characters =
                "abcdefghijklmnopqrstuvwxyz01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%&*?";

            for (var i = 0; i < 12; i++) {
                generatedPassword += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            this.form.password = generatedPassword;
        },
        submitForm() {
            if (this.validate()) {
                axios
                    .post(
                        this.$store.getters.getApiUrl +
                            "/korisnici/novi-korisnik",
                        this.form
                    )
                    .then((res) => {
                        //Perform Success Action
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            "Uspješno spremljen novi korisnik!"
                        );
                        if (this.$store.getters.isLoggedIn) {
                            this.reloadKorisnici();
                        }
                        this.reset();
                        this.resetValidation();
                    })
                    .catch((error) => {
                        // error.response.status Check status code

                        this.pokaziPoruku(
                            "poruke",
                            "error",
                            error.response.data.errors
                        );
                    })
                    .finally(() => {});
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        reloadKorisnici() {
            this.$store.dispatch("reloadKorisnici_AC");
        },
    },
    mounted() {
        this.ucitajMjesta();
    },
};
</script>

<style></style>
