<template>
    <div>
        <!-- Tablica gospodarstva -->
        <v-data-table
            ref="datatableGospodarstva"
            :headers="headers"
            :items="gospodarstva"
            key="id"
            sort-by="name"
            :search="search"
            no-results-text="Nema pronađenih"
            no-data-text="Nema podataka"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            @page-count="pageCount = $event"
            single-expand
            show-expand
            item-key="id"
            @item-expanded="ucitajPripadajuceUzorke"
            calculate-widths
            @current-items="getFiltered"
        >
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisFiltriranih"
                                    class="mr-2 ml-2"
                                    color="red lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispis tablice korisnika</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFiltriranih"
                                    class="mr-2"
                                    color="blue lighten-1"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi tablicu korisnika</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download :filename="FilenameXlsx">
                                        <v-icon
                                            @click="izvozExcell"
                                            class="mr-2"
                                            color="green lighten-1"
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </span>
                        </template>
                        <span>Izvoz tablice korisnika</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    :to="{ name: 'novo-gospodarstvo' }"
                                >
                                    Dodaj
                                </v-btn>
                            </span>
                        </template>
                        <span>Dodaj novog klijenta</span>
                    </v-tooltip>
                </v-toolbar>
            </template>
            <template v-slot:[`item.naziv`]="{ item }">
                <router-link
                    :to="{ name: 'gospodarstvo', params: { id: item.id } }"
                    class="routerLink"
                >
                    <v-icon class="mr-3">mdi-barn</v-icon>
                    {{ item.naziv }}
                </router-link>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-avatar
                    v-if="item.status == true"
                    color="green lighten-1"
                    size="14"
                >
                </v-avatar>
                <v-avatar v-else color="red lighten-1" size="14"> </v-avatar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="ispisObrasca(item)"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                color="green lighten-2"
                            >
                                mdi-printer
                            </v-icon>
                        </span>
                    </template>
                    <span>Ispiši obrazac uzorka</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="downloadObrasca(item)"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                color="blue lighten-2"
                            >
                                mdi-download
                            </v-icon>
                        </span>
                    </template>
                    <span>Preuzmi obrazac</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="obrisiDialog(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši obrazac</span>
                </v-tooltip>
            </template>
            <!-- Tablica uzoraka -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-4">
                    <v-data-table
                        :headers="headersPripadajuciUzorci"
                        :items="pripadajuciUzorci"
                        key="id"
                        no-results-text="Nema pronađenih"
                        no-data-text="Nema podataka"
                        fixed-header
                        hide-default-footer
                        single-expand
                        show-expand
                        item-key="uzorak_id"
                        @item-expanded="ucitajPripadajuceAnalize"
                        class="blue lighten-5 ml-5"
                        disable-filtering
                        disable-pagination
                        hide-default-header
                        disable-sort
                        group-by="parcela_id"
                        :search="searchUzorci"
                    >
                        <template v-slot:header="{ props }">
                            <th
                                v-for="head in props.headers"
                                class="header-text-color"
                            >
                                {{ head.text }}
                            </th>
                        </template>

                        <template
                            v-slot:[`group.header`]="{ items, isOpen, toggle }"
                        >
                            <th colspan="9" class="teal lighten-4">
                                <v-row>
                                    <v-col cols="2">
                                        <v-icon @click="toggle" class="mr-0">
                                            {{
                                                isOpen
                                                    ? "mdi-minus"
                                                    : "mdi-plus"
                                            }}
                                        </v-icon>
                                        <span class="">{{
                                            items[0].parcela_naziv
                                        }}</span>
                                    </v-col>
                                    <v-col>
                                        Mjesto parcele:
                                        <span
                                            v-if="
                                                !items[0].parcela_mjesto_naziv
                                            "
                                        >
                                            Nepoznato
                                        </span>
                                        <span
                                            v-else="
                                                !items[0].parcela_mjesto_naziv
                                            "
                                        >
                                            {{ items[0].parcela_mjesto_naziv }}
                                        </span>
                                    </v-col>
                                    <v-col>
                                        Površina (ha):
                                        {{ items[0].parcela_povrsina }}
                                    </v-col>
                                    <v-col>
                                        ARKOD :
                                        {{ items[0].parcela_arkod }}
                                    </v-col>
                                    <v-col>
                                        Katastarska čestica :
                                        {{ items[0].parcela_kat_cestica }}
                                    </v-col>
                                </v-row>
                            </th>
                        </template>
                        <!-- <template v-slot:[`item`]="{ item }">
                            <span v-if="item.uzorak_id == null">
                              Nema uzoraka
                            </span>
                            <span v-else>
                                {{ item.uzorak_naziv }}
                            </span>
                        </template> -->
                        <template v-slot:[`item.uzorak_naziv`]="{ item }">
                            <span v-if="item.uzorak_id == null">
                                <td>Nema uzoraka za odabranu parcelu</td>
                                <td></td>
                            </span>
                            <!-- <router-link
                                :to="{
                                    name: 'uzorci/' + item.uzorak_id,
                                    params: {
                                        id: item.uzorak_id,
                                    },
                                }"
                                class="routerLink"
                            >
                            </router-link> -->
                            {{ item.uzorak_naziv }}
                        </template>
                        <template v-slot:[`item.status_analize`]="{ item }">
                            <v-avatar
                                v-if="item.status_analize"
                                color="green lighten-1"
                                size="14"
                            >
                            </v-avatar>
                            <v-avatar v-else color="red lighten-1" size="14">
                            </v-avatar>
                        </template>

                        <template
                            v-slot:item.data-table-expand="{
                                item,
                                expand,
                                isExpanded,
                            }"
                        >
                            <td v-if="item.uzorak_id" class="text-start">
                                <v-btn
                                    icon
                                    @click="expand(!isExpanded)"
                                    class="v-data-table__expand-icon"
                                    :class="{
                                        'v-data-table__expand-icon--active':
                                            isExpanded,
                                    }"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </td>
                            <td v-else>
                                <v-icon color="orange lighten4"
                                    >mdi-alert-box-outline</v-icon
                                >
                            </td>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-container>
                                    <h3 class="font-weight-light">
                                        Pripadajuće analize
                                    </h3>
                                    <!-- Tablica ANaliza -->
                                    <v-data-table
                                        :headers="headersPripadajuceAnalize"
                                        :items="pripadajuceAnalize"
                                        key="id"
                                        no-results-text="Nema pronađenih"
                                        no-data-text="Nema podataka"
                                        hide-default-footer
                                        hide-default-header
                                        class="gray lighten-5"
                                        dense
                                    >
                                        <!--  Prilagodjeni header pokazuje za svaku stavku   -->
                                        <template
                                            v-slot:[`item`]="{ headers, item }"
                                        >
                                            <tr
                                                style="
                                                    background-color: #e3f2fd !important;
                                                "
                                            >
                                                <td
                                                    :colspan="headers.length"
                                                ></td>
                                            </tr>
                                            <tr class="green lighten-2">
                                                <td v-for="stavka in headers">
                                                    {{ stavka.text }}
                                                </td>
                                            </tr>

                                            <tr class="makni-hover">
                                                <td>
                                                    {{ item.analiza_naziv }}
                                                </td>
                                                <td>{{ item.analiza_broj }}</td>
                                                <td>
                                                    {{
                                                        formatDate(
                                                            item.analiza_datum
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_operator }}
                                                </td>
                                            </tr>
                                            <tr class="makni-hover">
                                                <td
                                                    :colspan="headers.length"
                                                    class="pa-5"
                                                >
                                                    <v-simple-table dense>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    pH-KCI
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    pH-H<sub>2</sub>O
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    AL-P<sub>2</sub>O<sub
                                                                        >5</sub
                                                                    >
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    AL-K<sub>2</sub>O
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    Humus %
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    CaCO<sub>3</sub>%
                                                                </th>
                                                                <th
                                                                    class="text-left"
                                                                >
                                                                    Hk cmol<sup>
                                                                        (+)</sup
                                                                    >kg
                                                                    <sup
                                                                        >-1</sup
                                                                    >
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                class="makni-hover"
                                                            >
                                                                <td>
                                                                    {{
                                                                        item.analiza_phkci
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.analiza_phh2o
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.analiza_alp2o5
                                                                    }}
                                                                </td>

                                                                <td>
                                                                    {{
                                                                        item.analiza_alpk2o
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.analiza_humus
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.analiza_caco3
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.analiza_hkcmol
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-container>
                            </td>
                        </template>

                        <template v-slot:[`item.uzorak_datum`]="{ item }">
                            <span> {{ formatDate(item.uzorak_datum) }}</span>
                        </template>
                        <template
                            v-slot:[`item.parcela_mjesto_naziv`]="{ item }"
                        >
                            <span>
                                <span v-if="!item.parcela_mjesto_naziv">
                                    Nepoznato
                                </span>
                                <span v-else>
                                    {{ item.parcela_mjesto_naziv }}
                                </span>
                            </span>
                        </template>
                    </v-data-table>
                </td>
            </template>
            <div class="text-center">
                <v-pagination
                    v-model="page"
                    :length="4"
                    circle
                    :total-visible="10"
                ></v-pagination>
            </div>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
        <!-- ISPIS Komponenta-->

        <v-dialog v-model="dialogObrisi" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Potvrdite brisanje!</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogObrisi = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="obrisi(obrisiId)">
                        Obriši
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <gospodartsvo-obrazac
                        :gospodarstvo="gospodarstvoIspis"
                    ></gospodartsvo-obrazac>
                </v-container>
            </section>
        </vue-html2pdf>
        <div id="printMe" class="hidden-screen-only">
            <v-container>
                <gospodartsvo-obrazac
                    :gospodarstvo="gospodarstvoIspis"
                    v-if="gospodarstvoIspis"
                ></gospodartsvo-obrazac>
                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- ISPIS FILTRIRANIH GOSPODARSTVA -->
        <div id="printFiltrirano" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <gospodarstvo-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </gospodarstvo-filtrirano>

                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
        <!-- -DOWNLOAD  FILTRIRANIH :paginate-elements-by-height="1400"   -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="true"
            :paginate-elements-by-height="1400"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfFiltrirano"
            :html-to-pdf-options="downloadFilterOptions"
            @hasDownloaded="hasDownloaded($event)"
            @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <gospodarstvo-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </gospodarstvo-filtrirano>

                <!-- <div class="verticalSpacer"></div> -->

                <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
export default {
    components: {
        "obrazac-zaglavlje": ObrazacZaglavlje,
        VueHtml2pdf,
        "gospodartsvo-obrazac": () =>
            import("../obrasci/GospodarstvoObrazac.vue"),
        "gospodarstvo-filtrirano": () =>
            import("../obrasci/GospodarstvoFilterIspis.vue"),
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        search: "",
        searchUzorci: "",
        loading: false,
        appName: process.env,
        headers: [
            {
                text: "Naziv",
                align: "start",
                sortable: false,
                value: "naziv",
                width: "20%",
            },

            { text: "Ime i prezime", value: "ime", sortable: false },
            {
                text: "Mjesto",
                value: "gospodarstvo_mjesto_naziv",
                sortable: false,
            },
            {
                text: "Poštanski broj",
                value: "gospodarstvo_postanski_broj",
                sortable: false,
            },
            { text: "OIB", value: "oib" },
            { text: "MIBPG", value: "maticni_broj", sortable: false },
            { text: "Telefon", value: "telefon", sortable: false },
            { text: "Mobitel", value: "mobitel", sortable: false },
            { text: "Email", value: "email", sortable: false },
            { text: "Web", value: "web", sortable: false },
            { text: "A/N", value: "status" },
            {
                text: "Operacije",
                value: "actions",
                sortable: false,
                width: "10%",
            },
        ],
        headersPripadajuciUzorci: [
            {
                text: "Naziv",
                sortable: false,
                value: "uzorak_naziv",
                width: "20%",
                align: "start",
            },
            {
                text: "Dubina",
                value: "uzorak_dubina",
                sortable: true,
                align: "center",
            },
            { text: "Broj uzorka", value: "uzorak_broj" },
            {
                text: "Datum uzorka",
                value: "uzorak_datum",
                sortable: true,
                width: "10%",
            },

            {
                text: "Status analize",
                value: "status_analize",
                sortable: false,
                align: "center",
            },

            {
                text: "Mjesto parcele",
                value: "parcela_mjesto_naziv",
                sortable: false,
            },
            {
                text: "Parcela",
                value: "parcela_naziv",
                sortable: false,
            },
            {
                text: "Uzorak uzeo",
                value: "operator_ime",
                sortable: false,
            },
        ],
        headersPripadajuceAnalize: [
            {
                text: "Naziv",
                sortable: false,
                value: "analiza_naziv",
                width: "20%",
                align: "start",
            },
            {
                text: "Broj analize",
                sortable: false,
                value: "analiza_broj",
                align: "start",
            },
            {
                text: "Datum analize",
                sortable: false,
                value: "analiza_datum",
                align: "start",
            },
            {
                text: "Operater",
                sortable: false,
                value: "analiza_operator",
                align: "start",
            },
        ],
        htmlToPdfOptions: {
            margin: 0,

            filename: `Gospodartsvo.pdf`,

            image: {
                type: "jpeg",
                quality: 0.98,
            },

            enableLinks: false,

            html2canvas: {
                scale: 1,
                useCORS: true,
            },

            jsPDF: {
                unit: "mm",
                format: "a4",
                orientation: "portrait",
            },
        },
        gospodarstva: [],
        pripadajuciUzorci: [],
        pripadajuceAnalize: [],
        ispisDialog: false,
        dataItemsFlitrirane: [], // Stavke nakon filtera ili pretrage
        dataItemsKoristeniFilteri: {
            datumFilter: null,
            godinaFilter: null,
            operatorFilter: null,
        },
        downloadFilterOptions: {
            filename: "Tablica_korisnika",
            pagebreak: {
                mode: ["avoid-all", "css", "legacy"],
            },
            jsPDF: {
                format: "a4",
                orientation: "landscape",
            },
            // html2canvas: {
            //     useCORS: true,
            //     allowTaint: true,
            //     scrollX: 0,
            //     scrollY: 0,
            // },
        },
        FilenameXlsx: null,
        sheetName: "Analiza",
        sheets: [
            {
                name: "Analiza",
                data: [{}],
            },
        ],
        collection: [{ a: 1, b: 2 }],

        imeDatoteke: "Gospodarstvo",
        dialogObrisi: false,
        obrisiId: null,
        gospodarstvoIspis: null,
        output: null,
    }),
    created() {
        this.ucitaj();
    },

    methods: {
        ucitaj() {
            this.$Api
                .getData("gospodarstva")
                .then((response) => {
                    this.gospodarstva = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajPripadajuceUzorke(item) {
            this.pripadajuciUzorci = [];
            this.$Api
                .getGospodarstvoUzorci(item.item.id)
                .then((response) => {
                    this.pripadajuciUzorci = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajPripadajuceAnalize(item) {
            this.$Api
                .getGospodarstvoAnalize(item.item.uzorak_id)
                .then((response) => {
                    this.pripadajuceAnalize = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        obrisi(id) {
            this.$Api
                .delete("gospodarstvo", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ispisObrasca(item) {
            // this.imeDatoteke = "Gospodarstvo_" + item.naziv + ".pdf";
            // this.gospodarstvoIspis = item;
            // this.generateReport();

            this.gospodarstvoIspis = item;
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };

            setTimeout(() => {
                this.$htmlToPaper("printMe", localOptions);
            }, "500");
        },
        //Dohvat filtriranih stavki
        getFiltered(items) {
            if (this.$refs.datatableGospodarstva) {
                this.dataItemsFlitrirane =
                    this.$refs.datatableGospodarstva.$children[0].filteredItems;
                this.formirajExcell();
            }
        },
        ispisFiltriranih() {
            // this.forceRerender();

            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            // this.analizaIspis = this.dataItemsFlitrirane;
            // this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printFiltrirano", localOptions);
            }, "500");
        },
        //Preuzimanje filtriranih analiza
        downloadFiltriranih() {
            this.overlay = true;
            this.imeDatoteke = "Tablica korisnika";

            setTimeout(() => {
                this.$refs.html2PdfFiltrirano.generatePdf();
            }, "500");
        },
        formirajExcell() {
            this.FilenameXlsx = "Tablica_Korisnika.xlsx";
            this.sheets[0].data = [{}]; //Nuliranje array
            this.dataItemsFlitrirane.forEach((element) => {
                this.sheets[0].data.push({
                    Naziv: element.naziv,
                    "Ime i prezime": element.ime,
                    Ulica: element.ulica,
                    "Kučni broj": element.kucni_broj,
                    "Poštanski broj": element.gospodarstvo_postanski_broj,
                    Mjesto: element.gospodarstvo_mjesto_naziv,
                    OIB: element.oib,
                    MIBPG: element.maticni_broj,
                    Telefon: element.telefon,
                    Mobitel: element.mobitel,
                    "E-mail": element.email,
                    Web: element.web,
                    Status: this.dohvatiStatusKorisnika(element.status),
                });
            });
        },
        //Nista ne radi samo pokazuje cursor, download ide iz komponente xlsx
        izvozExcell() {
            return false;
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },

        async beforeDownload({ html2pdf, options, pdfContent }) {
            html2pdf().set(options).from(pdfContent).toPdf().save();
        },
        downloadObrasca(item) {
            this.imeDatoteke = "Gospodarstvo_" + item.naziv + ".pdf";
            this.gospodarstvoIspis = item;
            setTimeout(() => {
                this.generateReport();
            }, "500");
        },
        hasDownloaded(e) {
            this.overlay = false;
        },
        onProgress(e) {
            this.printPdfProgress = e;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        dohvatiStatusKorisnika(korisnikStatus) {
            let rezultat;
            rezultat = korisnikStatus == 1 ? "AKTIVAN" : "NEAKTIVAN";
            return rezultat;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
<style scoped>
.selected {
    background-color: red;
}
.header-text-color {
    color: #343333 !important;
    background-color: #a3b3c8 !important;
    font-weight: lighter;
    text-align: left;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: 5px;
}
.makni-hover {
    background-color: transparent !important;
}
</style>
