<template>
    <div>
        <v-overlay :value="overlay" z-index="9999">
            <h2>Preuzimanje u tijeku!</h2>
            <!-- <v-progress-circular
                :size="20"
                color="amber"
                indeterminate
            ></v-progress-circular> -->
        </v-overlay>

        <v-data-table
            ref="datatableAnalize"
            :headers="headers"
            :items="dataItems"
            key="id"
            item-key="analiza_id"
            sort-by="analiza_datum"
            :search="search"
            no-results-text="Nema pronađenih"
            no-data-text="Nema podataka"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            loading-text="Učitavanje... Molimo pričekajte"
            @page-count="pageCount = $event"
            single-expand
            :expanded.sync="expanded"
            show-expand
            @item-expanded="ucitajKalkulacije"
            group-by="gospodarstvo_id"
            :sort-desc="true"
        >
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="10" class="teal lighten-4">
                    <v-row>
                        <v-col cols="2">
                            <v-icon @click="toggle" class="mr-0">
                                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                            </v-icon>
                            <span class="">{{
                                items[0].gospodarstvo_naziv
                            }}</span>
                        </v-col>
                        <v-col>
                            <h4>OIB: {{ items[0].gospodarstvo_oib }}</h4>

                            <h4>
                                MIBPG:
                                {{ items[0].gospodarstvo_maticni_broj }}
                            </h4>
                        </v-col>
                    </v-row>
                </th>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <h2 class="mr-5">Analize</h2>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                    ></v-text-field>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisFiltriranih"
                                    class="mr-2 ml-2"
                                    color="red lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispis tablice analiza</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFiltriranih"
                                    class="mr-2"
                                    color="blue lighten-1"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi tablicu analiza</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download :filename="FilenameXlsx">
                                        <v-icon
                                            @click="izvozExcell"
                                            class="mr-2"
                                            color="green lighten-1"
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </span>
                        </template>
                        <span>Izvoz tablice analiza</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    :to="{ name: 'nova-analiza' }"
                                >
                                    Dodaj +
                                </v-btn>
                            </span>
                        </template>
                        <span>Dodaj novu analizu</span>
                    </v-tooltip>
                </v-toolbar>
            </template>
            <!-- Filter slot -->
            <template v-slot:header="{ props: { headers } }">
                <th colspan="11">
                    <v-expansion-panels accordion tile focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="blue lighten-4">
                                <v-row no-gutters align="center">
                                    <v-col cols="2" class="d-flex">
                                        <div>
                                            <v-icon>mdi-filter</v-icon>
                                        </div>
                                        <div class="mt-1">
                                            Filtrirajte podatke
                                        </div>
                                    </v-col>
                                    <v-col cols="8" class="d-flex">
                                        <div v-if="godinaFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                                color="primary"
                                            >
                                                Godina analize
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ godinaFilter }}
                                            </h5>
                                        </div>

                                        <!-- <div v-if="mjestoFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Mjesto analize
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ mjestoFilter }}
                                            </h5>
                                        </div> -->
                                        <div v-if="operatorFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Operator
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ operatorFilter }}
                                            </h5>
                                        </div>
                                        <div v-if="datumFilter" class="ml-4">
                                            <span
                                                class="text--secondary font-weight-light"
                                            >
                                                Datum analize
                                            </span>
                                            <h5 class="text--secondary">
                                                {{ formatedDate }}
                                            </h5>
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="text-center">
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="mt-1">
                                    <v-col>
                                        <v-select
                                            :items="godineLista()"
                                            label="Izbor godine"
                                            v-model="godinaFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-select
                                    ></v-col>
                                    <!-- <v-col>
                                        <v-autocomplete
                                            :items="mjesta"
                                            label="Izbor mjesta"
                                            item-text="naziv"
                                            item-value="naziv"
                                            v-model="mjestoFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-autocomplete>
                                    </v-col> -->
                                    <v-col>
                                        <v-autocomplete
                                            :items="operatori"
                                            label="Izbor operatora"
                                            item-text="name"
                                            item-value="name"
                                            v-model="operatorFilter"
                                            clearable
                                            dense
                                            hide-details
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="izbornikDatum"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="formatedDate"
                                                    label="Datum"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    required
                                                    clearable
                                                    dense
                                                    @click:clear="
                                                        ocistiDatumFilter
                                                    "
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="datumFilter"
                                                @input="izbornikDatum = false"
                                                locale="hr-HR"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </th>
            </template>

            <template v-slot:[`item.analiza_naziv`]="{ item }">
                <router-link
                    :to="{
                        name: 'analiza-single',
                        params: { id: item.analiza_id },
                    }"
                    class="routerLink"
                >
                    <v-icon class="mr-3">mdi-atom</v-icon>
                    {{ item.analiza_naziv }}
                </router-link>
            </template>
            <template v-slot:[`item.uzorak_dubina`]="{ item }">
                <v-chip
                    v-if="item.uzorak_dubina == '0-30'"
                    color="green darken-1"
                    class="ml-0 mr-2 black--text"
                    small
                >
                    <span class="white--text"> {{ item.uzorak_dubina }}</span>
                </v-chip>

                <v-chip
                    v-else
                    color="orange darken-3"
                    class="ml-0 mr-2 black--text"
                    small
                >
                    <span class="white--text"> {{ item.uzorak_dubina }}</span>
                </v-chip>
            </template>

            <template v-slot:[`item.analiza_datum`]="{ item }">
                <span> {{ formatDate(item.analiza_datum) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="ispisObrasca(item)"
                                class="mr-1"
                                color="green lighten-2"
                            >
                                mdi-printer
                            </v-icon>
                        </span>
                    </template>
                    <span>Ispiši Analizu</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="downloadObrasca(item)"
                                class="mr-1"
                                color="blue lighten-2"
                            >
                                mdi-download
                            </v-icon>
                        </span>
                    </template>
                    <span>Preuzmi analizu</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="izradiRacun(item.analiza_id)"
                                v-bind="attrs"
                                v-on="on"
                                color="blue-grey lighten-1"
                            >
                                mdi-file-document-plus
                            </v-icon>
                        </span>
                    </template>
                    <span>Izradi račun</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="obrisiDialog(item.analiza_id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši analizu</span>
                </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="">
                    <v-container class="mt-2 mb-2">
                        <v-row>
                            <v-col cols="12" md="6" class="ml-0 pl-0">
                                <h2 class="font-weight-light">
                                    Detalji analize
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="4"
                                md="4"
                                sm="12"
                                xs="12"
                                class="rounded blue lighten-5"
                            >
                                <h2 class="">
                                    Analiza uzorka pod brojem : <br />
                                    {{ item.uzorak_broj }}
                                </h2>

                                <div class="mb-2 mt-2">
                                    <h4 class="mt-2 d-inline">Broj analize</h4>
                                    {{ item.analiza_broj }}
                                    <br />
                                    <h4 class="mt-2 d-inline">Gospodarstvo</h4>
                                    {{ item.gospodarstvo_naziv }}
                                    <br />
                                    <h4 class="mt-2 d-inline">
                                        Parcela uzorka
                                    </h4>
                                    {{ item.parcela_naziv }}
                                    <br />
                                    <h4 class="mt-2 d-inline">Uzorak broj</h4>
                                    {{ item.uzorak_broj }}
                                    <br />
                                    <h4 class="mt-2 d-inline">Dubina uzorka</h4>
                                    {{ item.uzorak_dubina }} <span>cm</span>
                                </div>
                            </v-col>
                            <v-col
                                cols="8"
                                md="8"
                                sm="12"
                                xs="12"
                                class="lime lighten-5"
                            >
                                <h2 class="font-weight-light mb-2">
                                    Rezultati analize
                                </h2>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    pH-KCI
                                                </th>
                                                <th class="text-left">
                                                    pH-H<sub>2</sub>O
                                                </th>
                                                <th class="text-left">
                                                    AL-P<sub>2</sub>O<sub
                                                        >5</sub
                                                    >
                                                </th>
                                                <th class="text-left">
                                                    AL-K<sub>2</sub>O
                                                </th>
                                                <th class="text-left">
                                                    Humus %
                                                </th>
                                                <th class="text-left">
                                                    CaCO<sub>3</sub>%
                                                </th>
                                                <th class="text-left">
                                                    Hk cmol<sup> (+)</sup>kg
                                                    <sup>-1</sup>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ item.analiza_phkci }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_phh2o }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_alp2o5 }}
                                                </td>

                                                <td>
                                                    {{ item.analiza_alpk2o }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_humus }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_caco3 }}
                                                </td>
                                                <td>
                                                    {{ item.analiza_hkcmol }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="6">
                                <div class="text-left">
                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="kalkulacijaDialog(item)"
                                    >
                                        Kalkulacija
                                        <v-icon small class="ml-2">
                                            mdi-calculator
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="6" md="6">
                                <div class="text-right">
                                    <v-btn
                                        color="primary"
                                        dark
                                        @click="ispisObrasca(item)"
                                    >
                                        Ispiši
                                        <v-icon small class="ml-2">
                                            mdi-printer
                                        </v-icon>
                                    </v-btn>

                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="posaljiDialog(item)"
                                    >
                                        <v-icon></v-icon>
                                        Pošalji
                                        <v-icon small class="ml-2">
                                            mdi-email
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="ml-0 pl-0">
                                <v-divider></v-divider>
                                <h2 class="font-weight-light">
                                    Povezane Kalkulacije
                                </h2>

                                <v-data-table
                                    :headers="headersPovezaneKalkulacije"
                                    :items="rezultatFilterKalkulacije"
                                    key="id"
                                    no-results-text="Nema pronađenih"
                                    no-data-text="Nema podataka"
                                    fixed-header
                                    hide-default-footer
                                    class="blue lighten-5"
                                >
                                    <template
                                        v-slot:[`item.kalkulacija_naziv`]="{
                                            item,
                                        }"
                                    >
                                        <router-link
                                            :to="{
                                                name: 'npk-kalkulacija-single',
                                                params: {
                                                    id: item.kalkulacija_id,
                                                },
                                            }"
                                            class="routerLink"
                                        >
                                            {{ item.kalkulacija_naziv }}
                                        </router-link>
                                    </template>
                                    <template
                                        v-slot:[`item.kalkulacija_datum`]="{
                                            item,
                                        }"
                                    >
                                        <span>
                                            {{
                                                formatDate(
                                                    item.kalkulacija_datum
                                                )
                                            }}</span
                                        >
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </td>
                <!-- ---------------DIALOG KALKULACIJE ------------- -->
                <v-dialog
                    v-model="dialogKalkulacija"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Izrada kalkulacije</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogKalkulacija = false">
                                <v-icon>mdi-close</v-icon>
                                Zatvori
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card>
                        <!-- Prosljedjivanje podataka u odabir -->

                        <v-row class="pa-0 ma-0">
                            <v-col cols="4">
                                <h4>Odaberite vrstu kalkulacije</h4>
                                <v-select
                                    dense
                                    :items="kalkulacijaItems"
                                    label="Kalkulacija"
                                    outlined
                                    item-text="kalkulacija_vrsta_naziv"
                                    item-value="kalkulacija_vrsta_id"
                                    v-model="odabranaKalkulacijaObject"
                                    return-object
                                    @change="PrikaziKomponentu()"
                                ></v-select>
                            </v-col>

                            <v-col cols="4"> </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <kalkulacija-komponenta
                            :item="item"
                            :is="kalkulacijaKomponenta"
                            :kalkulacijaVrstaId="kalkulacijaVrstaId"
                            :kalkulacijaBroj="kalkulacijaBroj"
                            v-on:close-dialog="zatvoriOdabir"
                        ></kalkulacija-komponenta>
                    </v-card>
                </v-dialog>
            </template>

            <div class="text-center">
                <v-pagination v-model="page" circle></v-pagination>
            </div>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            :total-visible="10"
        ></v-pagination>
        <!-- Dialog obrisi-->
        <v-dialog v-model="dialogObrisi" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Potvrdite brisanje!</span>
                </v-card-title>
                <v-card-text>
                    Upozorenje! <br />
                    Brisanjem analize brišete i sve povezane kalkulacije kao i
                    vezane preporuke!
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogObrisi = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="obrisi(obrisiId)">
                        Obriši
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- -DOWNLOAD  -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <analiza-obrazac
                        :analiza="analizaDownload"
                        v-if="analizaDownload"
                    ></analiza-obrazac>
                    <div class="verticalSpacer"></div>

                    <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
                </v-container>
            </section>
        </vue-html2pdf>

        <!-- -DOWNLOAD  FILTRIRANIH :paginate-elements-by-height="1400"   -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="true"
            :paginate-elements-by-height="1400"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfFiltrirano"
            :html-to-pdf-options="downloadFilterOptions"
            @hasDownloaded="hasDownloaded($event)"
            @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <analiza-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </analiza-filtrirano>

                <!-- <div class="verticalSpacer"></div> -->

                <!-- <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer> -->
            </section>
        </vue-html2pdf>

        <!-- ---------------SLANJE ------------- -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="false"
            @beforeDownload="beforeDownloadSlanje($event)"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2PdfSlanje"
            :html-to-pdf-options="slanjeOptions"
        >
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <analiza-obrazac
                        :analiza="analizaSlanje"
                        v-if="analizaSlanje"
                    ></analiza-obrazac>
                    <div class="verticalSpacer"></div>
                </v-container>
            </section>
        </vue-html2pdf>

        <!-- ISPIS  -->
        <div id="printAnaliza" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>
                <analiza-obrazac
                    :analiza="analizaIspis"
                    v-if="analizaIspis"
                ></analiza-obrazac>
                <div class="verticalSpacer"></div>
            </v-container>

            <div class="footer"></div>
        </div>
        <!-- ISPIS FILTRIRANIH ANALIZA -->
        <div id="printFiltrirano" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <analiza-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </analiza-filtrirano>

                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>

        <!-- Dialog slanje email-->
        <v-dialog v-model="dialogSlanjeEmail" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"
                        >Potvrdite slanje na unešenu e-mail adresu!</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="4"></v-col>
                        <v-col cols="8"></v-col>
                    </v-row>
                    <v-form
                        ref="formSlanje"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                    >
                        <v-text-field
                            v-model.number="emailSlanje"
                            label="Email adresa"
                            required
                            outlined
                            clearable
                            :rules="emailRules"
                            dense
                        ></v-text-field>
                    </v-form>
                    <div class="text-center" v-show="slanjeStatus">
                        <h3 class="font-light">Slanje u tijeku!</h3>

                        <v-progress-linear
                            indeterminate
                            color="cyan"
                        ></v-progress-linear>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogSlanjeEmail = false"
                    >
                        Odustani
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="posaljiAnalizu">
                        Pošalji e-mail
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
const npkPreporukaKalkulacija = () =>
    import(
        /* webpackChunkName: "npkPreporuka" */ "../kalkulacije/kalkulacija/NpkKalkulacija.vue"
    );
// const testnaKalkulacija = () =>
//     import(
//         /* webpackChunkName: "testnaKalkulacija" */ "../statistika/Statistika.vue"
//     );
import { bus } from "../../main";
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import ObrazacPodnozje from "../obrasci/ObrazacPodnozje.vue";
import VueHtml2pdf from "vue-html2pdf";
import uploadDokumenta from "../../api/upload";

export default {
    components: {
        npkPreporukaKalkulacija,
        // testnaKalkulacija,
        VueHtml2pdf,
        "analiza-obrazac": () => import("../obrasci/AnalizaObrazac.vue"),
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "obrazac-podnozje": ObrazacPodnozje,
        "analiza-filtrirano": () => import("../obrasci/AnalizaFilterIspis.vue"),
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: "",
        valid: true,
        loading: false,
        printPdfProgress: 0,
        overlay: false,
        datum: null,
        izbornikDatum: false,
        formatedDate: null,
        expanded: [],
        mjesta: [],
        operatori: [],
        datumFilter: null, //Vrijednost filtera godine
        godinaFilter: null, //Vrijednost filtera godine
        mjestoFilter: null, //Vrijednost filtera mjesta
        operatorFilter: null, //Vrijednost filtera operator
        headersPovezaneKalkulacije: [
            {
                text: "Naziv",
                align: "start",
                sortable: false,
                value: "kalkulacija_naziv",
                width: "20%",
            },
            {
                text: "Vrsta kalkulacije",
                value: "kalkulacija_vrsta_naziv",
                sortable: false,
            },
            {
                text: "Planirana kultura",
                value: "plan_kultura_naziv",
                sortable: false,
            },
            { text: "Datum", value: "kalkulacija_datum", sortable: false },

            { text: "Operater", value: "operater_name", sortable: false },
            { text: "Operacije", value: "actions", sortable: false },
        ],

        vlasnikProfil: {},
        kalkulacije: [],
        rezultatFilterKalkulacije: [],
        kalkulacijaItems: [],
        kalkulacijaKomponenta: null,
        odabranaKalkulacijaObject: null,
        kalkulacijaVrstaId: null,
        kalkulacijaBroj: null,
        unosRules: [(v) => !!v || "Unos je potreban"],
        dataItems: [], //Ucitava elemente
        dataItemsFlitrirane: [], // Stavke nakon filtera ili pretrage
        dataItemsKoristeniFilteri: {
            datumFilter: null,
            godinaFilter: null,
            operatorFilter: null,
        },
        obrisiId: "",
        form: {}, //Objekt za uredjivanje dodavanje spremanje
        dialogObrisi: false,
        dialogIspis: false,
        dialogKalkulacija: false,
        expandedItem: null,
        imeDatoteke: null,
        analizaIspis: null,
        analizaDownload: null,
        analizaSlanje: null,
        analizaObrazacKey: 1, //Varijabla za rerender analiza obrazac
        slanjeStatus: false,
        emailSlanje: "", //Varijabla za spremanje email adrese
        dialogSlanjeEmail: false,
        slanjeOptions: {
            filename: `Analiza.pdf`,
        },
        downloadFilterOptions: {
            filename: "Tablica_analiza",
            pagebreak: {
                mode: ["avoid-all", "css", "legacy"],
            },
            jsPDF: {
                format: "a4",
                orientation: "landscape",
            },
            // html2canvas: {
            //     useCORS: true,
            //     allowTaint: true,
            //     scrollX: 0,
            //     scrollY: 0,
            // },
        },
        FilenameXlsx: null,
        sheetName: "Analiza",
        sheets: [
            {
                name: "Analiza",
                data: [{}],
            },
        ],
        collection: [{ a: 1, b: 2 }],

        emailRules: [
            (v) => !!v || "E-mail je potrebna",
            (v) => /.+@.+\..+/.test(v) || "E-mail adresa nije ispravna",
        ],
    }),
    computed: {
        headers() {
            return [
                {
                    text: "Naziv",
                    align: "start",
                    sortable: false,
                    value: "analiza_naziv",
                    width: "20%",
                },
                {
                    text: "ID",
                    align: "start",
                    sortable: false,
                    value: "gospodarstvo_id",
                    filterable: false,
                },
                {
                    text: "Dubina uzorka",
                    value: "uzorak_dubina",
                    sortable: false,
                    align: "center",
                    filterable: false,
                },
                {
                    text: "Uzorak broj",
                    value: "uzorak_broj",
                    sortable: false,
                },
                {
                    text: "Godina analize",
                    value: "godina",
                    sortable: true,
                    width: "8%",
                    filter: this.godineFilter,
                    align: " d-none", //Skriveno , tu za pretragu
                },
                {
                    text: "Korisnik OIB",
                    value: "gospodarstvo_oib",
                    sortable: false,
                    align: " d-none", //Skriveno , tu za pretragu
                },
                {
                    text: "Korisnik MB",
                    value: "gospodarstvo_maticni_broj",
                    sortable: false,
                    align: " d-none", //Skriveno , tu za pretragu
                },
                {
                    text: "Gospodarstvo",
                    value: "gospodarstvo_naziv",
                    sortable: false,
                },
                {
                    text: "Parcela",
                    value: "parcela_naziv",
                    sortable: false,
                    filterable: false,
                },
                {
                    text: "Datum",
                    value: "analiza_datum",
                    sortable: false,
                    filter: this.datumiFilter,
                },
                {
                    text: "Operater",
                    value: "analiza_operator_ime",
                    filter: this.operatoriFilter,
                    sortable: false,
                },
                {
                    text: "Operacije",
                    value: "actions",
                    sortable: false,
                    width: "150px",
                    filterable: false,
                },
            ];
        },
    },
    async created() {
        await this.ucitaj();
        await this.ucitajMjesta();
        await this.ucitajKalkulacijeVrsta();
        await this.ucitajGlavniProfil();
        await this.ucitajOperatore();

        //Slusa event iz kalkulacije komponente
        bus.$on("close-dialog", () => {
            this.dialogKalkulacija = false;
        });
    },
    watch: {
        //Prati zatvaranje dialoga
        dialogKalkulacija: function (val) {
            if (!val) {
                //Dialog zatvoren ucitaj kalkulacije refresh
                this.ucitajKalkulacije(this.expandedItem);
                // Postavlja odabir kalkulacije u null
                // isto tako postavljakomponntu u null
                this.odabranaKalkulacijaObject = null;
                this.kalkulacijaKomponenta = null;
            }
        },
        //Prati i sprema odabrani filter u varijablu za ispis tablice analiza s filterima
        datumFilter(novo, staro) {
            this.formatedDate = this.formatDate(novo);
            this.dataItemsKoristeniFilteri.datumFilter = this.formatedDate;
        },
        //Prati i sprema odabrani filter u varijablu za ispis tablice analiza s filterima
        godinaFilter(novo) {
            this.dataItemsKoristeniFilteri.godinaFilter = novo;
        },
        //Prati i sprema odabrani filter u varijablu za ispis tablice analiza s filterima
        operatorFilter(novo) {
            this.dataItemsKoristeniFilteri.operatorFilter = novo;
        },
    },
    methods: {
        hasDownloaded(e) {
            this.overlay = false;
        },
        onProgress(e) {
            this.printPdfProgress = e;
        },
        //Radi rerender analiza obrazac komponente jer komponenta je stvorena i reba update
        forceRerender() {
            this.analizaObrazacKey += 1;
        },
        kalkulacijaDialog() {
            this.dialogKalkulacija = true;
        },
        posaljiDialog(item) {
            this.analizaSlanje = item;
            this.dialogSlanjeEmail = true;
            this.emailSlanje = this.analizaIspis.gospodarstvo_email;
        },
        async beforeDownloadSlanje({ html2pdf, options, pdfContent }) {
            html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .output("datauristring")
                .then(function (pdfAsString) {
                    // The PDF has been converted to a Data URI string and passed to this function.
                    // Use pdfAsString however you like (send as email, etc)!
                    this.dokument = pdfAsString;

                    setTimeout(() => {
                        uploadDokumenta
                            .uploadDokumentaSlanje(
                                pdfAsString,
                                options.filename
                            )
                            .then((response) => {
                                // console.log(response);
                            });
                    }, "1000");
                });
        },

        //Generira dokument i prosljeđuje za slanje
        async posaljiAnalizu(item) {
            if (this.$refs.formSlanje.validate()) {
                this.slanjeStatus = true;
                this.slanjeOptions.filename =
                    "Analiza" +
                    this.analizaSlanje.analiza_broj.replace("/", "-");
                let email = {
                    email_primatelj_adresa: this.emailSlanje,
                    email_posiljatelj_adresa: this.vlasnikProfil.email,
                    email_subject:
                        this.vlasnikProfil.name +
                        " " +
                        this.slanjeOptions.filename,
                    html_body: "Analiza",
                    email_attachment: "Analiza",
                    email_document_path:
                        "dokumenti/" + this.slanjeOptions.filename + ".pdf",
                    kupac_data: JSON.stringify(this.analizaSlanje),
                };

                await this.generateSlanje();

                setTimeout(() => {
                    this.$Api.posaljiEmail(email).then((response) => {
                        this.slanjeStatus = false;
                        this.dialogSlanjeEmail = false;
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    });
                }, "2000");
            }
        },
        //Generira za slanje
        async generateSlanje() {
            this.$refs.html2PdfSlanje.generatePdf();
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        zatvoriOdabir() {
            this.dialogKalkulacija = false;
        },
        //Prikazuje komponentu ovisno o odabiru kalkulacije
        PrikaziKomponentu() {
            //Postavljanje vrijednosi vrste za prosljedjivanje u komponentu kao props
            this.kalkulacijaVrstaId =
                this.odabranaKalkulacijaObject.kalkulacija_vrsta_id;
            this.kalkulacijaKomponenta =
                this.odabranaKalkulacijaObject.kalkulacija_vrsta_vrijednost;
        },
        dodajGodinaColumn(items) {
            items.forEach((obj) => {
                obj.godina = new Date(obj.analiza_datum).getFullYear();
            });
            this.dataItems = items;
        },
        async ucitajGlavniProfil() {
            this.vlasnikProfil = JSON.parse(sessionStorage.getItem("gp"));
        },
        ucitaj() {
            this.loading = true;
            this.$Api
                .getData("analize")
                .then((response) => {
                    // this.dataItems = response.data;
                    this.dodajGodinaColumn(response.data);
                    this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        async ucitajKalkulacije(item) {
            await this.$Api
                .getData("kalkulacije")
                .then((response) => {
                    this.kalkulacije = response.data;
                    if (item) {
                        this.expandedItem = item;
                        this.rezultatFilterKalkulacije =
                            this.kalkulacije.filter((kalkulacije) => {
                                return (
                                    kalkulacije.analiza_id ==
                                    item.item.analiza_id
                                );
                            });
                    }
                    //Generira novi broj kalkulacije
                    this.generirajBrojKalkulacije(item.item.analiza_broj);
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },

        ucitajKalkulacijeVrsta() {
            this.$Api
                .getData("kalkulacije-vrsta")
                .then((response) => {
                    this.kalkulacijaItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajOperatore() {
            this.$Api
                .getData("korisnici")
                .then((response) => {
                    this.operatori = response.data;
                    // this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        //Generira novi broj kalkulacije postavlja u this.kalkulacijaBroj
        generirajBrojKalkulacije(analizaBroj) {
            let noviBroj = this.rezultatFilterKalkulacije.length + 1;
            this.kalkulacijaBroj = analizaBroj + "-" + noviBroj;
        },
        dodaj() {
            if (this.$refs.form.validate()) {
                this.$Api
                    .postData("analiza", this.form)
                    .then((response) => {
                        this.dialogNovo = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
            this.resetforme();
        },
        azuriraj(id) {
            if (this.$refs.form.validate()) {
                this.$Api
                    .updateData("parcela", id, this.form)
                    .then((response) => {
                        this.dialogUredi = false;
                        this.ucitaj();
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        obrisi(id) {
            this.$Api
                .delete("analize", id)
                .then((response) => {
                    this.dialogObrisi = false;
                    this.ucitaj();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        izradiRacun(analizaId) {
            this.$router.push("/racun/novo/" + analizaId);
        },
        downloadObrasca(item) {
            this.imeDatoteke = "Analiza-" + item.analiza_broj;
            this.analizaDownload = item;

            setTimeout(() => {
                this.generateReport();
            }, "500");
        },

        ispisObrasca(item) {
            // this.forceRerender();
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.analizaIspis = item;
            // this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printAnaliza", localOptions);
            }, "500");
        },
        ispisFiltriranih() {
            // this.forceRerender();

            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            this.dataItemsFlitrirane =
                this.$refs.datatableAnalize.$children[0].filteredItems;
            // this.analizaIspis = this.dataItemsFlitrirane;
            // this.postaviPodnozje();
            setTimeout(() => {
                this.$htmlToPaper("printFiltrirano", localOptions);
            }, "500");
        },
        //Preuzimanje filtriranih analiza
        downloadFiltriranih() {
            this.overlay = true;

            this.imeDatoteke = "Tablica Analiza";
            this.dataItemsFlitrirane =
                this.$refs.datatableAnalize.$children[0].filteredItems;
            // this.analizaIspis = this.dataItemsFlitrirane;
            setTimeout(() => {
                this.generateReportFiltriranih();
            }, "500");
        },

        formirajExcell() {
            this.FilenameXlsx = "Tablica_Analiza.xlsx";
            this.sheets[0].data = [{}]; //Nuliranje array

            this.$refs.datatableAnalize.$children[0].filteredItems.forEach(
                (element) => {
                    this.sheets[0].data.push({
                        Naziv: element.analiza_naziv,
                        "Broj Analize": element.analiza_broj,
                        "Datum Analize": this.formatDate(element.analiza_datum),
                        Korisnik: element.gospodarstvo_ime,
                        Parcela: element.parcela_naziv,
                        "Katastarska čestica": element.parcela_kat_cestica,
                        "Broj uzorka": element.uzorak_broj,
                        "Datum uzorka": this.formatDate(element.uzorak_datum),
                        "Korisnik OIB": element.gospodarstvo_oib,
                        "Korisnik MB": element.gospodarstvo_maticni_broj,
                    });
                }
            );
        },
        //Nista ne radi samo pokazuje cursor, download ide iz komponente xlsx
        izvozExcell() {
            this.formirajExcell();
            return false;
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        async generateReportFiltriranih() {
            this.$refs.html2PdfFiltrirano.generatePdf();
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + "/" + mm + "/" + dd;
            return today;
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        ucitajMjesta() {
            try {
                this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
                // this.loading = false;
            } catch (error) {
                // this.loading = false;
            }
        },
        ocistiDatumFilter() {
            this.datumFilter = "";
        },
        datumiFilter(value) {
            if (!this.datumFilter) {
                return true;
            }

            return value === this.datumFilter;
        },
        godineLista() {
            let tmpArr = [];
            let currYear = new Date().getFullYear();

            for (let i = 0; i <= 50; i++) {
                tmpArr.push({ text: currYear - i, value: currYear - i });
            }
            return tmpArr;
        },
        //FILTERI ZA UZORKE
        godineFilter(value) {
            if (!this.godinaFilter) {
                return true;
            }

            return value === this.godinaFilter;
        },
        mjestaFilter(value) {
            if (!this.mjestoFilter) {
                return true;
            }
            return value === this.mjestoFilter;
        },
        operatoriFilter(value) {
            if (!this.operatorFilter) {
                return true;
            }

            return value === this.operatorFilter;
        },
    },
};
</script>
<style scoped>
.kalkulacijaPoruka {
    color: #727171;
    text-align: center;
    font-size: 1.1em;
    margin-top: 15px;
}
.verticalSpacer {
    margin-top: 200px;
    margin-bottom: 180px;
    background-color: #682a2a;
}
.alati {
}
</style>
