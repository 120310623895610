<template>
    <div>
        <v-overlay z-index="2" v-if="state">
            <div class="text-center" v-if="state">
                <h1 class="font-light">Učitavanje u tijeku!</h1>
                <p></p>
                <v-progress-circular
                    :size="50"
                    :width="7"
                    color="orange lighten-2"
                    indeterminate
                >
                </v-progress-circular>
            </div>
        </v-overlay>
    </div>
</template>

<script>
export default {
    props: ["state"],
    data() {
        return {
            absolute: true,
            zIndex: 2,
        };
    },
};
</script>

<style></style>
