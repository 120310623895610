import { render, staticRenderFns } from "./PreporukaSingle.vue?vue&type=template&id=3caff974&scoped=true&"
import script from "./PreporukaSingle.vue?vue&type=script&lang=js&"
export * from "./PreporukaSingle.vue?vue&type=script&lang=js&"
import style0 from "./PreporukaSingle.vue?vue&type=style&index=0&id=3caff974&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3caff974",
  null
  
)

export default component.exports