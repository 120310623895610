<template>
    <div>
        <v-card class="mx-auto" width="300">
            <v-list dense>
                <v-list-item to="/dashboard" link dense>
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Kontrolna ploča</v-list-item-title>
                </v-list-item>

                <!-- <left-menu-item
                    :items="datatable"
                    naslovKategorije="Datatable"
                ></left-menu-item>

                <div v-if="userRole === 1">
                    <left-menu-item
                        :items="testovi"
                        naslovKategorije="Testovi"
                        ikonaKategorije="mdi-animation"
                    ></left-menu-item>
                </div> -->

                <div v-if="userRole === 1 || userRole === 2">
                    <v-subheader>Gospodarstva i Parcele</v-subheader>
                    <left-menu-item
                        :items="gospodarstva"
                        naslovKategorije="Gospodarstva"
                        ikonaKategorije="mdi-barn"
                    ></left-menu-item>
                    <left-menu-item
                        :items="parcele"
                        naslovKategorije="Parcele"
                    ></left-menu-item>
                </div>

                <v-subheader>Uzorci i analize</v-subheader>

                <left-menu-item
                    v-if="userRole === 1 || userRole === 2"
                    :items="uzorci"
                    naslovKategorije="Uzorci"
                ></left-menu-item>
                <left-menu-item
                    :items="analize"
                    naslovKategorije="Analize"
                ></left-menu-item>

                <v-subheader>Kalkulacije i preporuke</v-subheader>
                <left-menu-item
                    :items="kalkulacije"
                    naslovKategorije="Kalkulacije"
                ></left-menu-item>
                <left-menu-item
                    :items="preporuke"
                    naslovKategorije="Preporuke"
                    ikonaKategorije="mdi-file-document"
                ></left-menu-item>
                <v-subheader>Računi</v-subheader>
                <left-menu-item
                    :items="racuni"
                    naslovKategorije="Računi"
                    ikonaKategorije="mdi-file-document"
                ></left-menu-item>
                <div v-if="userRole === 1 || userRole === 2">
                    <v-subheader>Kulture i predkulture</v-subheader>
                    <left-menu-item
                        :items="kulture"
                        naslovKategorije="Kulture"
                    ></left-menu-item>
                </div>

                <div v-if="userRole === 1 || userRole === 2">
                    <v-subheader>Tlo, nasadi i rodnost</v-subheader>
                    <left-menu-item
                        :items="tlaNasadiRodnost"
                        naslovKategorije="Tla, nasadi i rodnost"
                    ></left-menu-item>
                </div>

                <div v-if="userRole === 1 || userRole === 2">
                    <v-subheader>Gnojidba i gnojiva</v-subheader>
                    <left-menu-item
                        :items="gnojidbaGnojiva"
                        naslovKategorije="Gnojidba i gnojiva"
                    ></left-menu-item>
                </div>

                <v-subheader>Sustav</v-subheader>
                <!-- <left-menu-item
                    :items="statistika"
                    naslovKategorije="Statistika"
                ></left-menu-item> -->
                <left-menu-item
                    :items="obrasci"
                    ikonaKategorije="mdi-file-document"
                ></left-menu-item>
                <!-- <left-menu-item
                    :items="izvjestaji"
                    ikonaKategorije="mdi-chart-arc"
                ></left-menu-item> -->
                <div v-if="userRole === 1 || userRole === 2">
                    <left-menu-item
                        :items="opciPodaci"
                        naslovKategorije="Opći podaci"
                        ikonaKategorije="mdi-animation"
                    ></left-menu-item>
                </div>

                <div v-if="userRole === 1">
                    <v-subheader>Korisnički podaci</v-subheader>
                    <left-menu-item
                        :items="korisnickiPodaci"
                        naslovKategorije="Korisnici i operatori"
                        ikonaKategorije="mdi-account-cog-outline"
                    ></left-menu-item>
                </div>
                <div v-else>
                    <v-subheader>Korisnički podaci</v-subheader>
                    <v-list-item to="/moj-profil" link dense>
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Moj Profil</v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userRole: this.$store.getters.getRole,
            datatable: [
                {
                    title: "Datatable",
                    icon: "mdi-eyedropper",
                    itemLink: "/datatable",
                },
            ],
            uzorci: [
                {
                    title: "Uzorci",
                    icon: "mdi-eyedropper",
                    itemLink: "/uzorci",
                },
            ],

            analize: [
                {
                    title: "Analize",
                    icon: "mdi-atom-variant",
                    itemLink: "/analize",
                },
            ],
            parcele: [
                {
                    title: "Parcele",
                    icon: "mdi-image-filter-hdr",
                    itemLink: "/parcele",
                },
            ],
            gospodarstva: [
                {
                    title: "Klijenti",
                    icon: "mdi-barn",
                    itemLink: "/gospodarstva",
                },
            ],

            kalkulacije: [
                {
                    title: "Kalkulacije",
                    icon: "mdi-calculator",
                    itemLink: "/kalkulacije",
                },
            ],
            preporuke: [
                {
                    title: "Preporuke",
                    icon: "mdi-file-sign",
                    itemLink: "/preporuke",
                },
            ],
            racuni: [
                {
                    title: "Računi",
                    icon: "mdi-file-sign",
                    itemLink: "/racuni",
                },
            ],

            kulture: [
                {
                    title: "Predkultura i planirana kultura",
                    icon: "mdi-barley",
                    itemLink: "/kultura",
                },
            ],
            tlaNasadiRodnost: [
                {
                    title: "Tla, nasadi i rodnost",
                    icon: "mdi-sprout",
                    itemLink: "/tla-nasadi-rodnost",
                },
            ],
            gnojidbaGnojiva: [
                {
                    title: "Gnojidba i gnojiva",
                    icon: "mdi-watering-can",
                    itemLink: "/gnojidba-gnojiva",
                },
            ],
            opciPodaci: [
                {
                    title: "Mjesta",
                    icon: "mdi-town-hall",
                    itemLink: "/opci/mjesta",
                },
                {
                    title: "Banke",
                    icon: "mdi-bank",
                    itemLink: "/opci/banka",
                },
            ],
            // statistika: [
            //     {
            //         title: "Statistika",
            //         icon: "mdi-google-analytics",
            //         itemLink: "/statistika",
            //     },
            // ],
            obrasci: [
                {
                    title: "Obrasci",
                    icon: "mdi-file-document",
                    itemLink: "/obrasci",
                },
            ],
            izvjestaji: [
                {
                    title: "Izvještaji",
                    icon: "mdi-google-analytics",
                    itemLink: "/izvjestaji",
                },
            ],
            korisnickiPodaci: [
                {
                    title: "Moj profil",
                    icon: "mdi-account-multiple-outline",
                    itemLink: "/glavni-profil",
                },
                {
                    title: "Korisnici",
                    icon: "mdi-account-multiple-outline",
                    itemLink: "/korisnici",
                },
            ],
            // ------------------------ TESTOVI ------------------------------
            testovi: [
                {
                    title: "Preporuka test",
                    icon: "mdi-account-multiple-outline",
                    itemLink: "/preporuka-test",
                },
                {
                    title: "Test 1",
                    icon: "mdi-account-multiple-outline",
                    itemLink: "/test-1",
                },
            ],

            test1: [
                {
                    title: "Test 1",
                    icon: "mdi-barn",
                    itemLink: "/test-1",
                },
            ],
            test2: [
                {
                    title: "Test 2",
                    icon: "mdi-barn",
                    itemLink: "/test-2",
                },
            ],
        };
    },
};
</script>

<style></style>
