<template>
    <div class="wrap">
        <v-row>
            <v-col>
                <v-list-item two-line class="naslov">
                    <v-list-item-content>
                        <v-list-item-title>
                            DETALJNE INFORMACIJE O PARCELI</v-list-item-title
                        >
                        <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6">
                <span class="naglaseno">Gospodarstvo:</span>
                {{ this.parcela.gospodarstvo_naziv }} <br />
                <span class="naglaseno">Parcela:</span>
                {{ this.parcela.parcela_naziv }}
            </v-col>
        </v-row>
        <v-row>
            <v-col></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Opće informacije</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">Naziv</span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.parcela_naziv }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">Površina</span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.parcela_povrsina }} ha
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Katastarska čestica
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.kat_cestica }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold"> ARKOD </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.arkod }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        GPS koordinate
                                    </span>
                                </td>
                                <td class="stupacDesno" colspan="3">
                                    {{ this.parcela.gps }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 10px">
                    Kultura i predkultura na parceli
                </h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold"
                                        >Planirana kultura
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.plan_kutura_naziv }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Planirani prinos
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.plan_prinos }} t/ha
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Predkultura
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.pred_kultura_naziv }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Prinos predkulture
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.pred_kultura_prinos }} t/ha
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Žetveni ostaci</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Žetveni ostaci
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.zetveni_ostaci_naziv }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Količina t/ha
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ this.parcela.zet_ost_h }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Organska gnojidba</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Vrsta organskog gnojiva
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.organsko_vrsta }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Količina t/h
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.organsko_t_h }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Broj godina primjene
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.organsko_primjena }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Osnovna gnojidba</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Osnovna gnojidba na parceli
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.osnovna_gnojidba }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Zemljište</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Ekspozicija i nagib
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.exp_nagib }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Uređenost zemljišta
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.ur_zemljista }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Navodnjavanje
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.navodnjavanje }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Voda na zemljištu
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.ur_zemljista_kategorija }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Informacije o tlu</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold"> Tip tla </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.tip_tla }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Rodnost tla
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.rodnost_tla }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Dubina tla
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.dubina_tla }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Tip nasada
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.tip_nasada }}
                                </td>
                            </tr>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Biogenost tla
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.biogenost_tla }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Starost nasada
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.starost_nasada }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h4 style="margin-top: 15px">Agrotehnika i zaštita</h4>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <table light class="osnovnoTablica" dense>
                    <template>
                        <tbody>
                            <tr>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold">
                                        Agrotehnika
                                    </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.agrotehnika }}
                                </td>
                                <td class="stupacLijevo">
                                    <span class="naglasenoBold"> Zaštita </span>
                                </td>
                                <td class="stupacDesno">
                                    {{ parcela.zastita }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </v-col>
        </v-row>

        <div class="verticalSpacer"></div>
    </div>
</template>
<script>
export default {
    props: ["parcela"],
    data() {
        return {
            mjesta: null, // array mjesta iz local storage
            mjestoSingle: null, //Single mjesto prema ID iz
            mjestoNaziv: null,
        };
    },
    watch: {
        mjestoSingle(novo, staro) {
            this.mjestoNaziv = novo.naziv;
        },
    },
    components: {},
    methods: {
        ucitajMjesta() {
            this.mjesta = JSON.parse(localStorage.getItem("mjesta"));
        },
        dohvatiImeMjesta() {
            this.mjestoSingle = this.mjesta.find(
                (mjesto) => mjesto.id === this.analiza.gospodarstvo_mjesto_id
            );
        },
    },
    created() {
        this.ucitajMjesta();
        // this.dohvatiImeMjesta();
    },
};
</script>

<style scoped>
.wrap {
    padding-right: 5px;
}

.naglaseno {
    color: #682a2a;
}
.naglasenoBold {
    font-weight: bold;
}
.verticalSpacer {
    margin-top: 80px;
    margin-bottom: 80px;
}
.naslov {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 0px;
}
.naslov {
    font-size: 16px !important;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    font-family: "Roboto";
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}
.container {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-top: 0px;
    padding-top: 0px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
label,
input,
span {
    padding: 0px;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid;
}
.osnovnoTablica {
    border: 1px solid;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}
.osnovnoTablica td {
    border: 1px solid;
    text-align: center;
    padding: 4px;
}

.osnovnoTablica td.stupacLijevo {
    text-align: left;
    width: 32%;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}
.osnovnoTablicaTlo {
    padding: 4px;
}
.osnovnoTablicaTlo p {
    padding: 0px;
}
.osnovnoTablicaTlo td.stupacLijevo {
    text-align: left;
    width: 18%;
}
.osnovnoTablicaTlo td.stupacDesno {
    text-align: left;
}
</style>
