const zupanije = [
    {
        id: 1,
        naziv: "I ZAGREBAČKA",
    },
    {
        id: 2,
        naziv: "II KRAPINSKO-ZAGORSKA",
    },
    {
        id: 3,
        naziv: "III SISAČKO-MOSLAVAČKA",
    },
    {
        id: 4,
        naziv: "IV KARLOVAČKA",
    },
    {
        id: 5,
        naziv: "V VARAŽDINSKA",
    },
    {
        id: 6,
        naziv: "VI KOPRIVNIČKO-KRIŽEVAČKA",
    },
    {
        id: 7,
        naziv: "VII BJELOVARSKO-BILOGORSKA",
    },
    {
        id: 8,
        naziv: "VIII PRIMORSKO-GORANSKA",
    },
    {
        id: 9,
        naziv: "IX LIČKO-SENJSKA",
    },
    {
        id: 10,
        naziv: "X VIROVITIČKO-PODRAVSKA",
    },
    {
        id: 11,
        naziv: "XI POŽEŠKO-SLAVONSKA",
    },
    {
        id: 12,
        naziv: "XII BRODSKO-POSAVSKA",
    },
    {
        id: 13,
        naziv: "XIII ZADARSKA",
    },
    {
        id: 14,
        naziv: "XIV OSJEČKO-BARANJSKA",
    },
    {
        id: 15,
        naziv: "XV ŠIBENSKO-KNINSKA",
    },
    {
        id: 16,
        naziv: "XVI VUKOVARSKO-SRIJEMSKA",
    },
    {
        id: 17,
        naziv: "XVII SPLITSKO-DALMATINSKA",
    },
    {
        id: 18,
        naziv: "XVIII ISTARSKA",
    },
    {
        id: 19,
        naziv: "XIX DUBROVAČKO-NERETVANSKA",
    },
    {
        id: 20,
        naziv: "XX MEĐIMURSKA",
    },
    {
        id: 21,
        naziv: "XXI GRAD ZAGREB",
    },
];
export default zupanije;
