<template>
    <div>
        DATATABLE1
        <v-row>
            <v-col>
                <v-toolbar flat class="grey lighten-4">
                    <h2 class="mr-5">Uzorci</h2>

                    <v-text-field
                        v-model="filters[2].value"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisFiltriranih"
                                    class="mr-2 ml-2"
                                    color="red lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispis tablice uzoraka</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-2"
                                    color="blue lighten-1"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi tablicu uzoraka</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <xlsx-workbook>
                                    <xlsx-sheet
                                        :collection="sheet.data"
                                        v-for="sheet in sheets"
                                        :key="sheet.name"
                                        :sheet-name="sheet.name"
                                    />
                                    <xlsx-download :filename="FilenameXlsx">
                                        <v-icon
                                            @click="izvozExcell"
                                            class="mr-2"
                                            color="green lighten-1"
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </span>
                        </template>
                        <span>Izvoz tablice uzoraka</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="userRole === 1 || userRole === 2">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    :to="{ name: 'novi-uzorak' }"
                                >
                                    Dodaj +
                                </v-btn>
                            </span>
                        </template>
                        <span>Dodaj novi uzorak</span>
                    </v-tooltip>
                </v-toolbar>
            </v-col>
        </v-row>
        <!-- ------------DATATABLE------------------------------ -->
        <data-tables
            :data="data"
            :pagination-props="{ pageSizes: [5, 10, 15] }"
            :table-props="tableProps"
            v-loading="loading"
            :filters="filters"
            @filtered-data="handleFilteredData"
        >
            <el-table-column type="expand">
                <template slot-scope="props">
                    <v-container class="pa-5">
                        <v-row>
                            <v-col cols="12" md="6">
                                <h2 class="font-weight-light">
                                    Detalji uzorka
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>
                                    Gospodarstvo: <br />
                                    {{ props.row.gospodarstvo_naziv }}
                                </p>
                            </v-col>
                            <v-col>
                                <p>
                                    Parcela: <br />
                                    {{ props.row.parcela_naziv }}
                                </p>
                            </v-col>
                            <v-col>
                                <p>
                                    Operater: <br />
                                    {{ props.row.operator_ime }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </el-table-column>
            <el-table-column label="Naziv" min-width="90">
                <template slot-scope="props">
                    <div
                        class="text-center"
                        v-if="userRole === 1 || userRole === 2"
                    >
                        <v-icon class="mr-3" size="small"
                            >mdi-eyedropper</v-icon
                        >
                        <router-link
                            :to="{
                                name: 'uzorak',
                                params: { id: props.row.id },
                            }"
                            class="routerLink"
                        >
                            {{ props.row.naziv }}
                        </router-link>
                    </div>
                    <div v-else>
                        {{ props.row.naziv }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="lab_broj"
                label="Lab broj"
                min-width="40"
                align="center"
            >
            </el-table-column>
            <el-table-column label="Datum uzorka" width="120">
                <template slot-scope="props">
                    <span style="margin-left: 10px">{{
                        formatDate(props.row.datum)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="dubina"
                label="Dubina"
                align="center"
                width="100"
            >
                <template slot-scope="props">
                    <v-chip
                        v-if="props.row.dubina == '0-30'"
                        color="green darken-1"
                        class="ml-0 mr-2 black--text"
                        small
                    >
                        <span class="white--text"> {{ props.row.dubina }}</span>
                    </v-chip>

                    <v-chip
                        v-else
                        color="orange darken-3"
                        class="ml-0 mr-2 black--text"
                        small
                    >
                        <span class="white--text"> {{ props.row.dubina }}</span>
                    </v-chip>
                </template>
            </el-table-column>
            <el-table-column prop="uzorak_mjesto_naziv" label="Mjesto/Općina">
                <template slot-scope="props">
                    <span v-if="props.row.uzorak_mjesto_naziv">
                        {{ props.row.uzorak_mjesto_naziv }}
                    </span>
                    <span v-else> Nema podatka </span>
                </template>
            </el-table-column>
            <el-table-column label="Status analize" width="120" align="center">
                <template slot-scope="props">
                    <v-avatar
                        v-if="props.row.status_analize"
                        color="green lighten-1"
                        size="14"
                    >
                    </v-avatar>
                    <v-avatar v-else color="red lighten-1" size="14">
                    </v-avatar>
                </template>
            </el-table-column>

            <el-table-column prop="broj" label="Broj uzorka" min-width="70">
            </el-table-column>

            <el-table-column label="Operacije">
                <!-- SLOT SEARCH -->
                <template slot="header" slot-scope="scope">
                    <el-input
                        v-model="filters[0].value"
                        size="mini"
                        placeholder="Type to search"
                    />
                    <el-select
                        v-model="filters[1].value"
                        placeholder="Odaberite godinu"
                        multiple="multiple"
                    >
                        <el-option label="0-30" value="0-30"></el-option>
                        <el-option label="30-60" value="30-60"></el-option>
                    </el-select>
                </template>
                <!-- SLOT AKCIJE -->
                <template slot-scope="scope">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ispisObrasca(item)"
                                    class="mr-1"
                                    color="green lighten-2"
                                >
                                    mdi-printer
                                </v-icon>
                            </span>
                        </template>
                        <span>Ispiši Uzorak</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadObrasca(item)"
                                    class="mr-1"
                                    color="blue lighten-2"
                                >
                                    mdi-download
                                </v-icon>
                            </span>
                        </template>
                        <span>Preuzmi obrazac</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon
                                    v-if="userRole === 1 || userRole === 2"
                                    @click="obrisiDialog(item.id)"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red lighten-2"
                                >
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                    v-else
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red lighten-2"
                                >
                                    mdi-delete
                                </v-icon>
                            </span>
                        </template>
                        <span>Obriši</span>
                    </v-tooltip>
                    <!-- <el-button
                        size="mini"
                        @click="handleEdit(scope.$index, scope.row)"
                        >Edit</el-button
                    >
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)"
                        >Delete</el-button
                    > -->
                </template>
            </el-table-column>
            <div slot="empty" style="color: red">Nema dostupnih podataka</div>
        </data-tables>
        <!-- ISPIS FILTRIRANIH UZORAKA -->
        <div id="printFiltrirano" class="hidden-screen-only">
            <v-container>
                <obrazac-zaglavlje></obrazac-zaglavlje>

                <uzorak-filtrirano
                    :stavke="dataItemsFlitrirane"
                    :filteri="dataItemsKoristeniFilteri"
                >
                </uzorak-filtrirano>

                <div class="verticalSpacer"></div>
            </v-container>
            <div class="footer"></div>
        </div>
    </div>
</template>

<script>
import {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import UzorakIspis from "../obrasci/UzorakIspis.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
    components: {
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        VueHtml2pdf,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "uzorak-obrazac": UzorakIspis,
        "uzorak-filtrirano": () => import("../obrasci/UzorakFilterIspis.vue"),
    },
    data() {
        return {
            userRole: null,
            columns: [
                {
                    label: "Naziv",
                    prop: "naziv",
                },
                {
                    label: "Dubina",
                    prop: "dubina",
                },
                { label: "Mjesto/Opčina", prop: "uzorak_mjesto_naziv" },
                {
                    label: "Broj",
                    prop: "broj",
                },
                {
                    label: "Datum",
                    prop: "broj",
                },
                {
                    label: "Laboratorijski broj",
                    prop: "lab_broj",
                },
                {
                    label: "Datum uzorka",
                    prop: "datum",
                },
            ],
            filters: [
                {
                    value: "",
                    prop: "lab_broj",
                },
                {
                    value: [],
                    prop: "dubina",
                },
                {
                    value: "",
                    prop: "uzorak_mjesto_naziv",
                },
            ],
            dataItemsFlitrirane: [], // Stavke nakon filtera ili pretrage
            dataItemsKoristeniFilteri: {
                datumFilter: null,
                godinaFilter: null,
                operatorFilter: null,
                mjestoFilter: null,
            },
            search: "",
            tableProps: {
                border: true,
                stripe: true,
                defaultSort: {
                    prop: "lab_broj",
                    order: "descending",
                    // order: "ascending",
                },
            },
            data: [],
            loading: false,
            total: 0,
            //EXCELL IZVOZ
            FilenameXlsx: null,
            sheetName: "uzorci",
            sheets: [
                {
                    name: "Uzorci",
                    data: [{}],
                },
            ],
            collection: [{ a: 1, b: 2 }],
        };
    },
    methods: {
        handleFilteredData(filteredData) {
            this.dataItemsFlitrirane = filteredData;
            this.formirajExcell();
        },
        formirajExcell() {
            this.FilenameXlsx = "Tablica_uzoraka.xlsx";
            this.sheets[0].data = [{}]; //Nuliranje array

            this.dataItemsFlitrirane.forEach((element) => {
                this.sheets[0].data.push({
                    Naziv: element.naziv,
                    "Broj uzorka": element.broj,
                    "Datum uzorka": this.formatDate(element.datum),
                    "Dubina uzorka (cm)": element.dubina,
                    Korisnik: element.gospodarstvo_naziv,
                    "Korisnik OIB": element.gospodarstvo_oib,
                    "Korisnik MB": element.gospodarstvo_maticni_broj,
                    Parcela: element.parcela_naziv,
                    "Katastarska općina": element.uzorak_mjesto_naziv,
                    "Katastarska čestica": element.parcela_kat_cestica,
                    "Analiza napravljena": this.statusAnalize(
                        element.status_analize
                    ),
                });
            });
        },
        statusAnalize(uzorak) {
            return (uzorak = 1 ? "DA" : "NE");
        },
        //Nista ne radi samo pokazuje cursor, download ide iz komponente xlsx
        izvozExcell() {
            return false;
        },
        ucitaj() {
            this.loading = true;
            this.$Api
                .getData("uzorci")
                .then((response) => {
                    // this.dataItems = response.data;
                    this.data = response.data;
                    this.loading = false;
                    console.log("DATA", response.data[0]);
                })
                .catch((err) => {});
        },
        ispisFiltriranih() {
            // this.forceRerender();
            let localOptions = {
                styles: [this.$baseUrl + "/css/print.css"],
            };
            // this.analizaIspis = this.dataItemsFlitrirane;
            // this.postaviPodnozje();

            setTimeout(() => {
                this.$htmlToPaper("printFiltrirano", localOptions);
            }, "500");
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        handleEdit(index, row) {
            console.log(index, row);
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
    },
    created() {
        this.userRole = this.$store.getters.getRole; //Postavljanje uloge
        this.ucitaj();
    },
};
</script>

<style scoped></style>
