<template>
    <div>
        <v-navigation-drawer v-model="drawer" app mobile-breakpoint="960">
            <template v-slot:prepend :korisnik="korisnik">
                <v-row>
                    <v-col></v-col>
                </v-row>
                <v-row class="ma-1">
                    <v-col cols="12" class="text-center">
                        <div class="">
                            <img
                                :src="prikaziLogo"
                                max-width="120"
                                width="150"
                                class="logotip"
                            />
                        </div>
                        <v-spacer></v-spacer>
                        <span class="korisnikIme">
                            {{ korisnik.name }}
                        </span>
                        <br />
                        <span class="webLink">
                            <a
                                :href="'http://' + korisnik.web"
                                target="_blank"
                                >{{ korisnik.web }}</a
                            >
                        </span>
                    </v-col>
                </v-row>
            </template>

            <v-divider></v-divider>
            <!-- Lijevi izbornik-->
            <left-menu></left-menu>
        </v-navigation-drawer>
        <v-app-bar id="app-bar" app color="">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>AgroSys</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon link color="" class="ma-2 mr-4"
                            >mdi-account</v-icon
                        >
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        :to="{ name: 'glavni-profil' }"
                        dense
                        v-if="userRole == 1"
                    >
                        <v-list-item-title>
                            <v-icon color="" class="ma-2">mdi-account</v-icon>
                            Moj profil
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else :to="{ name: 'moj-profil' }" dense>
                        <v-list-item-title>
                            <v-icon color="" class="ma-2">mdi-account</v-icon>
                            Moj profil
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="logout" dense>
                        <v-list-item-title>
                            <v-icon color="" class="ma-2">mdi-logout</v-icon>
                            Odjavi se
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: mapState({
        korisnik: (state) => state.user,
    }),
    data() {
        return {
            userRole: this.$store.getters.getRole,
            drawer: null,
            isLoading: false,
            prikaziLogo: null,
            vlasnikProfil: null,
        };
    },
    watch: {
        vlasnikProfil(novo, staro) {
            if (novo) {
                this.prikaziLogo =
                    this.$store.getters.getLogoUrl + this.vlasnikProfil.logotip;
            }
        },
    },
    methods: {
        async ucitajGlavniProfil() {
            this.$Api
                .getVlasnikProfil()
                .then((response) => {
                    this.vlasnikProfil = response.data[0];
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        logout() {
            this.$store
                .dispatch("logout")
                .then(() => this.$router.push("/login"))
                .catch((err) => console.log(err));
        },
    },
    async created() {
        this.ucitajGlavniProfil();
        this.isLoading = true;
    },
    async beforeMount() {},
    mounted() {
        this.isLoading = false;
    },
};
</script>

<style>
.v-navigation-drawer__content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.v-navigation-drawer__content::-webkit-scrollbar {
    display: none;
}
.logotip {
    padding-top: 15px;
    padding-bottom: 10px;
    /* filter: grayscale(1); */
    filter: brightness(0) invert(1);
}

.korisnikIme {
    font-family: "Rubik";
    color: #eeeeee;
    margin-bottom: 15px;
}
.webLink a {
    text-decoration: none !important;
    color: #ffffff !important;
}
.webLink a:hover {
    text-decoration: none !important;
    color: #ed45e2 !important;
}
</style>
