// Core Components
import Navigation from "../components/NavigationDrawer.vue";
// import Footer from "../components/Footer.vue";
import LeftMenu from "../components/LeftMenu.vue";
import LeftMenuItem from "../components/LeftMenuitem.vue";
import LoaderStranica from "../components/LoaderStranica.vue";
import {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LWMSTileLayer,
    LGeoJson,
} from "vue2-leaflet";

import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

// delete Icon.Default.prototype._getIconUrl;
delete L.Icon.Default.prototype._getIconUrl;
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function setupComponents(Vue) {
    Vue.component("navigation", Navigation);
    Vue.component("loader-stranica", LoaderStranica);
    Vue.component("left-menu", LeftMenu);
    Vue.component("left-menu-item", LeftMenuItem);
    Vue.component("l-map", LMap);
    Vue.component("l-tile-layer", LTileLayer);
    Vue.component("l-marker", LMarker);
    Vue.component("l-popup", LPopup);
    Vue.component("l-wms-tile-layer", LWMSTileLayer);
    Vue.component("l-geo-json", LGeoJson);
}

export { setupComponents };
