<template>
    <div>
        <v-card class="mx-auto">
            <v-container class="pa-3">
                <v-row>
                    <v-col cols="12">
                        <v-sheet class="d-flex align-center">
                            <div class="text-left">
                                <v-img
                                    :src="prikaziLogo"
                                    max-width="150"
                                    class="mx-auto"
                                ></v-img>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="text-right">
                                <p class="text-right mb-0 px-0">
                                    {{ vlasnikProfil.name }}
                                </p>
                                <p class="text-right mb-0 px-0">
                                    {{ vlasnikProfil.ulica }},
                                    {{ vlasnikProfil.kucni_broj }}
                                </p>
                                <p class="text-right mb-0 px-0">
                                    {{ vlasnikProfil.mjesto_id }}
                                </p>
                            </div>
                        </v-sheet>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h1 class="text-center font-weight-light mt-15 mb-10">
                            Izračun gnojidbe poljoprivrednih kultura
                        </h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="pa-5">
                        <h2 class="text-left font-weight-light mb-2">
                            Osnovne Informacije
                        </h2>

                        <div class="text">
                            <b>Naziv:</b>

                            {{ kalkulacija.kalkulacija_naziv }}
                        </div>
                        <div class="text">
                            <b>Broj kalkulacije:</b>

                            {{ kalkulacija.kalkulacija_broj }}
                        </div>
                        <div class="text">
                            <b>Broj Analize:</b>
                            {{ kalkulacija.analiza_broj }}
                        </div>
                        <div class="text">
                            <b>Naziv Analize:</b>

                            {{ kalkulacija.analiza_naziv }}
                        </div>
                        <div class="text">
                            <b>Broj Uzorka:</b>
                            {{ kalkulacija.uzorak_broj }}
                        </div>
                        <div class="text">
                            <b>Naziv Uzorka:</b>
                            {{ kalkulacija.uzorak_naziv }}
                        </div>
                    </v-col>
                    <v-col cols="6" class="pa-5">
                        <h2 class="text-left font-weight-light mb-2">
                            Klijent
                        </h2>
                        <div class="text-left">
                            {{ kalkulacija.gospodarstvo_ime }}
                        </div>
                        <div class="text-left">
                            {{ kalkulacija.gospodarstvo_naziv }}
                        </div>

                        <div class="text-left">
                            {{ kalkulacija.gospodarstvo_ulica }}
                            {{ kalkulacija.gospodarstvo_kucni_broj }},

                            {{ kalkulacija.mjesto_naziv }}
                        </div>
                        <div class="text-left">
                            MIBPG:
                            {{ kalkulacija.gospodarstvo_maticni_broj }}
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    ><v-col cols="12"><v-divider></v-divider></v-col
                ></v-row>
                <v-row>
                    <v-col cols="12" class="pa-5">
                        <h4 class="text-left font-weight-light mb-2">
                            Informacije o parceli
                        </h4>
                        <v-simple-table light class="osnovnoTablica" dense>
                            <template>
                                <tbody>
                                    <tr>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Naziv
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{ kalkulacija.parcela_naziv }}
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Planirani prinos
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{
                                                kalkulacija.parcela_plan_prinos
                                            }}
                                            t/ha
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Organsko gnojivo
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{
                                                kalkulacija.organsko_vrsta_naziv
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Površina
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{ kalkulacija.parcela_povrsina }}
                                            ha
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Predkultura
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{ kalkulacija.pred_kultura_naziv }}
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Organsko zaorano
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{ kalkulacija.organsko_t_h }} t/ha
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Kultura
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{ kalkulacija.kultura_naziv }}
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Žetveni ostaci
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            <v-row no-gutters>
                                                <v-col>
                                                    <div
                                                        style="
                                                            text-align: center;
                                                        "
                                                    >
                                                        {{
                                                            kalkulacija.zetveni_ostaci_naziv
                                                        }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <div
                                                        style="
                                                            text-align: center;
                                                        "
                                                    >
                                                        {{
                                                            kalkulacija.zet_ost_h
                                                        }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="stupacLijevo">
                                            <span class="naglaseno">
                                                Godina primjene organskog
                                            </span>
                                        </td>
                                        <td class="stupacDesno">
                                            {{
                                                kalkulacija.organsko_primjena_naziv
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pa-5">
                        <h4 class="text-left font-weight-light mb-2">
                            Rezultat analize
                        </h4>
                        <v-simple-table class="osnovnoTablica">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">pH-KCI</th>
                                        <th class="text-center">
                                            pH-H<sub>2</sub>O
                                        </th>
                                        <th class="text-center">
                                            AL-P<sub>2</sub>O<sub>5</sub>
                                        </th>
                                        <th class="text-center">
                                            AL-K<sub>2</sub>O
                                        </th>
                                        <th class="text-center">Humus %</th>
                                        <th class="text-center">
                                            CaCO<sub>3</sub>O %
                                        </th>
                                        <th class="text-center">
                                            Hk cmol<sup> (+)</sup>kg
                                            <sup>-1</sup>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ kalkulacija.analiza_phkci }}
                                        </td>
                                        <td>
                                            {{ kalkulacija.analiza_phh2o }}
                                        </td>
                                        <td>
                                            {{ kalkulacija.analiza_alp2o5 }}
                                        </td>

                                        <td>
                                            {{ kalkulacija.analiza_alpk2o }}
                                        </td>
                                        <td>
                                            {{ kalkulacija.analiza_humus }}
                                        </td>
                                        <td>
                                            {{ kalkulacija.analiza_caco3 }}
                                        </td>
                                        <td>
                                            {{ kalkulacija.analiza_hkcmol }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pa-5">
                        <h4 class="text-left font-weight-light mb-2">
                            Rezultat kalkulacije
                        </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-simple-table v-if="rezultatNPK" dense>
                            <template v-slot:default>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td>Optimalna NPK formulacija</td>
                                        <td>
                                            {{ rezultatNPK.optimalni_npk }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Minimalni udio dušika N-min</td>
                                        <td>
                                            {{ rezultatNPK.min_udio_Nmin }}
                                            N/ha/god
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Potreba aktivne tvari</td>
                                        <td>
                                            {{ rezultatNPK.potreba_npk }}
                                            kg/ha
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NPK</td>
                                        <td>
                                            {{ rezultatNPK.npk.formulacija_n }}:
                                            {{ rezultatNPK.npk.formulacija_p }}:
                                            {{ rezultatNPK.npk.formulacija_k }}
                                            za
                                            {{ rezultatNPK.parcela_povrsina }}
                                            ha
                                            {{
                                                rezultatNPK.npk
                                                    .parcela_ukupno_npk
                                            }}
                                            kg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>UREA</td>
                                        <td>
                                            {{ rezultatNPK.urea.udio_urea }}
                                            za
                                            {{ rezultatNPK.parcela_povrsina }}
                                            ha
                                            {{
                                                rezultatNPK.urea
                                                    .parcela_ukupno_urea
                                            }}
                                            kg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>KAN</td>
                                        <td>
                                            {{ rezultatNPK.kan.udio_kan }}
                                            za
                                            {{ rezultatNPK.parcela_povrsina }}
                                            ha
                                            {{
                                                rezultatNPK.kan
                                                    .parcela_ukupno_kan
                                            }}
                                            kg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Bilanca NPK hraniva u kg/ha</td>
                                        <td>
                                            {{
                                                rezultatNPK.bilanca_npk
                                                    .bilanca_n
                                            }}:
                                            {{
                                                rezultatNPK.bilanca_npk
                                                    .bilanca_p
                                            }}:
                                            {{
                                                rezultatNPK.bilanca_npk
                                                    .bilanca_k
                                            }}
                                            Status bilance:
                                            {{
                                                rezultatNPK.bilanca_npk
                                                    .ukupno_bilanca
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Komponenta P</td>
                                        <td>
                                            {{
                                                rezultatNPK.komponenta_p
                                                    .p2o5_naziv
                                            }}:
                                            {{
                                                rezultatNPK.komponenta_p
                                                    .p2o5_kolicina
                                            }}
                                            za
                                            {{ rezultatNPK.parcela_povrsina }}
                                            ha
                                            {{
                                                rezultatNPK.komponenta_p
                                                    .parcela_ukupno_p2o5
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Komponenta K</td>
                                        <td>
                                            {{
                                                rezultatNPK.komponenta_k
                                                    .k2o_naziv
                                            }}:
                                            {{
                                                rezultatNPK.komponenta_k
                                                    .k2o_kolicina
                                            }}
                                            za
                                            {{ rezultatNPK.parcela_povrsina }}
                                            ha
                                            {{
                                                rezultatNPK.komponenta_k
                                                    .parcela_ukupno_k2o
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="text-left">
                            <p>Napomena</p>
                            <p class="display">
                                Izračun preporuke gnojidbe izračunava preporuku
                                gnojidbe i time je informativnog karaktera.
                                Izračun preporuke gnojidbe koristite na vlastitu
                                odgovornost.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center text--disabled">
                            {{ vlasnikProfil.name }}
                            {{ vlasnikProfil.ulica }},{{
                                vlasnikProfil.kucni_broj
                            }}
                            {{ vlasnikProfil.mjesto_id }}
                            {{ vlasnikProfil.mobitel }}
                            {{ vlasnikProfil.telefon }}
                            {{ vlasnikProfil.web }}
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-row>
                    <v-col>
                        <div class="text-left">
                            <v-btn
                                @click="ispisObrasca()"
                                class="mr-2"
                                color="primary"
                            >
                                Ispiši kalkulaciju
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="text-right">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                :to="{
                                    name: 'nova-preporuka',
                                    params: {
                                        kalkulacija_id:
                                            kalkulacija.kalkulacija_id,
                                    },
                                }"
                            >
                                Izradi preporuku +
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- ---------------ISPIS ------------- -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="imeDatoteke"
            :preview-modal="true"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            ref="html2Pdf"
        >
            <!--
                            :html-to-pdf-options="htmlToPdfOptions"
                            -->
            <section slot="pdf-content">
                <v-container>
                    <obrazac-zaglavlje></obrazac-zaglavlje>
                    <ispis-npkkalkulacija
                        :kalkulacija="kalkulacija"
                        :parcela="parcela"
                        :rezultatNPK="rezultatNPK"
                        v-if="rezultatNPK"
                    ></ispis-npkkalkulacija>

                    <footer>
                        <obrazac-podnozje></obrazac-podnozje>
                    </footer>
                </v-container>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import ispisNpkKalkulacije from "./ispis/IspisNpkKalkulacija.vue";
import ObrazacZaglavlje from "../obrasci/ObrazacZaglavlje.vue";
import ObrazacPodnozje from "../obrasci/ObrazacPodnozje.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
    data: () => ({
        kalkulacija: {},
        parcela: {},
        vlasnikProfil: {},
        rezultatNPK: null,
        imeDatoteke: "",
        kulture: null,
        predkulture: null,
    }),
    components: {
        VueHtml2pdf,
        "obrazac-zaglavlje": ObrazacZaglavlje,
        "obrazac-podnozje": ObrazacPodnozje,
        "ispis-npkkalkulacija": ispisNpkKalkulacije,
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
        async ucitaj(id) {
            this.isLoading = true;
            await this.$Api
                .getByID("kalkulacija", id)
                .then((response) => {
                    this.kalkulacija = response.data;
                    this.rezultatNPK = JSON.parse(
                        response.data.kalkulacija_rezultat
                    );

                    this.formatedDate = this.formatDate(
                        this.kalkulacija.kalkulacija_datum
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajGlavniProfil() {
            this.vlasnikProfil = JSON.parse(sessionStorage.getItem("gp"));
        },
        ispisObrasca() {
            this.generateReport();
        },
        async generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
    },
    computed: {
        prikaziLogo() {
            return this.$store.getters.getLogoUrl + this.vlasnikProfil.logotip;
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.ucitaj(this.id);
        this.ucitajGlavniProfil();
    },
};
</script>

<style scoped>
.naglaseno {
    color: #682a2a;
}
.naglasenoBold {
    font-weight: bold;
}
.verticalSpacer {
    margin-top: 100px;
    margin-bottom: 100px;
}

.osnovnoTablica tr {
    background-color: #faf7e4;
    border: 1px solid;
}
.osnovnoTablica td {
    text-align: center;
}
.osnovnoTablica td.stupacLijevo {
    text-align: left;
    width: 18%;
    background-color: #faf7e4;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}
</style>
