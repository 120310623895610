<template>
    <div>
        <v-card class="mx-auto">
            <v-container class="pa-3">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="cyan darken-1 mb-5">
                            <span class="subtitle white--text">
                                Unos preporuke</span
                            >
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-expansion-panels multiple hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="amber lighten-4">
                            Detaljne informacije parcele i analizi
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Informacije o parceli
                                    </h4>
                                    <v-simple-table
                                        light
                                        class="osnovnoTablica"
                                        dense
                                    >
                                        <template>
                                            <tbody>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Klijent
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.gospodarstvo_ime
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Uzorak broj
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.uzorak_broj
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Analiza broj
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.analiza_broj
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Naziv
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.parcela_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Planirani prinos
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.parcela_plan_prinos
                                                        }}
                                                        t/ha
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Organsko gnojivo
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.organsko_vrsta_naziv
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Površina
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.parcela_povrsina
                                                        }}
                                                        ha
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Predkultura
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.pred_kultura_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Organsko zaorano
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.organsko_t_h
                                                        }}
                                                        t/ha
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Kultura
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.kultura_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Žetveni ostaci
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div
                                                                    style="
                                                                        text-align: center;
                                                                    "
                                                                >
                                                                    {{
                                                                        kalkulacija.zetveni_ostaci_naziv
                                                                    }}
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div
                                                                    style="
                                                                        text-align: center;
                                                                    "
                                                                >
                                                                    {{
                                                                        kalkulacija.zet_ost_h
                                                                    }}
                                                                    t/ha
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Godina primjene
                                                            organskog
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.organsko_primjena_naziv
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Proizvodnja
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.proizvodnja_naziv
                                                        }}
                                                    </td>
                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                            Tip nasada
                                                        </span>
                                                    </td>
                                                    <td class="stupacDesno">
                                                        {{
                                                            kalkulacija.tip_nasada_naziv
                                                        }}
                                                    </td>

                                                    <td class="stupacLijevo">
                                                        <span class="naglaseno">
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="stupacDesno"
                                                    ></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Rezultat analize
                                        {{ kalkulacija.analiza_broj }}
                                    </h4>
                                    <v-simple-table class="osnovnoTablica">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        pH-KCI
                                                    </th>
                                                    <th class="text-center">
                                                        pH-H<sub>2</sub>O
                                                    </th>
                                                    <th class="text-center">
                                                        AL-P<sub>2</sub>O<sub
                                                            >5</sub
                                                        >
                                                    </th>
                                                    <th class="text-center">
                                                        AL-K<sub>2</sub>O
                                                    </th>
                                                    <th class="text-center">
                                                        Humus %
                                                    </th>
                                                    <th class="text-center">
                                                        CaCO<sub>3</sub>O %
                                                    </th>
                                                    <th class="text-center">
                                                        Hk cmol<sup> (+)</sup>kg
                                                        <sup>-1</sup>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_phkci
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_phh2o
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_alp2o5
                                                        }}
                                                    </td>

                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_alpk2o
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_humus
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_caco3
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            kalkulacija.analiza_hkcmol
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-5">
                                    <h4
                                        class="text-left font-weight-light mb-2"
                                    >
                                        Rezultat kalkulacije
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-simple-table v-if="rezultatNPK" dense>
                                        <template v-slot:default>
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Optimalna NPK
                                                        formulacija
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.optimalni_npk
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Minimalni udio dušika
                                                        N-min
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.min_udio_Nmin
                                                        }}
                                                        N/ha/god
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Potreba aktivne tvari
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.potreba_npk
                                                        }}
                                                        kg/ha
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>NPK</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_n
                                                        }}:
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_p
                                                        }}:
                                                        {{
                                                            rezultatNPK.npk
                                                                .formulacija_k
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.npk
                                                                .parcela_ukupno_npk
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UREA</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.urea
                                                                .udio_urea
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.urea
                                                                .parcela_ukupno_urea
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>KAN</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK.kan
                                                                .udio_kan
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK.kan
                                                                .parcela_ukupno_kan
                                                        }}
                                                        kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Bilanca NPK hraniva u
                                                        kg/ha
                                                    </td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_n
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_p
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .bilanca_k
                                                        }}
                                                        Status bilance:
                                                        {{
                                                            rezultatNPK
                                                                .bilanca_npk
                                                                .ukupno_bilanca
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Komponenta P</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .p2o5_naziv
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .p2o5_kolicina
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_p
                                                                .parcela_ukupno_p2o5
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Komponenta K</td>
                                                    <td>
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .k2o_naziv
                                                        }}:
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .k2o_kolicina
                                                        }}
                                                        za
                                                        {{
                                                            rezultatNPK.parcela_povrsina
                                                        }}
                                                        ha
                                                        {{
                                                            rezultatNPK
                                                                .komponenta_k
                                                                .parcela_ukupno_k2o
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                    <v-col cols="12" class="pa-5"> </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card-title class="cyan darken-1 mb-5">
                            <span class="subtitle white--text">
                                Napišite preporuku</span
                            >
                        </v-card-title>
                        <!-- <pre>
                            {{ rezultatKalcilizacija }}
                        </pre> -->

                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                v-model.number="form.preporuka_naziv"
                                label="Naziv preporuke"
                                required
                                outlined
                                clearable
                                :rules="nazivRules"
                                dense
                            ></v-text-field>
                        </v-form>
                        <TipTapEditor v-model="preporukaSadrzaj" />
                    </v-col>
                </v-row>
                <v-col>
                    <div class="text-left">
                        <v-btn
                            color="primary"
                            class=""
                            @click="spremiPreporuku"
                        >
                            Spremi preporuku
                        </v-btn>
                    </div>
                </v-col>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import TipTapEditor from "../../components/Editor.vue";
import PreporukaAnaliza from "../preporuka/GeneriranjePreporuke";
import Kalcilizacija from "../../formule/izracunKalcilizacije";
export default {
    components: { TipTapEditor },
    data: () => ({
        valid: false,
        form: {},
        preporukaSadrzaj: "",
        kalkulacija: "",
        formatedDate: "",
        kalkulacijaId: null,
        rezultatNPK: null,
        preporuke: null,
        noviBrojPreporuka: "",
        rezultatKalcilizacija: {}, //Rezultat iz izracunKalcilizacije.js
        nazivRules: [(v) => !!v || "Naziv je potreban"],
    }),
    created() {
        this.kalkulacijaId = this.$route.params.kalkulacija_id;
        this.ucitajKalkulaciju(this.kalkulacijaId);
        this.ucitajPreporuke();

        //Danasnji datum za bazu
        this.form.preporuka_datum = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");
    },
    methods: {
        ucitajKalkulaciju(id) {
            this.$Api
                .getByID("kalkulacija", id)
                .then((response) => {
                    console.log(response.data);
                    this.kalkulacija = response.data;
                    this.form.preporuka_analiza_id =
                        this.kalkulacija.analiza_id;
                    this.form.preporuka_naziv =
                        "Preporuka " + this.kalkulacija.analiza_broj;
                    this.rezultatNPK = JSON.parse(
                        response.data.kalkulacija_rezultat
                    );
                    this.formatedDate = this.formatDate(
                        this.kalkulacija.kalkulacija_datum
                    );
                    this.ucitajKalcilizaciju(
                        this.kalkulacija.analiza_hkcmol,
                        this.kalkulacija.analiza_phkci
                    ); //Izracun kalcilizacije
                    this.generirajTekstPreporuke(response.data);
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        spremiPreporuku() {
            if (this.validate()) {
                this.form.preporuka_analiza_id = this.kalkulacija.analiza_id;
                this.form.preporuka_kalkulacija_id = this.kalkulacijaId;
                this.noviBrojPreporuke();
                this.form.preporuka_broj = this.noviBrojPreporuka;
                this.form.preporuka_sadrzaj = this.preporukaSadrzaj;
                this.form.preporuka_operater_id = JSON.parse(
                    sessionStorage.getItem("user")
                ).id;
                this.$Api
                    .postData("preporuka", this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                        this.$router.push("/preporuke");
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },

        ucitajPreporuke() {
            this.$Api
                .getData("preporuke")
                .then((response) => {
                    this.preporuke = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        noviBrojPreporuke() {
            let najveciId = 0;
            this.preporuke.forEach((preporuka) => {
                if (preporuka.preporuka_id > najveciId)
                    najveciId = preporuka.preporuka_id;
            });
            let noviBroj = najveciId + 1;
            this.noviBrojPreporuka =
                this.kalkulacija.analiza_broj + "/" + noviBroj;
        },
        ucitajKalcilizaciju(hkcmol, phkci) {
            this.rezultatKalcilizacija = Kalcilizacija.izracunKalcilizacije(
                hkcmol,
                phkci
            );
        },
        generirajTekstPreporuke(kalkulacijaObject) {
            let preporukaAnaliza =
                PreporukaAnaliza.GenerirajPreporuku(kalkulacijaObject);
            //DETALJI UZORAk I PARCELA
            this.preporukaSadrzaj += "<h2>Detalji o uzorku i parceli</h2>";
            this.preporukaSadrzaj +=
                "<table >" +
                //RED
                "<tr>" +
                "<td >Naziv parcele</td>" +
                "<td>" +
                this.kalkulacija.parcela_naziv +
                "</td>" +
                "<td>Katastraska čestica</td>" +
                "<td>" +
                this.kalkulacija.parcela_kat_cestica +
                "</td>" +
                "<td>Površina</td>" +
                "<td>" +
                this.kalkulacija.parcela_povrsina +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Broj uzorka</td>" +
                "<td>" +
                this.kalkulacija.uzorak_broj +
                "</td>" +
                "<td>Broj analize</td>" +
                "<td>" +
                this.kalkulacija.analiza_broj +
                "</td>" +
                "<td>Lab broj</td>" +
                "<td>" +
                this.kalkulacija.uzorak_lab_broj +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Predkultura</td>" +
                "<td>" +
                this.kalkulacija.pred_kultura_naziv +
                "</td>" +
                "<td>Planirana kultura</td>" +
                "<td>" +
                this.kalkulacija.kultura_naziv +
                "</td>" +
                "<td>Planirani prinos</td>" +
                "<td>" +
                this.kalkulacija.parcela_plan_prinos +
                " t/ha" +
                "</td>" +
                "</tr>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Organska gnojidba</td>" +
                "<td>" +
                this.kalkulacija.organsko_vrsta_naziv +
                "</td>" +
                "<td>Količina</td>" +
                "<td>" +
                this.kalkulacija.organsko_t_h +
                "</td>" +
                "<td>Primjena</td>" +
                "<td>" +
                this.kalkulacija.organsko_primjena_naziv +
                "</td>" +
                "</tr>" +
                "</table>";
            //Rezultat agrokemijske analize
            this.preporukaSadrzaj += "<h2>Rezultat agrokemijske analize</h2>";
            this.preporukaSadrzaj +=
                "<table>" +
                "<tr>" +
                '<td colwidth="180" >pH-KCI</td>' +
                '<td colwidth="180">' +
                this.kalkulacija.analiza_phkci +
                "</td>" +
                "<td>" +
                preporukaAnaliza.phKCI.tekst +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>pH-H<sub>2</sub>O</td>" +
                "<td>" +
                this.kalkulacija.analiza_phh2o +
                "</td>" +
                "<td>" +
                preporukaAnaliza.phKCI.tekst +
                "</td>" +
                "</tr>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>AL-P<sub>2</sub>O<sub>5</sub></td>" +
                "<td>" +
                this.kalkulacija.analiza_alp2o5 +
                "</td>" +
                "<td>" +
                preporukaAnaliza.AlP2O5.tekst +
                "</td>" +
                //RED
                "</tr>" +
                "<tr>" +
                "<td>AL-K<sub>2</sub>O</td>" +
                "<td>" +
                this.kalkulacija.analiza_alpk2o +
                "</td>" +
                "<td>" +
                preporukaAnaliza.AlK2O.tekst +
                "</td>" +
                "</tr>" +
                //RED
                "</tr>" +
                "<tr>" +
                "<td>Humus %</td>" +
                "<td>" +
                this.kalkulacija.analiza_humus +
                "</td>" +
                "<td>" +
                preporukaAnaliza.humus.tekst +
                "</td>" +
                "</tr>" +
                //RED
                "</tr>" +
                "<tr>" +
                "<td>Hk cmol<sup> (+)</sup>kg<sup>-1</sup></td>" +
                "<td>" +
                this.kalkulacija.analiza_hkcmol +
                "</td>" +
                "<td>" +
                preporukaAnaliza.Caco3.tekst +
                "</td>" +
                "</tr>" +
                //RED
                "</tr>" +
                "<tr>" +
                "<td>CaCO<sub>3</sub> %</td>" +
                "<td>" +
                this.kalkulacija.analiza_caco3 +
                "</td>" +
                "<td>" +
                // preporukaAnaliza.Caco3.tekst +
                "</td>" +
                "</tr>" +
                "</table>";
            //Rezultat agrokemijske analize
            this.preporukaSadrzaj += "<h2>Gnojidbena preporuka</h2>";
            this.preporukaSadrzaj +=
                "<table >" +
                //RED
                "<tr>" +
                "<td >Odabrana formulacija NPK</td>" +
                "<td >" +
                this.rezultatNPK.npk.formulacija_n +
                ":" +
                this.rezultatNPK.npk.formulacija_p +
                ":" +
                this.rezultatNPK.npk.formulacija_k +
                "</td>" +
                "<td >Optimalna formulacija NPK</td>" +
                "<td >" +
                this.rezultatNPK.optimalni_npk +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>NPK preporuka</td>" +
                "<td>" +
                this.rezultatNPK.npk.parcela_ukupno_npk +
                " kg" +
                " za " +
                this.rezultatNPK.parcela_povrsina +
                " ha " +
                this.rezultatNPK.npk.formulacija_n +
                ":" +
                this.rezultatNPK.npk.formulacija_p +
                ":" +
                this.rezultatNPK.npk.formulacija_k +
                " " +
                "</td>" +
                "<td>Potreba aktivne tvari</td>" +
                "<td>" +
                this.rezultatNPK.potreba_npk +
                " kg/ha" +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>UREA kg/ha</td>" +
                "<td>" +
                this.rezultatNPK.urea.parcela_ukupno_urea +
                " za " +
                this.rezultatNPK.parcela_povrsina +
                " ha " +
                "</td>" +
                "<td>KAN kg/ha</td>" +
                "<td>" +
                this.rezultatNPK.urea.parcela_ukupno_urea +
                " za " +
                this.rezultatNPK.parcela_povrsina +
                " ha " +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Minimalni udio dušika N-min</td>" +
                "<td>" +
                this.rezultatNPK.min_udio_Nmin.toFixed(2) +
                " N/ha/god" +
                "</td>" +
                "</tr>" +
                "</table>";
            this.preporukaSadrzaj += "<h2>Kalcizacija tla</h2>";

            this.preporukaSadrzaj +=
                "<table>" +
                //RED
                "<tr>" +
                "<td>Potreba Ca</td>" +
                "<td>" +
                this.rezultatKalcilizacija.potrebaCa +
                " t/ha" +
                "</td>" +
                "<td>Potreba CaO</td>" +
                "<td>" +
                this.rezultatKalcilizacija.potrebaCaO +
                " t/ha" +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Potreba CaCo<sub>3</sub></td>" +
                "<td>" +
                this.rezultatKalcilizacija.potrebaCaco3 +
                " t/ha" +
                "</td>" +
                "<td>Potreba Karbokalk</td>" +
                "<td>" +
                this.rezultatKalcilizacija.potrebaKarbokalk +
                " t/ha" +
                "</td>" +
                "</tr>" +
                //RED
                "<tr>" +
                "<td>Potreba Saturacijski mulj</td>" +
                "<td>" +
                this.rezultatKalcilizacija.potrebaSaturacijskiMulj +
                " t/ha" +
                "</td>" +
                "<td>PhKCl < 5,5 CaCO<sub>3</sub>/td>" +
                "<td>" +
                this.kalkulacija.analiza_caco3 +
                " t/ha" +
                "</td>" +
                "</tr>" +
                "</table>";
            // this.preporukaSadrzaj += "<h2>Kalcizacija tla</h2>";
            // this.preporukaSadrzaj =
            //     "<table><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td></tr><tr><td>1</td><td>2</td><td>3</td><td>4</td></tr></tbody></table>";
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>

<style scoped>
.naglaseno {
    color: #682a2a;
}
.naglasenoBold {
    font-weight: bold;
}
.verticalSpacer {
    margin-top: 100px;
    margin-bottom: 100px;
}

.osnovnoTablica tr {
    background-color: #faf7e4;
    border: 1px solid;
}
.osnovnoTablica td {
    text-align: center;
}
.osnovnoTablica td.stupacLijevo {
    text-align: left;
    width: 18%;
    background-color: #faf7e4;
}
.osnovnoTablica td.stupacDesno {
    text-align: left;
}
.sekcijaNaslov {
    background-color: #e4e782;
}
</style>
