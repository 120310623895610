import store from "../store";
import axios1 from "axios";

const HTTP = axios1.create({
    baseURL: store.state.apiUrl,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

HTTP.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${$cookies.get("auth")}`;
    return config;
});

export const Api = {
    // OPČENITO
    getData(tablica) {
        return HTTP.get("/" + tablica);
    },
    getByPolje(tablica, polje, vrijednost) {
        return HTTP.get("/" + tablica + "/" + polje + "/" + vrijednost);
    },
    postData(tablica, polja) {
        return HTTP.post("/" + tablica + "/novo", polja);
    },
    updateData(tablica, id, polja) {
        return HTTP.put("/" + tablica + "/" + id, polja);
    },
    delete(tablica, id) {
        return HTTP.delete("/" + tablica + "/" + id);
    },
    getByID(tablica, id) {
        return HTTP.get("/" + tablica + "/" + id);
    },
    // PARCELA
    getParceleByGospodarstvoId(gospodarstvoId) {
        return HTTP.get("/parcela/gospodarstvo/" + gospodarstvoId);
    },
    // GOSPODARSTVA
    getByGospodarstvoID(id) {
        return HTTP.get("/gospodarstva/" + id);
    },
    getGospodarstvoUzorci(gospodarstvoId) {
        return HTTP.get("/gospodarstvo-uzorci/" + gospodarstvoId);
    },
    getGospodarstvoAnalize(gospodarstvoId) {
        return HTTP.get("/gospodarstvo-analize/" + gospodarstvoId);
    },
    // RAČUNI
    setRacunPlacenStatus(racunId) {
        return HTTP.put("/racun-placen-status/" + racunId);
    },
    // VLASNIK
    getVlasnikProfil() {
        return HTTP.get("/glavni");
    },
    getVlasnikBasicInfo() {
        return HTTP.get("/glavni-info");
    },
    // KORISNIK
    usporediLozinku(lozinka, id) {
        return HTTP.post("/korisnik/usporedba-lozinke/" + id, lozinka);
    },
    zamjeniLozinku(novaLozinka, id) {
        return HTTP.post("/korisnik/zamjeni-lozinku/" + id, novaLozinka);
    },
    zamjeniLozinkuAdmin(novaLozinka, id) {
        return HTTP.post("/korisnik/zamjeni-lozinku-admin/" + id, novaLozinka);
    },
    posaljiEmail(email) {
        return HTTP.post("/sendbasicemail", email);
    },
    // ANALIZA VANJSKI PREGLEd
    umetniSigntureAnaliza(id, signatureLink) {
        return HTTP.put("/analiza-signature-umetni/" + id, signatureLink);
    },
    //Dohvat linka i sadržaja za prikaz signed linka
    generirajAnalizaVanjskiLink(id) {
        return HTTP.get("signature-analiza/" + id);
    },
    prikaziAnalizuVanjski(signature, analizaId) {
        return HTTP.get(
            "/gost-analiza/" + analizaId + "?signature=" + signature
        );
    },
    // DASHBOARD
    dohvatiDashboardPodatke() {
        return HTTP.get("/dashboard-podaci");
    },
};
