<template>
    <v-row no-gutters>
        <v-col>
            <div class="text-left firmaLogo">
                <img
                    :src="prikaziLogo"
                    max-width="140"
                    width="140"
                    class="ml-5 logotip-print"
                />

                <p class="text-left firmaInfo">
                    {{ vlasnikProfil.name }} | {{ vlasnikProfil.ulica }}
                    {{ vlasnikProfil.kucni_broj }}
                    |
                    {{ vlasnikProfil.glavni_mjesto_broj }}
                    {{ vlasnikProfil.glavni_mjesto_naziv }}
                </p>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            vlasnikProfil: JSON.parse(sessionStorage.getItem("gp")),
            prikaziLogo: null,
        };
    },
    methods: {
        ucitajGlavniProfil() {
            this.prikaziLogo =
                this.$store.getters.getLogoUrl + this.vlasnikProfil.logotip;
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
    created() {
        this.vlasnikProfil = JSON.parse(sessionStorage.getItem("gp"));
        this.ucitajGlavniProfil();
    },
    mounted() {},
};
</script>

<style scoped>
.lijevo {
    text-align: left;
}

.logotip-print {
    margin-left: 30px;
    margin-top: 5px;
}
.firmaInfo {
    font-size: 12px;
    color: #7a7a7a;
    text-align: left;
    margin-left: 20px;
}
</style>
