<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="korisnici"
            key="id"
            sort-by="name"
            :search="search"
            no-results-text="Nema pronađenih"
            fixed-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="loading"
            @page-count="pageCount = $event"
        >
            <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pretraga"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        :to="{ name: 'novi-korisnik' }"
                    >
                        Novi korisnik
                    </v-btn>
                </v-toolbar>
                <!-- Dialog obrisi-->
                <v-dialog v-model="dialogObrisi" persistent max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Potvrdite brisanje!</span>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="dialogObrisi = false"
                            >
                                Odustani
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="brisiKorisnika"
                            >
                                Obriši
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <router-link
                    :to="{ name: 'korisnik', params: { id: item.id } }"
                >
                    {{ item.name }}
                </router-link>
            </template>
            <template v-slot:[`item.role`]="{ item }">
                {{ postaviUlogu(item.role) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon
                                @click="obrisiDialog(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                color="red lighten-2"
                            >
                                mdi-delete
                            </v-icon>
                        </span>
                    </template>
                    <span>Obriši</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
            <div class="text-center">
                <v-pagination v-model="page" :length="4" circle></v-pagination>
            </div>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
    </div>
</template>
<script>
export default {
    data: () => ({
        //Poruka
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        search: "",
        dialogObrisi: false,
        loading: false,
        headers: [
            {
                text: "Ime i prezime",
                align: "start",
                value: "name",
            },
            { text: "Uloga", value: "role" },
            { text: "Telefon", value: "telefon" },
            { text: "Mobitel", value: "mobitel" },
            { text: "Email", value: "email" },
            { text: "Uredi", value: "actions", sortable: false, width: "9%" },
        ],
        korisnici: [],
        uloge: [
            { id: 1, uloga: "Administrator" },
            { id: 2, uloga: "Operater" },
            { id: 3, uloga: "Vanjski suradnik" },
        ],
        obrisiId: null,
    }),
    created() {
        this.loading = true;
        this.ucitajkorisnike();
    },

    methods: {
        ucitajkorisnike() {
            this.$Api
                .getData("korisnici")
                .then((response) => {
                    this.korisnici = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        postaviUlogu(role) {
            const rezultat = this.uloge.find((uloga) => uloga.id === role);
            return rezultat.uloga;
        },
        brisiKorisnika() {
            this.$Api
                .delete("korisnik", this.obrisiId)
                .then((response) => {
                    this.closeDelete();
                    this.pokaziPoruku(
                        "poruke",
                        "success",
                        response.data.message
                    );
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        obrisiDialog(id) {
            this.dialogObrisi = true;
            this.obrisiId = id;
        },
        closeDelete() {
            this.dialogObrisi = false;
            this.$nextTick(() => {
                this.ucitajkorisnike();
            });
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
<style scoped>
.selected {
    background-color: red;
}
</style>
