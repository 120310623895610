<template>
    <div>
        <v-card elevation="4">
            <v-data-table
                :headers="headers"
                :items="dataItems"
                key="id"
                sort-by="name"
                :search="search"
                no-results-text="Nema pronađenih"
                no-data-text="Nema podataka"
                fixed-header
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                :loading="loading"
                single-select
                @page-count="pageCount = $event"
                @click:row="pokaziFormu"
                group-by="gospodarstvo_naziv"
            >
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th colspan="10" class="teal lighten-4">
                        <v-icon @click="toggle" class="mr-0">
                            {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                        </v-icon>
                        <span class="ml-4">{{
                            items[0].gospodarstvo_naziv
                        }}</span>
                    </th>
                </template>
                <template v-slot:top>
                    <v-toolbar flat class="grey lighten-4">
                        <h2 class="mr-5">Odaberite uzorak</h2>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pretraga"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="odustani()">
                            Odustani
                        </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.naziv`]="{ item }">
                    <router-link
                        :to="{
                            name: 'uzorak',
                            params: { id: item.id },
                        }"
                    >
                        {{ item.naziv }}
                    </router-link>
                </template>
                <template v-slot:[`item.dubina`]="{ item }">
                    <v-chip
                        v-if="item.dubina == '0-30'"
                        color="green darken-1"
                        class="ml-0 mr-2 black--text"
                        small
                    >
                        <span class="white--text"> {{ item.dubina }}</span>
                    </v-chip>

                    <v-chip
                        v-else
                        color="orange darken-3"
                        class="ml-0 mr-2 black--text"
                        small
                    >
                        <span class="white--text"> {{ item.dubina }}</span>
                    </v-chip>
                </template>
                <template v-slot:[`item.datum`]="{ item }">
                    <span> {{ formatDate(item.datum) }}</span>
                </template>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="4"
                        circle
                    ></v-pagination>
                </div>
            </v-data-table>
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
        </v-card>

        <div v-show="formaState">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-card class="mt-6">
                    <v-card-subtitle>
                        <h2 class="font-weight-light">
                            Analiza broj:{{ noviBrojAnalize }}
                        </h2>

                        <b>
                            Naziv uzorka:
                            {{ this.odabraniUzorak.naziv }}</b
                        >
                        <v-spacer></v-spacer>
                        <b>
                            Broj uzorka:
                            {{ this.odabraniUzorak.broj }}
                        </b>
                        <v-spacer></v-spacer>
                        <b>
                            Dubina uzorkovanja:
                            {{ this.odabraniUzorak.dubina }}
                        </b>
                        <v-spacer></v-spacer>
                        <b>
                            Klijent:
                            {{ this.odabraniUzorak.gospodarstvo_naziv }}
                        </b>
                        <v-spacer></v-spacer>
                        <b>
                            Parcela:
                            {{ this.odabraniUzorak.parcela_naziv }}
                        </b>
                        <v-spacer></v-spacer>
                        <!-- <b>
                            ARKOD čestice:
                            {{ this.odabraniUzorak.broj }}
                        </b> -->
                    </v-card-subtitle>

                    <v-card-text class="mt-4">
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="form.analiza_naziv"
                                    :rules="nazivRules"
                                    label="Naziv analize"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="izbornikDatum"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="formatedDate"
                                            label="Datum"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            required
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="datum"
                                        @input="izbornikDatum = false"
                                        locale="hr-HR"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title> Detalji analize </v-card-title>
                    <v-card-text class="mt-4">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model.number="form.analiza_phkci"
                                    label="pH-KCI"
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model.number="form.analiza_phh2o"
                                    label=""
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                >
                                    <template #label>
                                        <label>pH-H<sub>2</sub>O</label>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model.number="form.analiza_alp2o5"
                                    label=""
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                >
                                    <template #label>
                                        <label
                                            >AL-P<sub>2</sub>O<sub
                                                >5</sub
                                            ></label
                                        >
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model.number="form.analiza_alpk2o"
                                    label=""
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                >
                                    <template #label>
                                        <label>AL-K<sub>2</sub>O</label>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model.number="form.analiza_humus"
                                    label="Humus %"
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model.number="form.analiza_caco3"
                                    label=""
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                >
                                    <template #label>
                                        <label>CaCO<sub>3</sub>%</label>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    v-model.number="form.analiza_hkcmol"
                                    label=""
                                    required
                                    outlined
                                    clearable
                                    dense
                                    :rules="decimalRules"
                                >
                                    <template #label>
                                        <label>
                                            Hk cmol<sup> (+)</sup>kg<sup
                                                >-1</sup
                                            >
                                        </label>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="form.analiza_napomena"
                                    outlined
                                    label="Napomena"
                                    value="Ovdje ide napomena"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="text-left">
                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="odustani()"
                                    >
                                        Odustani
                                    </v-btn>
                                </div></v-col
                            >
                            <v-col>
                                <div class="text-right">
                                    <v-btn
                                        color="primary"
                                        class=""
                                        @click="spremiAnalizu"
                                    >
                                        Spremi
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        step: 1,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        formaState: false,
        odabraniUzorak: [],
        uzorakIdLink: null, //Uzorak id iz linka
        dataItems: [],
        search: "",
        valid: false,
        loading: false,
        izbornikDatum: false,
        datum: null,
        formatedDate: "",
        form: {},
        nazivRules: [(v) => !!v || "Naziv je potreban"],
        brojRules: [
            (v) => !!v || "Unesite broj",
            (v) => Number.isInteger(Number(v)) || "Unešeno nije broj",
        ],
        decimalRules: [
            (v) => !!v || "Unesite vrijednost",
            (v) =>
                /^\d{1,2}(\.\d{1,3})?$/.test(v) ||
                "Broj mora biti u formatu ##.###",
        ],

        gumbStatus: true,
        analize: [],
        noviBrojAnalize: 0,
        headers: [
            {
                text: "Naziv ",
                align: "start",
                sortable: false,
                value: "naziv",
                width: "15%",
            },
            { text: "Broj uzorka", value: "broj", sortable: false },
            { text: "Dubina", value: "dubina", sortable: false },
            {
                text: "Gospodarstvo",
                value: "gospodarstvo_naziv",
                sortable: false,
            },
            {
                text: "Parcela",
                value: "parcela_naziv",
                sortable: false,
            },

            {
                text: "Datum uzorka",
                value: "datum",
                sortable: false,
                width: "10%",
            },

            {
                text: "Napomena",
                value: "napomena",
                sortable: false,
                width: "20%",
            },
        ],
    }),

    created() {
        this.ucitajUzorke();
        this.ucitajAnalize();
        this.formatedDate = this.todayDate();
        this.form.analiza_datum = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");

        // if (this.$route.params.id) {
        //     this.uzorakIdLink = this.$route.params.id;
        // }
    },
    watch: {
        datum(val) {
            //Datum formatiran za prikaz
            this.formatedDate = this.formatDate(val);
            //Datum formatiran za bazu
            this.form.analiza_datum = this.formatISODate(val);
        },
    },
    methods: {
        ucitajUzorke() {
            this.$Api
                .getData("uzorci")
                .then((response) => {
                    this.dataItems = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        spremiAnalizu() {
            if (this.validate()) {
                this.form.operator_id = JSON.parse(
                    sessionStorage.getItem("user")
                ).id;
                let analizaId;
                this.$Api
                    .postData("analiza", this.form)
                    .then((response) => {
                        this.pokaziPoruku(
                            "poruke",
                            "success",
                            response.data.message
                        );
                        analizaId = response.data.lastInsertId;
                    })
                    .then((response) => {
                        //Generira link za vanjski pregled
                        this.$Api
                            .generirajAnalizaVanjskiLink(analizaId)
                            .then((response) => {
                                this.signatureAnaliza(analizaId, response.data);
                            })
                            .catch((err) => {
                                this.pokaziPoruku("poruke", "error", err);
                            });

                        this.$router.push("/analize");
                    })
                    .catch((err) => {
                        this.pokaziPoruku("poruke", "error", err);
                    });
            }
        },
        generirajBrojAnalize(uzorak) {
            let noviBroj = 0;
            const filterAnalize = this.analize.filter((analiza) => {
                return analiza.uzorak_id == uzorak.id;
            });
            noviBroj = filterAnalize.length + 1;
            return uzorak.lab_broj + "-" + noviBroj;
        },
        async signatureAnaliza(id, sigLink) {
            //Izdvajanje signanture iz linka
            let word = "signature=";
            sigLink = sigLink.slice(sigLink.indexOf(word) + word.length);
            this.$Api
                .umetniSigntureAnaliza(id, {
                    analiza_signature_link: sigLink,
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        ucitajAnalize() {
            this.$Api
                .getData("analize")
                .then((response) => {
                    this.analize = response.data;
                })
                .catch((err) => {
                    this.pokaziPoruku("poruke", "error", err);
                });
        },
        pokaziFormu(item, row) {
            this.odabraniUzorak = item;
            this.form.uzorak_id = item.id;
            this.rowBoja(row);
            this.formaState = true;
            this.noviBrojAnalize = this.generirajBrojAnalize(item);
            this.form.analiza_broj = this.noviBrojAnalize;
            this.form.analiza_naziv = "Analiza " + this.noviBrojAnalize;
        },
        rowBoja(row) {
            if (!row.isSelected) {
                row.select(true);
            }
        },
        resetforme() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        provjeriStatus(step) {
            switch (step) {
                case 1:
                    this.step += 1;
                    break;
                case 2:
                    if (this.$refs.form.validate()) {
                        this.step += 1;
                        this.stvoriIzvjestaj();
                    }
                    break;
                case 3:
                    break;
            }
        },
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        //Funckija za format datuma za bazu
        formatISODate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${year}/${month}/${day}`;
        },
        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + "." + mm + "." + yyyy;

            return today;
        },

        validate() {
            if (this.$refs.form.validate()) {
                return true;
            }
        },
        odustani() {
            this.$router.push("/analize");
        },
        pokaziPoruku(group, type, text) {
            this.$notify({
                group,
                type,
                title: "Obavijest",
                text,
            });
        },
    },
};
</script>
<style>
tr.v-data-table__selected {
    background: #ccf7d0 !important;
}

.row-tbGospodarstva > .v-data-table__wrapper > table > tbody > tr:hover {
    cursor: pointer !important;
}
.v-pagination.v-pagination {
}
</style>
